import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultDialog, {
  DefaultDialogImplementationProps,
} from "../../../../../Common/components/DefaultDialog";

export default function TermsConsentModal({
  isOpen,
  onClose,
  extras,
}: DefaultDialogImplementationProps) {
  const { t } = useTranslation();

  return (
    <DefaultDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("CollectTermsAcceptance.termsAndConditions.moreInfoModal.title")}
      actionButtons={
        <Button size="small" onClick={() => onClose()}>
          {t("common.close")}
        </Button>
      }
    >
      <Typography align="center">{extras}</Typography>
      <Typography align="center">
        {t("CollectTermsAcceptance.termsAndConditions.moreInfoModal.body")}
      </Typography>
    </DefaultDialog>
  );
}
