import { Stack } from "@mui/material";
import MedicalAssistanceFindAHospital from ".";

export default function MedicalAssistanceFindAHospitalExamples() {
  return (
    <Stack sx={{ height: "100vh" }}>
      <MedicalAssistanceFindAHospital />
    </Stack>
  );
}
