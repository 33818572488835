import { Typography } from "@mui/material";
import QuestionTypeWeight from ".";

export default function QuestionTypeWeightExamples() {
  const onChange = (answer: any) => {
    console.log("WeightInput()", answer);
  };

  return (
    <>
      <Typography variant="h2">Questionnaire Page: Weight</Typography>
      <QuestionTypeWeight onChange={onChange} />
    </>
  );
}
