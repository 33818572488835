import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack, Typography } from "@mui/material";
import useGetMemberships from "../../../Common/hooks/useGetMemberships";
import {
  findMembershipByMemberIdentifier,
  Membership,
} from "../../../../services/core-api-adapter";
import LoadingSpinner from "../../../Common/components/LoadingSpinner";
import { panelStyle } from "../../../../theme";

interface MembershipTermsPanelProps {
  membershipIdentifier?: string;
}

export default function MembershipTermsPanel({
  membershipIdentifier,
}: MembershipTermsPanelProps) {
  const { t } = useTranslation();

  const { memberships, membershipsFetchError, isMembershipsLoading } =
    useGetMemberships();
  const [membership, setMembership] = useState<Membership | null>(null);

  useEffect(() => {
    setMembership(
      findMembershipByMemberIdentifier(membershipIdentifier || "", memberships)
    );
  }, [memberships, membershipIdentifier]);

  return (
    <Stack
      spacing={2}
      flexGrow={1}
      justifyContent="space-between"
      sx={{ minHeight: "100%" }}
    >
      <Stack>
        <Typography variant="h2">
          {membership?.productDetails.friendlyName}
        </Typography>
        <Typography variant="h3">{t("MembershipTermsPage.title")}</Typography>
      </Stack>

      <Box flexGrow={1} sx={{ ...panelStyle, p: 0 }}>
        {isMembershipsLoading ? (
          <Stack alignItems="center" sx={{ p: 2 }}>
            <LoadingSpinner />
          </Stack>
        ) : (
          <>
            {!membership || membershipsFetchError ? (
              <Typography variant="h4" align="center">
                {t("common.somethingWentWrong")}
              </Typography>
            ) : (
              <iframe
                title={t("MembershipTermsPanel.title", {
                  productName: membership?.productDetails.friendlyName,
                })}
                src={membership?.productDetails.termsAndConditionsUri || ""}
                height="100%"
                width="100%"
                style={{
                  border: "none",
                  height: "100%",
                  left: 0,
                  margin: 0,
                  position: "absolute",
                  top: 0,
                  width: "100%",
                }}
              ></iframe>
            )}
          </>
        )}
      </Box>
    </Stack>
  );
}
