import OptionToggle from ".";

export default function OptionToggleExamples() {
  const onChange = (value: string) => console.log(value);

  return (
    <>
      <h2>OptionToggle (as a Multi select)</h2>
      <OptionToggle
        onChange={onChange}
        options={[
          { name: "Vomiting and diarrhoea", value: "Vomiting and diarrhoea" },
          {
            name: "Coughing and shortness of breath",
            value: "Coughing and shortness of breath",
            disabled: true,
          },
          { name: "Body pain", value: "Body pain" },
          {
            name: "Difficulty passing stool",
            value: "Difficulty passing stool",
          },
          { name: "Stomach pain", value: "Stomach pain" },
          { name: "Other", value: "Other" },
        ]}
        multiple
      />

      <h2>OptionToggle (as a Single select)</h2>
      <OptionToggle
        onChange={onChange}
        options={[
          {
            name: "A",
            value: "1",
          },
          {
            name: "B",
            value: "2",
          },
          {
            name: "C",
            value: "3",
          },
          {
            name: "D",
            value: "4",
          },
          {
            name: "E",
            value: "5",
            disabled: true,
          },
          {
            name: "F",
            value: "6",
          },
          {
            name: "G",
            value: "7",
          },
          {
            name: "H",
            value: "8",
            disabled: true,
          },
          {
            name: "I don't know",
            value: "0",
          },
        ]}
      />

      <h2>OptionToggle (enforce at least one value, as a Single select)</h2>
      <OptionToggle
        onChange={onChange}
        options={[
          {
            name: "A",
            value: "1",
          },
          {
            name: "B",
            value: "2",
          },
          {
            name: "C",
            value: "3",
          },
          {
            name: "D",
            value: "4",
          },
          {
            name: "E",
            value: "5",
            disabled: true,
          },
          {
            name: "F",
            value: "6",
          },
          {
            name: "G",
            value: "7",
          },
          {
            name: "H",
            value: "8",
            disabled: true,
          },
          {
            name: "I don't know",
            value: "0",
          },
        ]}
        preventEmptyValue
      />

      <h2>OptionToggle (as a Single select with a pre-selected option)</h2>
      <OptionToggle
        onChange={onChange}
        options={[
          {
            name: "A",
            value: "1",
          },
          {
            name: "B",
            value: "2",
          },
          {
            name: "C",
            value: "3",
          },
          {
            name: "D",
            value: "4",
          },
          {
            name: "E",
            value: "5",
            disabled: true,
          },
          {
            name: "F",
            value: "6",
          },
          {
            name: "G",
            value: "7",
          },
          {
            name: "H",
            value: "8",
            disabled: true,
          },
          {
            name: "I don't know",
            value: "0",
          },
        ]}
        value={["5"]}
      />

      <h2>OptionToggle (as a Multi select with a pre-selected option)</h2>
      <OptionToggle
        onChange={onChange}
        options={[
          {
            name: "A",
            value: "1",
          },
          {
            name: "B",
            value: "2",
          },
          {
            name: "C",
            value: "3",
          },
          {
            name: "D",
            value: "4",
          },
          {
            name: "E",
            value: "5",
            disabled: true,
          },
          {
            name: "F",
            value: "6",
          },
          {
            name: "G",
            value: "7",
          },
          {
            name: "H",
            value: "8",
            disabled: true,
          },
          {
            name: "I don't know",
            value: "0",
          },
        ]}
        value={["3", "5", "4"]}
        multiple
      />

      <h2>OptionToggle (enforce at least one value, as a Multi select)</h2>
      <OptionToggle
        onChange={onChange}
        options={[
          {
            name: "A",
            value: "1",
          },
          {
            name: "B",
            value: "2",
          },
          {
            name: "C",
            value: "3",
          },
          {
            name: "D",
            value: "4",
          },
          {
            name: "E",
            value: "5",
            disabled: true,
          },
          {
            name: "F",
            value: "6",
          },
          {
            name: "G",
            value: "7",
          },
          {
            name: "H",
            value: "8",
            disabled: true,
          },
          {
            name: "I don't know",
            value: "0",
          },
        ]}
        preventEmptyValue
        multiple
      />
    </>
  );
}
