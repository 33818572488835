import { Stack } from "@mui/material";
import QuestionTypeHeight from ".";

export default function QuestionTypeHeightExamples() {
  function onChange(value: any) {
    console.log("onChange()", value);
  }

  return (
    <Stack>
      <QuestionTypeHeight onChange={onChange} />
    </Stack>
  );
}
