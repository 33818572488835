import { Stack, Typography } from "@mui/material";
import CollectHeight from ".";

export default function HeightInputExamples() {
  const handleChange = (value: string, unit: string) => {
    console.log(value, unit);
  };
  return (
    <>
      <Typography variant="h2">Height Input Example</Typography>
      <Stack sx={{ px: 2, mt: 1 }}>
        <CollectHeight onChange={handleChange} />
      </Stack>
    </>
  );
}
