import { Stack } from "@mui/system";
import QuestionTypeStatus, { QuestionStatusType } from ".";

export default function QuestionTypeConditionsStatusExamples() {
  const onChange = (value: any) => {
    console.log("onChange()", value);
  };

  return (
    <Stack spacing={2}>
      <QuestionTypeStatus
        type={QuestionStatusType.ALLERGIES}
        onChange={onChange}
      />

      <QuestionTypeStatus
        type={QuestionStatusType.CONDITIONS}
        onChange={onChange}
      />
    </Stack>
  );
}
