import React, { useState, useId, useEffect } from "react";
import { Button, Stack, Typography, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import FaceIconBrandLarge from "../../../../theme/icons/FaceIconBrandLarge";
import InternationalPhoneNumberInput, {
  InternationalPhoneNumber,
} from "../../../Common/components/InternationalPhoneNumberInput";
import { GetHelpFABConfig } from "../../../../theme";
import GetHelpCTAButton from "../../../Common/components/GetHelpCTAButton";
import {
  AnalyticsEvent,
  trackFormEvent,
} from "../../../../services/analytics-adapter";

interface CollectThirdPartyUserLoginDetailsProps {
  onSubmit: (userData: { phoneNumber: InternationalPhoneNumber }) => void;
  value?: { globalSubscriberNumber: string; countryCode: string };
  existingUserDetails?: {
    firstName: string;
    lastName: string;
    phoneNumber: InternationalPhoneNumber;
  };
}

export default function CollectThirdPartyUserLoginDetails(
  props: CollectThirdPartyUserLoginDetailsProps
) {
  const { t } = useTranslation();
  const inputID = useId();

  const defaultCountryCode = "UG";
  const [phoneNumber, setPhoneNumber] = useState<InternationalPhoneNumber>({
    countryCode: defaultCountryCode,
    globalSubscriberNumber:
      props.existingUserDetails?.phoneNumber?.globalSubscriberNumber || "",
  });

  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);

  useEffect(() => {
    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: "Enter cellphone number",
    });
  }, []);

  function onChange(phoneNumber: InternationalPhoneNumber, isValid: boolean) {
    setIsSubmitButtonEnabled(isValid);
    setPhoneNumber(phoneNumber);
  }

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    props.onSubmit({
      phoneNumber,
    });
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing={2} justifyContent="flex-end" sx={{ pb: 2 }}>
          <FaceIconBrandLarge />
          <Stack spacing={2}>
            <Typography component="h2" variant="h3">
              {t("CollectThirdPartyUserDetails.title")}
            </Typography>
          </Stack>
          <Stack pb={4}>
            <Typography variant="body1">
              {t("CollectThirdPartyUserDetails.subtitle")}
            </Typography>
          </Stack>

          <InputLabel htmlFor={inputID}>
            {t("CollectPhoneNumber.CellphoneNumberInputLabel")}
          </InputLabel>
          <InternationalPhoneNumberInput
            value={phoneNumber}
            isDialingCodeDropDownDisabled={true}
            onChange={onChange}
          />
          <Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignSelf="flex-end"
              position="relative"
              width={GetHelpFABConfig.floatingActionButtonWidth}
              py={1}
            >
              <GetHelpCTAButton eventName="action.contactSupportFab" />
            </Stack>

            <Button
              type="submit"
              color="primary"
              disabled={!isSubmitButtonEnabled}
              aria-label={t("common.nextButton")}
              fullWidth
            >
              {t("common.nextButton")}
            </Button>
          </Stack>
        </Stack>
      </form>
    </>
  );
}
