import CommunicationPreferencesInputs from ".";

export default function CommunicationPreferencesInputsExamples() {
  function onChange(data: any) {
    console.log(
      "CommunicationPreferencesInputsExamples.onChange",
      JSON.stringify(data, null, 2)
    );
  }

  return (
    <>
      <CommunicationPreferencesInputs onChange={onChange} />

      <br />
      <hr />
      <br />

      <CommunicationPreferencesInputs
        onChange={onChange}
        value={[
          {
            communicationType: "SMS",
            communicationValue: "0123456789",
            isActive: true,
          },
        ]}
      />

      <br />
      <hr />
      <br />

      <CommunicationPreferencesInputs
        onChange={onChange}
        value={[
          {
            communicationType: "Email",
            communicationValue: "asdf@asdf.com",
            isActive: true,
          },
        ]}
      />

      <br />
      <hr />
      <br />

      <CommunicationPreferencesInputs
        onChange={onChange}
        value={[
          {
            communicationType: "SMS",
            communicationValue: "0123456789",
            isActive: true,
          },
          {
            communicationType: "Email",
            communicationValue: "asdf@asdf.com",
            isActive: true,
          },
        ]}
      />
    </>
  );
}
