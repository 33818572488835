import { Stack, Typography } from "@mui/material";
import DefaultError from ".";
import NoCameraFoundError from "../../../HealthCheck/components/BinahScanFlow/components/NoCameraFoundError";
import PermissionIssuesError from "../../../HealthCheck/components/BinahScanFlow/components/PermissionIssuesError";
import DeviceRequirementsError from "../../../HealthCheck/components/BinahScanFlow/components/DeviceRequirementsError";
import GenericError from "../../../HealthCheck/components/BinahScanFlow/components/GenericError";
import { HealthMonitorCodes } from "@binah/web-sdk";

export default function ErrorExamples() {
  function onComeBackLater() {
    console.log("onComeBackLater");
  }

  function onTryAgain() {
    console.log("onTryAgain");
  }

  return (
    <Stack spacing={3}>
      <Stack>
        <DefaultError onComeBackLater={() => {}} onTryAgain={() => {}}>
          <Typography>Default Error</Typography>
        </DefaultError>
      </Stack>
      <Stack>
        <NoCameraFoundError
          onComeBackLater={onComeBackLater}
          onTryAgain={onTryAgain}
        />
      </Stack>
      <Stack>
        <PermissionIssuesError
          onComeBackLater={onComeBackLater}
          onTryAgain={onTryAgain}
        />
      </Stack>
      <Stack>
        <DeviceRequirementsError
          onComeBackLater={onComeBackLater}
          onTryAgain={onTryAgain}
        />
      </Stack>
      <Stack>
        <GenericError
          onComeBackLater={onComeBackLater}
          onTryAgain={onTryAgain}
          errorCode={HealthMonitorCodes.DEVICE_CODE_INTERNAL_ERROR_2}
        />
      </Stack>
      <Stack>
        <GenericError
          onComeBackLater={onComeBackLater}
          onTryAgain={onTryAgain}
          errorCode={
            HealthMonitorCodes.MEASUREMENT_CODE_INVALID_RECENT_DETECTION_RATE_ERROR
          }
        />
      </Stack>
      <Stack>
        <GenericError
          onComeBackLater={onComeBackLater}
          onTryAgain={onTryAgain}
          errorCode={
            HealthMonitorCodes.MEASUREMENT_CODE_MISDETECTION_DURATION_EXCEEDS_LIMIT_ERROR
          }
        />
      </Stack>
      <Stack>
        <GenericError
          onComeBackLater={onComeBackLater}
          onTryAgain={onTryAgain}
          errorCode={HealthMonitorCodes.LICENSE_CODE_NETWORK_ISSUES_ERROR}
        />
      </Stack>
    </Stack>
  );
}
