import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";
import IconLoader from "@/features/Common/components/IconLoader";
import MembershipCardModal from "@/features/Common/components/MembershipCardModal";
import Panel from "@/features/Common/components/Panel";
import { trackEvent, trackUserInteraction } from "@/services/analytics-adapter";
import { getConfigurationVariable } from "@/services/configuration-adapter";
import { logout } from "@/services/core-api-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "@/services/feature-toggle-adapter";
import { useGlobalStore } from "@/store";
import {
  defaultFullscreenPageStyling,
  overrideDefaultLinkStyling,
  pageWithBottomNavigationStyling,
} from "@/theme";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import BottomNavigation from "@/features/Common/components/BottomNavigation";

export default function More() {
  const { t } = useTranslation();
  const { state, dispatch } = useGlobalStore();
  const navigate = useNavigate();

  const isThirdPartyMember = state.isThirdPartyMember;
  const [isMembershipCardModalOpen, setIsMembershipCardModalOpen] =
    useState(false);

  const onLogoutButtonClick = () => {
    logout().then(() => {
      dispatch({
        type: "CLEAR_CURRENT_USER",
      });
      navigate("/");
    });
  };

  function onFAQLinkClick() {
    trackUserInteraction({
      linkText: `More | ${t("More.helpSection.faqLink")}`,
      linkIntent: "navigational",
      linkScope: "button",
    });
    trackEvent({ event: "action.faqVisited", source: "more screen" });
  }

  function onGetSupportLinkClick() {
    trackUserInteraction({
      linkText: `More | ${t("More.helpSection.supportLink")}`,
      linkIntent: "navigational",
      linkScope: "button",
    });
    trackEvent({ event: "action.supportRequested", source: "more screen" });
  }

  function onContactDetailsLinkClick() {
    trackUserInteraction({
      linkText: `More | ${t("More.generalSettingsSection.contactDetailsLink")}`,
      linkIntent: "navigational",
      linkScope: "button",
    });
  }

  function onAboutAppLinkClick() {
    trackUserInteraction({
      linkText: `More | ${t("More.helpSection.aboutAppLink")}`,
      linkIntent: "navigational",
      linkScope: "button",
    });
  }

  function onCommunicationSettingsLinkClick() {
    trackUserInteraction({
      linkText: `More | ${t("More.generalSettingsSection.communicationPreferencesLink")}`,
      linkIntent: "navigational",
      linkScope: "button",
    });
  }

  function onViewMyMembershipCardClick() {
    trackUserInteraction({
      linkText: `More | ${t("More.myMembershipSection.membershipCardLink")}`,
      linkIntent: "navigational",
      linkScope: "button",
    });
    setIsMembershipCardModalOpen(true);
  }

  function onViewMyHealthProfileLinkClick() {
    trackUserInteraction({
      linkText: `More | ${t("More.generalSettingsSection.healthProfileLink")}`,
      linkIntent: "navigational",
      linkScope: "button",
    });
  }

  function onViewMyPurchasesLinkClick() {
    trackUserInteraction({
      linkText: `More | ${t("More.myPurchases.purchasesLink")}`,
      linkIntent: "navigational",
      linkScope: "button",
    });
  }

  function onMembershipCardModalClose() {
    setIsMembershipCardModalOpen(false);
  }

  return (
    <Stack
      sx={{
        ...defaultFullscreenPageStyling,
        ...pageWithBottomNavigationStyling,
      }}
    >
      <Typography
        component="h1"
        variant="h2"
        sx={{
          mb: 1,
        }}
      >
        {t("More.title")}
      </Typography>
      <Typography
        variant="body1"
        color="neutral"
        sx={{
          mb: 4,
        }}
      >
        {t("More.subtitle")}
      </Typography>

      <Stack spacing={4}>
        <Panel>
          <Typography>{state.currentUser.firstName}</Typography>
          <ButtonWithAnalytics
            page="More"
            text={t("common.logOutButton")}
            intent="confirmational"
            onClick={() => onLogoutButtonClick()}
            variant="text"
            color="primary"
            size="large"
            sx={{
              fontWeight: 700,
              minWidth: "auto",
              mt: 1,
              p: 0,
              textAlign: "left",
            }}
          >
            {t("common.logOutButton")}
          </ButtonWithAnalytics>
        </Panel>

        {isThirdPartyMember === false && (
          <Stack spacing={2}>
            <Typography component="h2">
              {t("More.myMembershipSection.title")}
            </Typography>
            <Panel padding={0}>
              <Stack
                direction="row"
                alignItems="center"
                component={Link}
                to={""}
                p={2}
                sx={{
                  ...overrideDefaultLinkStyling,
                }}
                justifyContent="space-between"
                onClick={onViewMyMembershipCardClick}
              >
                <Typography component="p" variant="h4">
                  {t("More.myMembershipSection.membershipCardLink")}
                </Typography>
                <IconLoader icon="ChevronNextIcon" />
              </Stack>
            </Panel>
          </Stack>
        )}

        {isFeatureEnabled(FeatureToggleIdentifier.ENABLE_PATHOLOGY_PANELS) && (
          <Stack spacing={2}>
            <Typography component="h2">
              {t("More.myPurchases.title")}
            </Typography>
            <Panel padding={0}>
              <Stack
                direction="row"
                alignItems="center"
                component={Link}
                to={"/home/more/my-purchases"}
                aria-label={t("More.myPurchases.purchasesLink")}
                p={2}
                sx={{
                  ...overrideDefaultLinkStyling,
                }}
                justifyContent="space-between"
                onClick={onViewMyPurchasesLinkClick}
              >
                <Typography component="p" variant="h4">
                  {t("More.myPurchases.purchasesLink")}
                </Typography>

                <Stack display="flex" flexDirection="row" alignItems="center">
                  <IconLoader icon="ChevronNextIcon" />
                </Stack>
              </Stack>
            </Panel>
          </Stack>
        )}

        <Stack spacing={2}>
          <Typography component="h2">
            {t("More.generalSettingsSection.title")}
          </Typography>
          <Panel padding={0}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              component={Link}
              onClick={onContactDetailsLinkClick}
              to={"/home/my-health/contact-details"}
              p={2}
              sx={{ ...overrideDefaultLinkStyling }}
            >
              <Typography component="p" variant="h4">
                {t("More.generalSettingsSection.contactDetailsLink")}
              </Typography>
              <IconLoader icon="ChevronNextIcon" />
            </Stack>
          </Panel>

          <Panel padding={0}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              component={Link}
              onClick={onCommunicationSettingsLinkClick}
              to={"/home/my-health/communication-preferences"}
              p={2}
              sx={{ ...overrideDefaultLinkStyling }}
            >
              <Typography component="h2" variant="h4">
                {t("More.generalSettingsSection.communicationPreferencesLink")}
              </Typography>
              <IconLoader icon="ChevronNextIcon" />
            </Stack>
          </Panel>

          <Panel padding={0}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              component={Link}
              onClick={onViewMyHealthProfileLinkClick}
              to={"/home/my-health/health-profile"}
              p={2}
              sx={{ ...overrideDefaultLinkStyling }}
            >
              <Typography component="p" variant="h4">
                {t("More.generalSettingsSection.healthProfileLink")}
              </Typography>
              <IconLoader icon="ChevronNextIcon" />
            </Stack>
          </Panel>
        </Stack>

        <Stack spacing={2}>
          <Typography component="h2">{t("More.helpSection.title")}</Typography>
          <Panel padding={0}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              component="a"
              href={getConfigurationVariable("VITE_APP_UNU_HEALTH_FAQ_URI")}
              onClick={onFAQLinkClick}
              target="_blank"
              p={2}
              sx={{ ...overrideDefaultLinkStyling }}
            >
              <Typography component="p" variant="h4">
                {t("More.helpSection.faqLink")}
              </Typography>
              <IconLoader icon="ChevronNextIcon" />
            </Stack>
          </Panel>
          <Panel padding={0}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              component={Link}
              to={"/home/more/get-support"}
              onClick={onGetSupportLinkClick}
              p={2}
              sx={{ ...overrideDefaultLinkStyling }}
            >
              <Typography component="p" variant="h4">
                {t("More.helpSection.supportLink")}
              </Typography>
              <IconLoader icon="ChevronNextIcon" />
            </Stack>
          </Panel>
          <Panel padding={0}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              component={Link}
              onClick={onAboutAppLinkClick}
              to={"/home/help/about-app"}
              p={2}
              sx={{ ...overrideDefaultLinkStyling }}
            >
              <Typography component="p" variant="h4">
                {t("More.helpSection.aboutAppLink")}
              </Typography>
              <IconLoader icon="ChevronNextIcon" />
            </Stack>
          </Panel>
        </Stack>
      </Stack>

      <BottomNavigation />

      <MembershipCardModal
        isOpen={isMembershipCardModalOpen}
        onClose={onMembershipCardModalClose}
      />
    </Stack>
  );
}
