import CollectTerms from ".";

export default function CollectTermsExamples() {
  return (
    <CollectTerms
      onTermsAccept={() => {
        console.log("onTermsAccept");
      }}
      contractList={[
        {
          ContractType: "TERMS_AND_CONDITIONS_PRODUCT",
          Version: 1,
          Description: "string",
          FileUri: "string",
        },
      ]}
    />
  );
}
