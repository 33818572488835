import { Typography } from "@mui/material";
import WeightInput from ".";

export default function WeightInputExamples() {
  const handleChange = (value: string) => {
    console.log("WeightInput:", value);
  };

  return (
    <>
      <Typography variant="h2">Weight input example</Typography>
      <WeightInput onChange={handleChange} />
    </>
  );
}
