import { ChangeEvent, useState } from "react";
import { Stack, TextField } from "@mui/material";
import HealthScoreIndicator from ".";

export default function HealthScoreIndicatorExamples() {
  const [healthScore, setHealthScore] = useState(0);

  return (
    <>
      <Stack padding={2} spacing={2}>
        <TextField
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setHealthScore(Number(event.target.value))
          }
        />
        <HealthScoreIndicator healthScore={healthScore} />
      </Stack>
    </>
  );
}
