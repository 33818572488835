import { Stack, Typography } from "@mui/material";
import CollectUNUID from ".";

export default function CollectUNUIDExamples() {
  return (
    <>
      <Stack sx={{ mt: 5 }}>
        <Typography variant="h2">Default UNU ID input</Typography>
        <Stack py={6}>
          <CollectUNUID
            value={""}
            onSubmit={(value: string) => {
              console.log("Do something on submit. Value: ", value);
            }}
          />
        </Stack>
      </Stack>

      <Stack>
        <Typography variant="h2">With pre-populated UNU ID</Typography>
        <Stack py={6}>
          <CollectUNUID
            value={"A23"}
            onSubmit={(value: string) => {
              console.log("Do something on submit. Value: ", value);
            }}
          />
        </Stack>
      </Stack>

      <Stack>
        <Typography variant="h2">Pre-populated with invalid UNU ID</Typography>
        <Stack py={6}>
          <CollectUNUID
            value={"23"}
            onSubmit={(value: string) => {
              console.log("Do something on submit. Value: ", value);
            }}
          />
        </Stack>
      </Stack>
    </>
  );
}
