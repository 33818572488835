import CollectConsentAcceptance from ".";

export default function CollectConsentAcceptanceExamples() {
  return (
    <CollectConsentAcceptance
      onSubmit={() => {
        console.log("onSubmit");
      }}
      data={{
        ContractType: "ContractType",
        Version: 1,
      }}
    />
  );
}
