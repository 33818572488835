// @ts-nocheck
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import theme from "../theme";

const expectedPaletteColours = [
  "primary",
  "secondary",
  "error",
  "warning",
  "info",
  "success",
  "neutral",
  "accent1",
  "accent2",
  "accent3",
  "accent4",
  "accent5",
];

export default function ColourExamples() {
  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h2">Theme Colours</Typography>

        <Stack spacing={2} divider={<Divider />}>
          {Object.keys(theme.palette)
            .filter((paletteColorName: unknown) => {
              const item: any = theme.palette[paletteColorName];
              return item.main ? true : false;
            })
            .map((paletteColorName: unknown) => {
              const paletteColor: any = theme.palette[paletteColorName];
              const shouldBeDeprecated =
                expectedPaletteColours.indexOf(paletteColorName) === -1;

              return (
                <Stack key={paletteColorName} spacing={1}>
                  <Typography variant="h3">
                    {paletteColorName as string}{" "}
                    {shouldBeDeprecated ? "(to be deprecated)" : ""}
                  </Typography>

                  <Grid container>
                    {Object.keys(paletteColor).map(
                      (paletteColorEntryKey: string) => {
                        const paletteColorEntryFullKey = `${paletteColorName}.${paletteColorEntryKey}`;
                        const paletteColorEntryRawValue =
                          paletteColor[paletteColorEntryKey];

                        return (
                          <>
                            <Grid item xs={3}>
                              <Typography>{paletteColorEntryKey}</Typography>
                            </Grid>

                            <Grid item xs={3}>
                              <Typography color={paletteColorEntryFullKey}>
                                {paletteColorEntryKey} (
                                {paletteColorEntryRawValue})
                              </Typography>
                            </Grid>

                            <Grid item xs={3}>
                              <Box bgcolor={paletteColorEntryRawValue}>
                                <Typography color="#000">
                                  {paletteColorEntryKey} (
                                  {paletteColorEntryRawValue})
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item xs={3}>
                              <Box bgcolor="#000">
                                <Typography color={paletteColorEntryFullKey}>
                                  {paletteColorEntryKey} (
                                  {paletteColorEntryRawValue})
                                </Typography>
                              </Box>
                            </Grid>
                          </>
                        );
                      }
                    )}
                  </Grid>
                </Stack>
              );
            })}
        </Stack>
      </Stack>
    </>
  );
}
