import { Refund } from ".";

export default function RefundExamples() {
  function onContinue() {
    console.log("onContinue");
  }

  function onBack() {
    console.log("onBack");
  }

  return (
    <Refund priceFormatted={"R175"} onContinue={onContinue} onBack={onBack} />
  );
}
