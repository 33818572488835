import { Box, Divider, Stack, Typography } from "@mui/material";
import PathologyStatusIndicator, {
  PathologyOrderStatus,
} from "@/features/Pathology/components/PathologyStatusIndicator";
import Panel from "@/features/Common/components/Panel";
import IconLoader from "@/features/Common/components/IconLoader";
import { useTranslation } from "react-i18next";
import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";
import {
  convertPxToRem,
  formatDateToReadableString,
  getShortDayFromDate,
} from "@/utils";

interface PathologyOrderSummaryProps {
  isOrderPending: boolean;
  amountFormatted: string;
  orderItem: string;
  orderDate: string;
}

function PathologyOrderItemNextStepTag({
  status,
}: {
  status: "BLOOD_TEST" | "WAITING_RESULTS" | "COMPLETE";
}) {
  const { t } = useTranslation();

  return (
    <Box
      bgcolor="primary.light"
      width="fit-content"
      borderRadius={1}
      color="primary.contrastText"
      p={1}
    >
      <Typography variant="h5" color="inherit">
        {t(`PathologyOrderItemNextStepTag.${status}.title`)}
      </Typography>
    </Box>
  );
}

export default function PathologyOrderSummary({
  isOrderPending,
  amountFormatted,
  orderItem,
  orderDate,
}: PathologyOrderSummaryProps) {
  const { t } = useTranslation();

  return (
    <Stack spacing={3}>
      <Stack justifyContent="center" textAlign="center" spacing={2}>
        <Typography variant="h2">{t("PathologyOrderSummary.title")}</Typography>
        {!isOrderPending && (
          <Box gap={1}>
            <Typography variant="h3">
              {t("PathologyOrderSummary.orderNumber.title")}
            </Typography>
            <Typography variant="h4">############</Typography>
          </Box>
        )}
        <Box display="flex" justifyContent="center" flexDirection="row" gap={2}>
          <PathologyStatusIndicator status={PathologyOrderStatus.PAID} />
          {!isOrderPending && (
            <Typography>
              {`${getShortDayFromDate(new Date(orderDate))}, ${formatDateToReadableString(new Date(orderDate))}`}
            </Typography>
          )}
        </Box>
      </Stack>
      <Panel>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          p={1}
        >
          <Stack spacing={2}>
            <Typography variant="h4">{orderItem}</Typography>
            {isOrderPending ? (
              <Typography variant="h5" color="neutral.main" fontWeight={400}>
                {t("PathologyOrderSummary.pendingOrder.title")}
              </Typography>
            ) : (
              <PathologyOrderItemNextStepTag status="BLOOD_TEST" />
            )}
          </Stack>
          <IconLoader
            sx={{ fontSize: 30 }}
            color="primary"
            icon="ChevronNextIcon"
          />
        </Stack>
      </Panel>
      <Panel>
        <Stack spacing={2}>
          <Box
            display="flex"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <IconLoader
              sx={{ fontSize: 30 }}
              color="primary"
              icon="InformationSymbolFilledCircleIcon"
            />
            <Typography variant="h4">
              {t("PathologyOrderSummary.findAFacility.panel.title")}
            </Typography>
          </Box>
          <ButtonWithAnalytics
            page="PathologyOrderSummary"
            text={t("PathologyOrderSummary.howToPrepare.button.title")}
            intent="navigational"
            variant="outlined"
            size="small"
            fullWidth
          >
            {t("PathologyOrderSummary.howToPrepare.button.title")}
          </ButtonWithAnalytics>
          <ButtonWithAnalytics
            page="PathologyOrderSummary"
            text={t("PathologyOrderSummary.findAFacility.button.title")}
            intent="navigational"
            variant="outlined"
            size="small"
            fullWidth
          >
            {t("PathologyOrderSummary.findAFacility.button.title")}
          </ButtonWithAnalytics>
        </Stack>
      </Panel>
      <Panel>
        <Stack spacing={2.5} p={1}>
          <Typography variant="h4">
            {t("PathologyOrderSummary.orderDetails.panel.title")}
          </Typography>
          <Divider />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="h4" fontWeight={400}>
              {orderItem}
            </Typography>
            <Typography variant="h4" color="primary">
              {amountFormatted}
            </Typography>
          </Box>
          <Divider />
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4">
              {t("PathologyOrderSummary.orderDetails.panel.total.title")}
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontSize: convertPxToRem(26) }}
              color="primary"
            >
              {amountFormatted}
            </Typography>
          </Box>
          <ButtonWithAnalytics
            page="PathologyOrderSummary"
            text={t("PathologyOrderSummary.findAFacility.button.title")}
            intent="navigational"
            size="small"
            fullWidth
          >
            {t("PathologyOrderSummary.orderDetails.panel.button.title")}
          </ButtonWithAnalytics>
        </Stack>
      </Panel>
    </Stack>
  );
}
