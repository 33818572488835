import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import PathologyStatusIndicator, { PathologyOrderStatus } from ".";

export default function PathologyStatusIndicatorExamples() {
  const possiblePathologyStatuses = [
    ...Object.keys(PathologyOrderStatus),
    "INVALID",
    undefined,
    null,
    "",
  ];

  return (
    <>
      <Stack spacing={2}>
        {possiblePathologyStatuses.map((status: any) => (
          <Box key={status}>
            <Typography variant="h5">status: {String(status)}</Typography>
            <PathologyStatusIndicator status={status} />
          </Box>
        ))}
      </Stack>
    </>
  );
}
