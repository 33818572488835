import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import MembershipStatusIndicator from ".";
import { MembershipStatus } from "../../../../services/core-api-adapter";

export default function MembershipStatusIndicatorExamples() {
  const possibleMembershipStatuses = [
    ...Object.keys(MembershipStatus),
    "EXPIRE",
    "INVALID",
    "EXPIRED_15_DAYS_AGO",
    undefined,
    null,
    "",
  ];

  return (
    <>
      <Stack spacing={2}>
        {possibleMembershipStatuses.map((status: any) => (
          <Box key={status}>
            <Typography variant="h5">status: {String(status)}</Typography>
            <MembershipStatusIndicator status={status} />
          </Box>
        ))}
      </Stack>
    </>
  );
}
