import { Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import HealthScoreIndicatorGauge from ".";

export default function HealthScoreIndicatorGaugeExamples() {
  const [healthScore, setHealthScore] = useState(1);

  return (
    <Stack alignItems="center">
      <Typography>Adjustable</Typography>
      <HealthScoreIndicatorGauge healthScore={healthScore} />
      <TextField
        sx={{ mt: 1 }}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setHealthScore(Number(event.target.value))
        }
        fullWidth
      />
    </Stack>
  );
}
