import { useNavigate } from "react-router-dom";
import { MedicalAssistanceDependantSelection } from ".";

export default function MedicalAssistanceDependantSelectionExamples() {
  const navigate = useNavigate();

  function onNextButtonClick() {
    navigate("/home/get-medical-assistance");
  }
  return (
    <MedicalAssistanceDependantSelection
      onNextButtonClick={onNextButtonClick}
    />
  );
}
