import { setup, assign } from "xstate";

export interface Context {
  signUpButtonClicked: boolean;
}

const initialContextValues: Context = {
  signUpButtonClicked: false,
};
export const welcomeFlowMachine = setup({
  types: {
    context: {} as Context,
  },
  actions: {
    addSignUpButtonClickedToContext: assign(() => {
      return { signUpButtonClicked: true };
    }),
  },
  schemas: {
    events: {
      AUTH_IDENTIFICATION_TYPE_COMPLETED: {
        type: "object",
        properties: {},
      },
      CVP_VIEW_COMPLETED: {
        type: "object",
        properties: {},
      },
      WELCOME_CONTINUE_BUTTON_CLICKED: {
        type: "object",
        properties: {},
      },
      WELCOME_SIGN_UP_BUTTON_CLICKED: {
        type: "object",
        properties: {},
      },
    },
  },
}).createMachine({
  context: initialContextValues,
  id: "welcomeFlow",
  initial: "WelcomeLandingScreen",
  states: {
    WelcomeLandingScreen: {
      on: {
        WELCOME_CONTINUE_BUTTON_CLICKED: {
          target: "customerValueProposition",
        },
        WELCOME_SIGN_UP_BUTTON_CLICKED: {
          target: "customerValueProposition",
          actions: {
            type: "addSignUpButtonClickedToContext",
          },
        },
      },
    },
    customerValueProposition: {
      on: {
        CVP_VIEW_COMPLETED: {
          target: "authIdentificationTypeSelection",
        },
      },
    },
    authIdentificationTypeSelection: {
      on: {
        AUTH_IDENTIFICATION_TYPE_COMPLETED: {
          target: "exit",
        },
      },
      entry: assign(initialContextValues),
    },
    exit: {
      type: "final",
    },
  },
});
