import { Stack } from "@mui/material";
import ProvisionedEmployeeWelcome from ".";

export default function ProvisionedEmployeeWelcomeExamples() {
  function onSubmit() {
    console.log("Submitted");
  }

  return (
    <Stack sx={{ height: "100vh", p: 2 }}>
      <ProvisionedEmployeeWelcome onSubmit={onSubmit} />
    </Stack>
  );
}
