import { getConfigurationVariable } from "@/services/configuration-adapter";
import theme, {
  defaultFullscreenPageStyling,
  overrideDefaultLinkStyling,
} from "@/theme";
import { buildWhatsAppUniversalLinkURL, convertPxToRem } from "@/utils";
import { Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IconLoader from "../IconLoader";
import WatermarkIcon from "../WatermarkIcon";

export default function Maintenance() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const eventSource = location.state?.eventSource || "";

  const SUPPORT_WHATSAPP_PHONE_NUMBER_ZA = getConfigurationVariable(
    "VITE_APP_UNU_HEALTH_SUPPORT_WHATSAPP_PHONE_NUMBER_ZA"
  );
  const SUPPORT_VOICE_PHONE_NUMBER_ZA = getConfigurationVariable(
    "VITE_APP_UNU_HEALTH_SUPPORT_VOICE_PHONE_NUMBER_ZA"
  );

  const SUPPORT_URI = getConfigurationVariable(
    "VITE_APP_UNU_HEALTH_SUPPORT_URI"
  );

  function onLogQueryClick(event: any) {
    event.preventDefault();
    return navigate("/get-help/log-query", {
      state: { eventSource },
    });
  }

  return (
    <Stack
      spacing={5}
      justifyContent="center"
      minHeight="100vh"
      sx={{ ...defaultFullscreenPageStyling }}
    >
      <Stack alignItems="center" spacing={2}>
        <IconLoader
          icon="MaintenanceIcon"
          sx={{ fontSize: convertPxToRem(110) }}
        />
        <Typography variant="h1" textAlign="center">
          {t("Maintenance.title")}
        </Typography>
        <Typography variant="body1" textAlign="center" color="neutral.main">
          {t("Maintenance.subtitle")}
        </Typography>
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h2" textAlign="center">
          {t("Maintenance.contact.title")}
        </Typography>

        <Stack spacing={2} width="100%">
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            component={Link}
            target="_blank"
            to={buildWhatsAppUniversalLinkURL(SUPPORT_WHATSAPP_PHONE_NUMBER_ZA)}
            sx={{
              ...overrideDefaultLinkStyling,
              color: theme.palette.neutral.dark,
            }}
          >
            <Stack flexDirection="row" alignItems="center">
              <WatermarkIcon
                height={35}
                width={35}
                iconSize="medium"
                color={theme.palette.primary[300]}
                foreground={theme.palette.primary.main}
                Icon={"WhatsAppIcon"}
                extra={{
                  marginRight: 1.5,
                }}
              />
              <Typography variant="h4">
                {t("GetHelpModal.whatsAppChat")}
              </Typography>
            </Stack>
            <IconLoader
              icon="ChevronNextIcon"
              sx={{ color: theme.palette.neutral.light }}
            />
          </Stack>

          <Divider />

          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            component={Link}
            to={`tel:${SUPPORT_VOICE_PHONE_NUMBER_ZA}`}
            sx={{
              ...overrideDefaultLinkStyling,
              color: theme.palette.neutral.dark,
            }}
          >
            <Stack flexDirection="row" alignItems="center">
              <WatermarkIcon
                height={35}
                width={35}
                iconSize="medium"
                color={theme.palette.primary[300]}
                foreground={theme.palette.primary.main}
                Icon={"PhoneIcon"}
                extra={{
                  marginRight: 1.5,
                }}
              />
              <Stack flexDirection="column">
                <Typography variant="h4">
                  {t("GetHelpModal.callUs.title", {
                    phoneNumber: SUPPORT_VOICE_PHONE_NUMBER_ZA,
                  })}
                </Typography>
                <Typography variant="body2">
                  {t("GetHelpModal.callUs.availability")}
                </Typography>
              </Stack>
            </Stack>
            <IconLoader
              icon="ChevronNextIcon"
              sx={{ color: theme.palette.neutral.light }}
            />
          </Stack>

          <Divider />

          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            onClick={onLogQueryClick}
            component="a"
            href={SUPPORT_URI}
            target="_blank"
            sx={{
              ...overrideDefaultLinkStyling,
              color: theme.palette.neutral.dark,
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
              cursor: "pointer",
            }}
          >
            <Stack flexDirection="row" alignItems="center">
              <WatermarkIcon
                height={35}
                width={35}
                iconSize="medium"
                color={theme.palette.primary[300]}
                foreground={theme.palette.primary.main}
                Icon={"QuestionMarkIcon"}
                extra={{
                  marginRight: 1.5,
                }}
              />
              <Typography variant="h4">
                {t("GetHelpModal.logQueryLabel")}
              </Typography>
            </Stack>
            <IconLoader
              icon="ChevronNextIcon"
              sx={{ color: theme.palette.neutral.light }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
