import { Typography, Switch } from "@mui/material";

export default function SwitchExamples() {
  return (
    <>
      <Typography variant="h2">Switch</Typography>
      <Switch />
      <Switch defaultChecked />
      <Switch disabled />
      <Switch disabled defaultChecked />
    </>
  );
}
