import { ConsultationTypeSelection } from ".";

export default function ConsultationTypeSelectionExamples() {
  function onSelectNurseConsult() {
    console.log("Continue button");
  }

  function onSelectDirectToDoctor() {
    console.log("Starting new session");
  }

  return (
    <>
      <ConsultationTypeSelection
        onNurseConsultClick={onSelectNurseConsult}
        onDirectToDoctorConsultClick={onSelectDirectToDoctor}
      />
    </>
  );
}
