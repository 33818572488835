import { Stack, Typography } from "@mui/material";
import InternationalPhoneNumberInput, { InternationalPhoneNumber } from ".";

export default function InternationalPhoneNumberInputExamples() {
  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        <Typography variant="h2">No country code selected</Typography>
        <Stack>
          <InternationalPhoneNumberInput
            onChange={(value: InternationalPhoneNumber) => {
              console.log(value);
            }}
            value={{ countryCode: "", globalSubscriberNumber: "" }}
          />
        </Stack>
        <Stack>
          <InternationalPhoneNumberInput
            onChange={(value: InternationalPhoneNumber) => {
              console.log(value);
            }}
            value={{ countryCode: "", globalSubscriberNumber: "021495050" }}
          />
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h2">Country code selected</Typography>
        <Stack>
          <InternationalPhoneNumberInput
            onChange={(value: InternationalPhoneNumber) => {
              console.log(value);
            }}
            value={{ countryCode: "ZA", globalSubscriberNumber: "021495050" }}
          />
        </Stack>
        <Stack>
          <InternationalPhoneNumberInput
            onChange={(value: InternationalPhoneNumber) => {
              console.log(value);
            }}
            value={{ countryCode: "ZA", globalSubscriberNumber: "021495050" }}
            isDisabled={true}
            size="small"
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
