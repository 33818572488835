import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ThankYou from ".";

export default function ThankYouExamples() {
  const { t } = useTranslation();

  return (
    <Stack>
      <Stack sx={{ height: "100vh" }}>
        <Typography variant="h2">Example 1</Typography>
        <ThankYou
          onButtonClick={() => console.log("Done button clicked")}
          title={"Thank you!"}
          subTitle={"Thank you for your feedback"}
          buttonLabel={"Done"}
        />
      </Stack>

      <Stack sx={{ height: "100vh" }}>
        <Typography variant="h2">Example 2</Typography>
        <ThankYou
          onButtonClick={() => console.log("Button clicked")}
          title={t("UserRatingFeedback.thankYou.title")}
          subTitle={t("UserRatingFeedback.thankYou.subtitle")}
          buttonLabel={t("UserRatingFeedback.thankYou.backHomeButtonLabel")}
        />
      </Stack>
    </Stack>
  );
}
