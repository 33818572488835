import { Stack } from "@mui/material";
import PaymentSuccess from ".";

export default function PaymentSuccessExamples() {
  return (
    <Stack sx={{ height: "100vh" }}>
      <PaymentSuccess onContinue={() => console.log("onContinue click")} />
    </Stack>
  );
}
