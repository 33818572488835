import { Stack } from "@mui/material";
import UserRatingFeedbackError from ".";

export default function UserRatingFeedbackErrorExamples() {
  function onTryAgain() {
    console.log("onTryAgain");
  }

  return (
    <Stack sx={{ height: "100vh" }}>
      <UserRatingFeedbackError onTryAgain={onTryAgain} />
    </Stack>
  );
}
