import QuestionTypeDateOfBirth from ".";
import { Stack } from "@mui/material";

export default function QuestionTypeDateOfBirthExamples() {
  function onChange(answer: any) {
    console.log("onChange", answer);
  }
  return (
    <Stack>
      <QuestionTypeDateOfBirth data="2000/10/20" onChange={onChange} />
    </Stack>
  );
}
