import { useId } from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CircledBackButton from "@/features/Common/components/CircledBackButton";
import IconLoader from "@/features/Common/components/IconLoader";
import Panel from "@/features/Common/components/Panel";
import theme, {
  defaultFullscreenPageStyling,
  overrideDefaultLinkStyling,
} from "@/theme";

export default function AboutApp() {
  const { t } = useTranslation();
  const appVersion = import.meta.env.VITE_APP_VERSION;
  const appVersionID = useId();

  return (
    <Stack spacing={3} sx={{ ...defaultFullscreenPageStyling }}>
      <CircledBackButton showLabel={true} />
      <Stack my={3}>
        <Typography variant="h2">{t("AboutApp.title")}</Typography>
      </Stack>

      <Panel>
        <Stack spacing={1}>
          <Typography
            id={appVersionID}
            variant="h5"
            color={theme.palette.neutral[500]}
          >
            {t("AboutApp.sections.version.label")}
          </Typography>
          <Typography aria-labelledby={appVersionID} fontWeight="bold">
            {appVersion}
          </Typography>
        </Stack>
      </Panel>

      <Panel>
        <Stack spacing={1}>
          <Typography variant="h5" color={theme.palette.neutral[500]}>
            {t("AboutApp.sections.legalInformation.label")}
          </Typography>
          <Typography variant="body2">
            {t("AboutApp.sections.legalInformation.content")}
          </Typography>
        </Stack>
      </Panel>

      <Panel padding={0}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          p={2}
          sx={{ ...overrideDefaultLinkStyling }}
          component="a"
          href="/home/help/about-app/open-source-licenses"
        >
          <Typography variant="h4">
            {t("AboutApp.sections.openSourceLicenses.linkLabel")}
          </Typography>
          <IconLoader icon="ChevronNextIcon" />
        </Stack>
      </Panel>
    </Stack>
  );
}
