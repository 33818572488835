import {
  createElement,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import { useGlobalStore } from "../../../../store";
import {
  getHealthProfile,
  healthProfileNewEntry,
} from "../../../../services/core-api-adapter";
import {
  convertPascalCaseToWords,
  convertStringToJSON,
} from "../../../../utils";
import FullscreenLoadingIndicator from "../../../Common/components/FullscreenLoadingIndicator";
import FaceIconBrandLarge from "../../../../theme/icons/FaceIconBrandLarge";
import CircledBackButton from "../../../Common/components/CircledBackButton";
import WhyAreWeAskingYouThisModal from "../../../Common/components/WhyAreWeAskingYouThisModal";
import QuestionTypeStatus from "../../../Common/components/QuestionTypeStatus";
import QuestionTypeBloodType from "../../../Common/components/QuestionTypeBloodType";
import QuestionTypeDateOfBirth from "../../../Common/components/QuestionTypeDateOfBirth";
import QuestionTypeHeight from "../../../Common/components/QuestionTypeHeight";
import QuestionTypeSexAtBirth from "../../../Common/components/QuestionTypeSexAtBirth";
import QuestionTypeSmokingFrequency from "../../../Common/components/QuestionTypeSmokingFrequency";
import QuestionTypeSmokingStatus from "../../../Common/components/QuestionTypeSmokingStatus";
import QuestionTypeWeight from "../../../Common/components/QuestionTypeWeight";
import FormInputErrorList from "../../../Common/components/FormInputErrorList";
import {
  AnalyticsEvent,
  trackEvent,
  trackFormEvent,
} from "../../../../services/analytics-adapter";
import { SnackbarContext } from "../../../../context/SnackbarProvider";
import { QuestionStatusType } from "../../../Common/components/MedicalConditionList";
import QuestionTypeMedicalConditionList from "../../../Common/components/QuestionTypeMedicalConditionList";
import ButtonWithAnalytics from "../../../Common/components/ButtonWithAnalytics";

type QuestionTypeName =
  | "AllergiesList"
  | "AllergiesStatus"
  | "BloodType"
  | "DateOfBirth"
  | "Height"
  | "ConditionsList"
  | "ConditionsStatus"
  | "SexAtBirth"
  | "SmokingFrequency"
  | "SmokingStatus"
  | "Weight";

interface Answer {
  data: any;
  isValid: boolean;
}

interface HealthMeasurementMetadataItemQuestion {
  component: any;
  questionType: QuestionTypeName;
  questionId?: string;
  initialValue?: any;
  currentValue?: any;
  isValid?: boolean;
  isVisible?: boolean;
  extras?: any;
}

interface HealthMeasurementMetadataItem {
  questions: HealthMeasurementMetadataItemQuestion[];
}

interface HealthMeasurementsMetadata {
  [key: string]: HealthMeasurementMetadataItem;
}

const healthMeasurementsMetadata: HealthMeasurementsMetadata = {
  SexAtBirth: {
    questions: [
      {
        questionType: "SexAtBirth",
        isVisible: true,
        component: QuestionTypeSexAtBirth,
      },
    ],
  },
  DateOfBirth: {
    questions: [
      {
        questionType: "DateOfBirth",
        isVisible: true,
        component: QuestionTypeDateOfBirth,
      },
    ],
  },
  Height: {
    questions: [
      {
        questionType: "Height",
        isVisible: true,
        component: QuestionTypeHeight,
      },
    ],
  },
  Weight: {
    questions: [
      {
        questionType: "Weight",
        isVisible: true,
        component: QuestionTypeWeight,
      },
    ],
  },
  SmokingStatusAndFrequency: {
    questions: [
      {
        questionType: "SmokingStatus",
        isVisible: true,
        component: QuestionTypeSmokingStatus,
      },
      {
        questionType: "SmokingFrequency",
        isVisible: true,
        component: QuestionTypeSmokingFrequency,
      },
    ],
  },
  BloodType: {
    questions: [
      {
        questionType: "BloodType",
        isVisible: true,
        component: QuestionTypeBloodType,
      },
    ],
  },
  ConditionsStatusAndList: {
    questions: [
      {
        questionType: "ConditionsStatus",
        isVisible: true,
        component: QuestionTypeStatus,
        extras: {
          type: QuestionStatusType.CONDITIONS,
        },
      },
      {
        questionType: "ConditionsList",
        isVisible: true,
        component: QuestionTypeMedicalConditionList,
        extras: {
          type: QuestionStatusType.CONDITIONS,
        },
      },
    ],
  },
  AllergiesStatusAndList: {
    questions: [
      {
        questionType: "AllergiesStatus",
        isVisible: true,
        component: QuestionTypeStatus,
        extras: {
          type: QuestionStatusType.ALLERGIES,
        },
      },
      {
        questionType: "AllergiesList",
        isVisible: true,
        component: QuestionTypeMedicalConditionList,
        extras: {
          type: QuestionStatusType.ALLERGIES,
        },
      },
    ],
  },
};

interface EditHealthMeasurementProps {
  healthMeasurementIdentifier: string | undefined;
  returnPath: string;
}

function findHealthMeasurementMetadata(
  healthMeasurementIdentifier: string
): HealthMeasurementMetadataItem | null {
  return healthMeasurementsMetadata[healthMeasurementIdentifier] || null;
}

export default function EditHealthMeasurement({
  healthMeasurementIdentifier,
  returnPath,
}: EditHealthMeasurementProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useGlobalStore();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fullHealthProfile, setFullHealthProfile] = useState<any>(null);
  const [healthMeasurement, setHealthMeasurement] =
    useState<HealthMeasurementMetadataItem | null>(null);
  const [isFetchingHealthProfile, setIsFetchingHealthProfile] = useState(false);
  const [isFormSubmitEnabled, setIsFormSubmitEnabled] = useState(false);
  const [isFormSubmissionInProgress, setIsFormSubmissionInProgress] =
    useState(false);
  const [isFormError, setIsFormError] = useState(false);

  const { showSnackbar } = useContext(SnackbarContext);

  function fetchHealthProfileAndUpdateLocalState() {
    setIsFetchingHealthProfile(true);
    getHealthProfile()
      .then((response) => {
        setFullHealthProfile(response);
        setIsFetchingHealthProfile(false);
      })
      .catch(() => {
        setIsFetchingHealthProfile(false);
        showSnackbar({
          message: t("common.genericErrorMessage"),
          animate: true,
          type: "error",
          direction: "down",
          duration: 5000,
          position: { horizontal: "center", vertical: "top" },
        });
        navigate("/home/my-health/health-profile");
      });
  }

  useEffect(() => {
    const healthMeasurementMetadata = findHealthMeasurementMetadata(
      healthMeasurementIdentifier || ""
    );
    if (healthMeasurementMetadata) {
      setHealthMeasurement(healthMeasurementMetadata);
      fetchHealthProfileAndUpdateLocalState();

      trackFormEvent(AnalyticsEvent.FORM_START, {
        formName: `Edit health measurement: ${convertPascalCaseToWords(
          healthMeasurementIdentifier || ""
        )}`,
      });
    }
  }, [healthMeasurementIdentifier]);

  useEffect(() => {
    let shouldRedirect = false;

    if (fullHealthProfile) {
      const isHealthMeasurementEditSupportedByUI =
        state.healthMeasurementsWithEditView.filter(
          (clickThroughIdentifier) => {
            return clickThroughIdentifier === healthMeasurementIdentifier;
          }
        ).length > 0;

      if (!healthMeasurement) {
        shouldRedirect = true;
      }

      if (!isHealthMeasurementEditSupportedByUI) {
        shouldRedirect = true;
      }

      if (shouldRedirect) {
        navigate(returnPath);
      } else {
        setHealthMeasurement((previousState: any) => {
          const questionsWithAnswersAdded = healthMeasurement?.questions.map(
            (question) => {
              const answerInFullHealthProfile = fullHealthProfile.items.find(
                (item: any) => item.questionType === question.questionType
              ) || {
                questionId: null,
                data: null,
              };
              question.questionId = answerInFullHealthProfile.questionId;
              question.initialValue = convertStringToJSON(
                answerInFullHealthProfile.data
              );
              return question;
            }
          );
          return {
            ...previousState,
            questions: questionsWithAnswersAdded,
          };
        });
      }
    }
  }, [fullHealthProfile, navigate, state.healthMeasurementsWithEditView]);

  useEffect(() => {
    if (!healthMeasurement) {
      setIsFormSubmitEnabled(false);
    } else {
      const isEveryVisibleAnswerValid = healthMeasurement.questions
        .filter((_question: any) => {
          return _question.isVisible === true;
        })
        .every((_question: any) => {
          return _question.isValid === true;
        });

      setIsFormSubmitEnabled(isEveryVisibleAnswerValid);
    }
  }, [healthMeasurement]);

  function onModalOpen() {
    setIsModalOpen(true);
  }

  function onModalClose() {
    setIsModalOpen(false);
  }

  function onQuestionChange(
    question: HealthMeasurementMetadataItemQuestion,
    answer: Answer
  ) {
    setHealthMeasurement((previousState: any) => {
      let shouldAllQuestionsExceptTheFirstBeHidden = true;

      const questionsWithAnswersAdded = previousState?.questions
        .map((_question: any) => {
          if (_question.questionId === question.questionId) {
            _question.currentValue = answer.data;
            _question.isValid = answer.isValid;
          }

          return _question;
        })
        .map((_question: any, _questionIndex: number) => {
          const isTheFirstQuestionInThisQuestionGroupCurrentlySetToTrue =
            previousState?.questions[0]?.currentValue?.value === "Yes"
              ? true
              : false;

          if (
            answer.data.value === "Yes" ||
            isTheFirstQuestionInThisQuestionGroupCurrentlySetToTrue
          ) {
            shouldAllQuestionsExceptTheFirstBeHidden = false;
          }

          if (_questionIndex > 0) {
            _question.isVisible = !shouldAllQuestionsExceptTheFirstBeHidden;
          }

          return _question;
        });

      return {
        ...previousState,
        questions: questionsWithAnswersAdded,
      };
    });
  }

  function onCancelButtonClick() {
    trackEvent({
      event: "action.healthProfileMeasurementEditCancelled",
      healthMeasurementIdentifier: convertPascalCaseToWords(
        healthMeasurementIdentifier || ""
      ),
    });
    navigate(-1);
  }

  function onFormSubmit(e: FormEvent) {
    e.preventDefault();

    setIsFormError(false);

    if (healthMeasurement?.questions) {
      setIsFormSubmissionInProgress(true);
      const requests = healthMeasurement?.questions
        .filter((question) => {
          return question.isVisible;
        })
        .map((question) => {
          return healthProfileNewEntry(question.questionType, {
            value: question.currentValue,
          });
        });
      Promise.all(requests)
        .then(() => {
          const transformedHealthMeasurementIdentifier =
            convertPascalCaseToWords(healthMeasurementIdentifier || "");
          setIsFormSubmissionInProgress(false);
          fetchHealthProfileAndUpdateLocalState();
          trackEvent({
            event: "action.healthProfileMeasurementEdited",
            healthMeasurementIdentifier: transformedHealthMeasurementIdentifier,
          });
          trackFormEvent(AnalyticsEvent.FORM_COMPLETE, {
            formName: `Edit health measurement: ${transformedHealthMeasurementIdentifier}`,
          });
          showSnackbar({
            message: t(
              "EditHealthMeasurement.measurementEdited.success.message",
              {
                healthMeasurementName: t(
                  `HealthProfile.${healthMeasurementIdentifier}.name` as any
                ),
              }
            ),
            animate: true,
            type: "success",
            direction: "down",
            position: { horizontal: "center", vertical: "top" },
          });
          navigate("/home/my-health/health-profile");
        })
        .catch(() => {
          setIsFormSubmissionInProgress(false);
          setIsFormError(true);
        });
    }
  }

  return (
    <>
      {isFormSubmissionInProgress || isFetchingHealthProfile ? (
        <FullscreenLoadingIndicator />
      ) : (
        <Stack justifyContent="space-between" sx={{ minHeight: "100%" }}>
          <Box sx={{ p: 2 }}>
            <CircledBackButton
              showLabel={true}
              onClick={() => navigate(returnPath)}
            />
          </Box>

          <Box sx={{ p: 2 }}>
            <form onSubmit={onFormSubmit}>
              <Stack spacing={2}>
                <FaceIconBrandLarge />

                <Stack spacing={4}>
                  {healthMeasurement &&
                    healthMeasurement.questions.map((question) => {
                      if (!question.isVisible) {
                        return null;
                      } else {
                        return createElement(question.component, {
                          key: question.questionType,
                          onChange: onQuestionChange.bind(null, question),
                          data: question.initialValue,
                          ...question.extras,
                        });
                      }
                    })}
                </Stack>

                <ButtonWithAnalytics
                  page="EditHealthMeasurement"
                  intent="informational"
                  text={t("WhyAreWeAskingYouThisModal.title")}
                  variant="text"
                  onClick={onModalOpen}
                  size="small"
                >
                  {t("WhyAreWeAskingYouThisModal.title")}
                </ButtonWithAnalytics>

                {isFormError ? (
                  <FormInputErrorList
                    errors={[t("common.somethingWentWrong")]}
                  />
                ) : null}

                <Stack direction="row" spacing={2}>
                  <ButtonWithAnalytics
                    page="EditHealthMeasurement"
                    intent="navigational"
                    text={t("common.cancel")}
                    variant="outlined"
                    size="small"
                    onClick={onCancelButtonClick}
                    fullWidth
                  >
                    {t("common.cancel")}
                  </ButtonWithAnalytics>
                  <ButtonWithAnalytics
                    page="EditHealthMeasurement"
                    intent="confirmational"
                    text={t("common.saveButton")}
                    size="small"
                    type="submit"
                    fullWidth
                    disabled={!isFormSubmitEnabled}
                  >
                    {t("common.saveButton")}
                  </ButtonWithAnalytics>
                </Stack>

                <WhyAreWeAskingYouThisModal
                  extras={t(
                    `HealthMeasurements.${healthMeasurementIdentifier}.modalContent` as any
                  )}
                  isOpen={isModalOpen}
                  onClose={onModalClose}
                />
              </Stack>
            </form>
          </Box>
        </Stack>
      )}
    </>
  );
}
