import MyPurchases from "@/features/More/components/MyPurchases";
import { trackPageViewV2 } from "@/services/analytics-adapter";
import { useEffect } from "react";

export default function MyPurchasesPage() {
  useEffect(() => {
    trackPageViewV2({
      pageName: "More:My purchases",
      pageSubSection1: "More",
      pageSubSection2: "More:My purchases",
      pageCategory: "More",
    });
  }, []);
  return <MyPurchases />;
}
