import QuestionTypeSmokingStatus from ".";

export default function QuestionTypeSmokingStatusExamples() {
  const onChange = (value: any) => {
    console.log("onChange()", value);
  };

  return (
    <>
      <QuestionTypeSmokingStatus onChange={onChange} />
    </>
  );
}
