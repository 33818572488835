import { Stack } from "@mui/material";
import NoProductsLinkedToMemberError from ".";

export default function NoProductsLinkedToMemberErrorExamples() {
  return (
    <Stack sx={{ height: "100vh", p: 2 }}>
      <NoProductsLinkedToMemberError />
    </Stack>
  );
}
