import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Stack,
  Typography,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useState } from "react";
import ButtonWithLoadingState from "@/features/Common/components/ButtonWithLoadingState";
import IconLoader from "@/features/Common/components/IconLoader";

const buttonColours = [
  "info",
  "primary",
  "secondary",
  "error",
  "errorInverse",
  "success",
  "warning",
  "neutral",
  "whatsApp",
  "accent1",
];
const buttonSizes = ["small", "medium", "large"];

function allButtonColoursAndSizesWith(extras: object): any[][] {
  return buttonColours.map((buttonColour) => {
    const value: any[] = [];
    buttonSizes.forEach((buttonSize) => {
      value.push({
        color: buttonColour,
        size: buttonSize,
        ...extras,
      });
    });
    return value;
  });
}

function ButtonTable(list: any, title: string) {
  return (
    <>
      <h2>{title}</h2>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            {buttonSizes.map(function (buttonSize: any) {
              return (
                <th
                  key={buttonSize}
                  style={{
                    border: "1px solid #ccc",
                    paddingBottom: "4px",
                    paddingTop: "4px",
                  }}
                >
                  Size: {buttonSize}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {list.map((buttonGroup: any, index: number) => {
            return (
              <tr key={`${buttonGroup.color}_${index}`}>
                {Object.keys(buttonGroup).map((buttonGroupKey, index) => {
                  const buttonGroupItem = buttonGroup[buttonGroupKey as any];

                  if (!buttonGroupItem.color) {
                    delete buttonGroupItem.color;
                  }

                  if (!buttonGroupItem.size) {
                    delete buttonGroupItem.size;
                  }

                  return (
                    <td
                      key={`${buttonGroupKey}_${index}`}
                      style={{
                        border: "1px solid #ccc",
                        paddingBottom: "4px",
                        paddingTop: "4px",
                        textAlign: "center",
                      }}
                    >
                      <Button {...(buttonGroupItem as any)}>
                        {buttonGroupItem.children
                          ? buttonGroupItem.children
                          : buttonGroupItem.color}
                      </Button>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default function ButtonExamples() {
  const { t } = useTranslation();
  const [disableLoadingSpinner, setDisableLoadingSpinner] = useState(true);

  setTimeout(() => {
    setDisableLoadingSpinner(false);
  }, 4000);

  return (
    <>
      <Stack spacing={2} divider={<Divider />}>
        {ButtonTable(
          allButtonColoursAndSizesWith({}),
          "{ variant: unspecified('contained') }"
        )}

        {ButtonTable(
          allButtonColoursAndSizesWith({
            fullWidth: true,
          }),
          "{ variant: unspecified('contained'), fullWidth: true }"
        )}

        {ButtonTable(
          allButtonColoursAndSizesWith({
            disabled: true,
          }),
          "{ variant: unspecified('contained'), disabled: true }"
        )}

        {ButtonTable(
          allButtonColoursAndSizesWith({
            variant: "outlined",
          }),
          "{ variant: outlined }"
        )}

        {ButtonTable(
          allButtonColoursAndSizesWith({
            variant: "outlined",
            disabled: true,
          }),
          "{ variant: outlined, disabled: true }"
        )}

        {ButtonTable(
          allButtonColoursAndSizesWith({
            variant: "text",
          }),
          "{ variant: text }"
        )}

        {ButtonTable(
          allButtonColoursAndSizesWith({
            variant: "text",
            disabled: true,
          }),
          "{ variant: text, disabled: true }"
        )}

        {ButtonTable(
          allButtonColoursAndSizesWith({
            startIcon: <IconLoader icon="ChevronNextIcon" />,
          }),
          "{ variant: unspecified('contained'), startIcon }"
        )}

        {ButtonTable(
          allButtonColoursAndSizesWith({
            endIcon: <IconLoader icon="ChevronNextIcon" />,
          }),
          "{ variant: unspecified('contained'), endIcon }"
        )}

        <Stack spacing={2}>
          <Typography variant="h1">Translation text buttons</Typography>
          <Box>
            <Typography>Button with App name translation text</Typography>
            <Button>{t("common.appName")}</Button>
          </Box>
        </Stack>

        <Stack spacing={2}>
          <Typography variant="h1">Buttons with custom styles</Typography>

          <Stack>
            {ButtonTable(
              allButtonColoursAndSizesWith({
                fullWidth: true,
                style: { justifyContent: "flex-end" },
              }),
              "Button with styles property ('flex-end' aligned)"
            )}
          </Stack>

          <Stack>
            {ButtonTable(
              allButtonColoursAndSizesWith({
                sx: { justifyContent: "center" },
                style: { display: "flex", width: "100%" },
              }),
              "Button with sx property: sx applied to parent element"
            )}
          </Stack>

          <Stack>
            {ButtonTable(
              allButtonColoursAndSizesWith({
                fullWidth: true,
                children: (
                  <Box
                    sx={{ justifyContent: "flex-end" }}
                    style={{ display: "flex", width: "100%" }}
                  >
                    Sx Styled button
                  </Box>
                ),
              }),
              "Button with sx property: sx applied to child element"
            )}
          </Stack>

          <Stack>
            <Typography variant="h2">Other examples</Typography>

            <Stack>
              <Button>Button in a Stack</Button>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={1}>
          <Typography variant="h2">ToggleButtonGroup</Typography>
          <Typography variant="h3">Color: Primary</Typography>
          <ToggleButtonGroup
            color="primary"
            value={true}
            size="small"
            exclusive
            fullWidth
          >
            <ToggleButton value={true}>{t("common.yes")}</ToggleButton>
            <ToggleButton value={false}>{t("common.no")}</ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            color="primary"
            value={true}
            size="medium"
            exclusive
            fullWidth
          >
            <ToggleButton value={true}>{t("common.yes")}</ToggleButton>
            <ToggleButton value={false}>{t("common.no")}</ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            color="primary"
            value={true}
            size="large"
            exclusive
            fullWidth
          >
            <ToggleButton value={true}>{t("common.yes")}</ToggleButton>
            <ToggleButton value={false}>{t("common.no")}</ToggleButton>
          </ToggleButtonGroup>

          <Typography variant="h3">Color: Neutral</Typography>
          <ToggleButtonGroup
            color="neutral"
            value={true}
            size="small"
            exclusive
            fullWidth
          >
            <ToggleButton value={true}>{t("common.yes")}</ToggleButton>
            <ToggleButton value={false}>{t("common.no")}</ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            color="neutral"
            value={true}
            size="medium"
            exclusive
            fullWidth
          >
            <ToggleButton value={true}>{t("common.yes")}</ToggleButton>
            <ToggleButton value={false}>{t("common.no")}</ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            color="neutral"
            value={true}
            size="large"
            exclusive
            fullWidth
          >
            <ToggleButton value={true}>{t("common.yes")}</ToggleButton>
            <ToggleButton value={false}>{t("common.no")}</ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        <Stack>
          <Typography variant="h2">Buttons with loading states</Typography>

          <Stack spacing={1}>
            <Stack>
              <Typography variant="h3">
                State: default (no props supplied)
              </Typography>
              <ButtonWithLoadingState onClick={() => console.log("clicked")} />
            </Stack>

            <Stack>
              <Typography variant="h3">State: hidden spinner</Typography>
              <ButtonWithLoadingState shouldShowLoadingSpinner={false} />
            </Stack>

            <Stack>
              <Typography variant="h3">
                State: hidden spinner, with some children
              </Typography>
              <ButtonWithLoadingState shouldShowLoadingSpinner={false}>
                ...
              </ButtonWithLoadingState>
            </Stack>

            <Stack>
              <Typography variant="h3">
                State: hidden spinner, with some text
              </Typography>
              <ButtonWithLoadingState shouldShowLoadingSpinner={false}>
                <Typography variant="body2" color="neutral.100">
                  Please wait...
                </Typography>
              </ButtonWithLoadingState>
            </Stack>

            <Stack>
              <Typography variant="h3">
                State: with button custom color
              </Typography>
              <ButtonWithLoadingState color="error" />
            </Stack>

            <Stack>
              <Typography variant="h3">
                State: with loading spinner custom color, and size
              </Typography>
              <ButtonWithLoadingState
                spinnerSize={50}
                spinnerColor="accent3.500"
              />
            </Stack>

            <Stack>
              <Typography variant="h3">
                State: has multiple children, and a controlled disabled state
              </Typography>
              <ButtonWithLoadingState
                variant="outlined"
                disabled={disableLoadingSpinner}
                shouldShowLoadingSpinner={false}
              >
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography
                    color={
                      disableLoadingSpinner ? "neutral.100" : "success.main"
                    }
                  >
                    Please wait...
                  </Typography>
                  <IconLoader
                    icon="CheckmarkIcon"
                    fontSize="medium"
                    color={disableLoadingSpinner ? "disabled" : "success"}
                  />
                </Stack>
              </ButtonWithLoadingState>
            </Stack>

            <Stack>
              <Typography variant="h3">State: with click handler</Typography>
              <ButtonWithLoadingState onClick={() => console.log("clicked")} />
            </Stack>

            <Stack>
              <Typography variant="h3">State: disabled with spinner</Typography>
              <ButtonWithLoadingState disabled={true} color="error" />
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <br />
    </>
  );
}
