import { Stack } from "@mui/material";
import PathologyPanelDetails from ".";
import { PathologyPanel } from "@/services/core-api-adapter";

const PANELS = [
  {
    panelId: "00eb8796-ac37-496e-aecc-1fac68ed2211",
    panelName: "CD4 & Viral Load",
    amountTotal: 701.0,
    panelChargeCode: "PATH_VIRAL_LOAD",
    panelDescription:
      "This is the initial description of the Panel CD4 & Viral Load",
    status: "Active",
    orderIndex: 12,
    created: "2024-10-18T07:57:15.491446+00:00",
    panelPathologyTests: [
      {
        testName: "CD4",
        testAmount: 160.0,
        testCode: "CD4CD8",
      },
      {
        testName: "HIV VIRAL LOAD",
        testAmount: 541.0,
        testCode: "HIVVL",
      },
    ],
  },
  {
    panelId: "b49744ab-3a82-4ccc-b49c-614f49bb307c",
    panelName: "HbA1c",
    amountTotal: 134.0,
    panelChargeCode: "PATH_HBA1C",
    panelDescription: "This is the initial description of the Panel HbA1c",
    status: "Active",
    orderIndex: 14,
    created: "2024-10-18T07:57:15.491446+00:00",
    panelPathologyTests: [
      {
        testName: "HbA1C",
        testAmount: 134.0,
        testCode: "HBA",
      },
    ],
  },
  {
    panelId: "c9759cb8-052a-4f01-9ec1-81342898e0d9",
    panelName: "Antenatal",
    amountTotal: 165.0,
    panelChargeCode: "PATH_ANTENATAL",
    panelDescription: "This is the initial description of the Panel Antenatal",
    status: "Active",
    orderIndex: 13,
    created: "2024-10-18T07:57:15.491446+00:00",
    panelPathologyTests: [
      {
        testName: "HCG Quantitative",
        testAmount: 165.0,
        testCode: "HCGQ",
      },
    ],
  },
  {
    panelId: "122bb0ff-cd68-402e-a6ce-7f66cacc869a",
    panelName: "STD Screening (excl HIV)",
    amountTotal: 928.0,
    panelChargeCode: "PATH_STD_EXCL_HIV",
    panelDescription:
      "This is the initial description of the Panel STD Screening (excl HIV)",
    status: "Active",
    orderIndex: 6,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "STD PCR",
        testAmount: 618.0,
        testCode: "STDPCR",
      },
      {
        testName: "Hep B surface antigen",
        testAmount: 155.0,
        testCode: "HEPBSAG",
      },
      {
        testName: "Syphilis RPR + T Pallidum ELISA",
        testAmount: 155.0,
        testCode: "RPR",
      },
    ],
  },
  {
    panelId: "162fa0d5-525d-4b06-b696-1bc0a9aab8dc",
    panelName: "Syphilis",
    amountTotal: 155.0,
    panelChargeCode: "PATH_SYPHILIS",
    panelDescription: "This is the initial description of the Panel Syphilis",
    status: "Active",
    orderIndex: 8,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "Syphilis RPR + T Pallidum ELISA",
        testAmount: 155.0,
        testCode: "RPR",
      },
    ],
  },
  {
    panelId: "192878d4-0a7d-4481-b193-3382da78e5d3",
    panelName: "STD PCR",
    amountTotal: 618.0,
    panelChargeCode: "PATH_STD_PCR",
    panelDescription: "This is the initial description of the Panel STD PCR",
    status: "Active",
    orderIndex: 7,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "STD PCR",
        testAmount: 618.0,
        testCode: "STDPCR",
      },
    ],
  },
  {
    panelId: "30cb47f4-a4c7-4a7c-8a61-adf446573997",
    panelName: "HIV Screening",
    amountTotal: 164.0,
    panelChargeCode: "PATH_HIV_SCREENING",
    panelDescription:
      "This is the initial description of the Panel HIV Screening",
    status: "Active",
    orderIndex: 4,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "HIV Elisa",
        testAmount: 164.0,
        testCode: "HIV",
      },
    ],
  },
  {
    panelId: "488cf288-44dc-4a7a-9f16-e878e71ba9ef",
    panelName: "Hepatitis B",
    amountTotal: 155.0,
    panelChargeCode: "PATH_HEPATITIS_B",
    panelDescription:
      "This is the initial description of the Panel Hepatitis B",
    status: "Active",
    orderIndex: 9,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "Hep B surface antigen",
        testAmount: 155.0,
        testCode: "HEPBSAG",
      },
    ],
  },
  {
    panelId: "57e57183-884d-4843-be4a-fbbaeb61f623",
    panelName: "Male Over 45",
    amountTotal: 501.0,
    panelChargeCode: "PATH_MALE_OVER_45",
    panelDescription:
      "This is the initial description of the Panel Male Over 45",
    status: "Active",
    orderIndex: 3,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "PSA",
        testAmount: 124.0,
        testCode: "PSA",
      },
      {
        testName: "Fasting glucose",
        testAmount: 36.0,
        testCode: "GLF",
      },
      {
        testName: "Haemoglobin",
        testAmount: 26.0,
        testCode: "HB",
      },
      {
        testName: "ALT",
        testAmount: 52.0,
        testCode: "ALT",
      },
      {
        testName: "Creatinine",
        testAmount: 41.0,
        testCode: "CR",
      },
      {
        testName: "TSH",
        testAmount: 165.0,
        testCode: "TSH",
      },
      {
        testName: "LDL",
        testAmount: 57.0,
        testCode: "LDL",
      },
    ],
  },
  {
    panelId: "5ae2adc4-3a5c-4e35-80a8-ef0a04a26477",
    panelName: "Aneamia",
    amountTotal: 242.0,
    panelChargeCode: "PATH_ANEAMIA",
    panelDescription: "This is the initial description of the Panel Aneamia",
    status: "Active",
    orderIndex: 10,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "FBC",
        testAmount: 93.0,
        testCode: "FBC",
      },
      {
        testName: "Ferritin",
        testAmount: 149.0,
        testCode: "FER",
      },
    ],
  },
  {
    panelId: "8406d19f-fe2a-4273-8540-c9d7b4ec157b",
    panelName: "Male Under 45",
    amountTotal: 377.0,
    panelChargeCode: "PATH_MALE_UNDER_45",
    panelDescription:
      "This is the initial description of the Panel Male Under 45",
    status: "Active",
    orderIndex: 2,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "LDL",
        testAmount: 57.0,
        testCode: "LDL",
      },
      {
        testName: "TSH",
        testAmount: 165.0,
        testCode: "TSH",
      },
      {
        testName: "Fasting glucose",
        testAmount: 36.0,
        testCode: "GLF",
      },
      {
        testName: "Haemoglobin",
        testAmount: 26.0,
        testCode: "HB",
      },
      {
        testName: "Creatinine",
        testAmount: 41.0,
        testCode: "CR",
      },
      {
        testName: "ALT",
        testAmount: 52.0,
        testCode: "ALT",
      },
    ],
  },
  {
    panelId: "865eb4bb-5b80-4988-9dfb-2d0423a4fc29",
    panelName: "HIV Monitoring",
    amountTotal: 820.0,
    panelChargeCode: "PATH_HIV_MONITORING",
    panelDescription:
      "This is the initial description of the Panel HIV Monitoring",
    status: "Active",
    orderIndex: 11,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "Creatinine",
        testAmount: 41.0,
        testCode: "CR",
      },
      {
        testName: "CD4",
        testAmount: 160.0,
        testCode: "CD4CD8",
      },
      {
        testName: "HIV VIRAL LOAD",
        testAmount: 541.0,
        testCode: "HIVVL",
      },
      {
        testName: "ALT",
        testAmount: 52.0,
        testCode: "ALT",
      },
      {
        testName: "Haemoglobin",
        testAmount: 26.0,
        testCode: "HB",
      },
    ],
  },
  {
    panelId: "950a8850-fdc4-4bd0-b64d-80f772de2a72",
    panelName: "STD Screening (incl HIV)",
    amountTotal: 1092.0,
    panelChargeCode: "PATH_STD_INCL_HIV",
    panelDescription:
      "This is the initial description of the Panel STD Screening (incl HIV)",
    status: "Active",
    orderIndex: 5,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "STD PCR",
        testAmount: 618.0,
        testCode: "STDPCR",
      },
      {
        testName: "HIV Elisa",
        testAmount: 164.0,
        testCode: "HIV",
      },
      {
        testName: "Syphilis RPR + T Pallidum ELISA",
        testAmount: 155.0,
        testCode: "RPR",
      },
      {
        testName: "Hep B surface antigen",
        testAmount: 155.0,
        testCode: "HEPBSAG",
      },
    ],
  },
  {
    panelId: "ccba2c3a-bbb5-4300-bfe9-c0e51a992d66",
    panelName: "Female",
    amountTotal: 377.0,
    panelChargeCode: "PATH_FEMALE",
    panelDescription: "This is the initial description of the Panel Female",
    status: "Active",
    orderIndex: 1,
    created: "2024-10-14T11:18:50.704215+00:00",
    panelPathologyTests: [
      {
        testName: "Fasting glucose",
        testAmount: 36.0,
        testCode: "GLF",
      },
      {
        testName: "Haemoglobin",
        testAmount: 26.0,
        testCode: "HB",
      },
      {
        testName: "TSH",
        testAmount: 165.0,
        testCode: "TSH",
      },
      {
        testName: "LDL",
        testAmount: 57.0,
        testCode: "LDL",
      },
      {
        testName: "ALT",
        testAmount: 52.0,
        testCode: "ALT",
      },
      {
        testName: "Creatinine",
        testAmount: 41.0,
        testCode: "CR",
      },
    ],
  },
];

export default function PathologyPanelDetailsExamples() {
  return (
    <Stack>
      {PANELS.map((panel, index) => (
        <Stack key={index}>
          <PathologyPanelDetails
            panel={panel as unknown as PathologyPanel}
            onBuyButtonClick={() => {
              console.log("buy button click");
            }}
          />
        </Stack>
      ))}
    </Stack>
  );
}
