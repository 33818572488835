import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";
import Panel from "@/features/Common/components/Panel";
import PathologyStatusIndicator, {
  PathologyOrderStatus,
} from "@/features/Pathology/components/PathologyStatusIndicator";
import theme, { defaultFullscreenPageStyling, shadows } from "@/theme";
import { formatDateToReadableString, getShortDayFromDate } from "@/utils";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CircledBackButton from "../../../Common/components/CircledBackButton";
import IconLoader from "../../../Common/components/IconLoader";
import StepsCounter from "../../../Common/components/StepsCounter";

interface PathologyOrderDetailsProps {
  isOrderPending: boolean;
  orderDate: string;
  paymentDate?: string;
}

interface ItemPanelProps {
  isComplete: boolean;
  title: string;
  stepNumber: number;
  pendingStatus: PathologyOrderStatus;
  completedStatus: PathologyOrderStatus;
  date?: string;
  children?: React.ReactNode;
}

function ItemPanel({
  isComplete = false,
  title,
  stepNumber,
  pendingStatus,
  completedStatus,
  date,
  children,
}: ItemPanelProps) {
  const { t } = useTranslation();
  return (
    <Panel
      sx={{
        border: isComplete
          ? "inherit"
          : `1px solid ${theme.palette.neutral[300]}`,
        boxShadow: isComplete ? shadows.light : "none",
      }}
    >
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack display="flex" flexDirection="row" gap={1}>
          <StepsCounter
            disabled={isComplete ? false : true}
            stepCounter={stepNumber}
          />
          <Stack>
            <Typography variant="body1" color="neutral.main" fontWeight="bold">
              {title}
            </Typography>
            <Typography variant="body2" color="neutral.main">
              {date
                ? `${getShortDayFromDate(new Date(date))}, ${formatDateToReadableString(new Date(date))}`
                : t("PathologyOrderDetails.dateTBC")}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <PathologyStatusIndicator
            status={isComplete ? completedStatus : pendingStatus}
          />
        </Stack>
      </Stack>
      {children}
    </Panel>
  );
}

export default function PathologyOrderDetails({
  isOrderPending,
  orderDate,
  paymentDate,
}: PathologyOrderDetailsProps) {
  const { t } = useTranslation();
  return (
    <Stack spacing={3} sx={{ ...defaultFullscreenPageStyling }}>
      <CircledBackButton showLabel={true} />
      <Stack justifyContent="center" textAlign="center" spacing={2}>
        <Typography variant="h2">
          {t("PathologyOrderDetails.hivScreening.title")}
        </Typography>
        {isOrderPending && (
          <Stack spacing={0.5}>
            <Typography variant="body1" fontWeight="bold" color="neutral.main">
              {t("PathologyOrderDetails.subtitle")}
            </Typography>
            <Typography variant="body2" color="neutral.500">
              {t("PathologyOrderDetails.body")}
            </Typography>
          </Stack>
        )}
        {!isOrderPending && (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="row"
            gap={2}
          >
            <Stack spacing={0.5}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="neutral.main"
              >
                {t("PathologyOrderSummary.orderNumber.title")}
              </Typography>
              <Typography variant="body2" color="neutral.500">
                ######
              </Typography>
            </Stack>
          </Box>
        )}
      </Stack>
      <Typography variant="h4">{t("PathologyOrderDetails.steps")}</Typography>
      <Panel>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack display="flex" flexDirection="row" gap={1}>
            <StepsCounter stepCounter={1} />
            <Stack>
              <Typography
                variant="body1"
                color="neutral.main"
                fontWeight="bold"
              >
                {t("PathologyOrderDetails.payment.title")}
              </Typography>
              {paymentDate && (
                <Typography variant="body2" color="neutral.main">
                  {`${getShortDayFromDate(new Date(paymentDate))}, ${formatDateToReadableString(new Date(paymentDate))}`}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack>
            <PathologyStatusIndicator status={PathologyOrderStatus.PAID} />
          </Stack>
        </Stack>
      </Panel>
      <ItemPanel
        isComplete={!isOrderPending}
        title={t("PathologyOrderDetails.order.title")}
        stepNumber={2}
        pendingStatus={PathologyOrderStatus.ORDER_PENDING}
        completedStatus={PathologyOrderStatus.ORDER_PROCESSED}
        date={orderDate}
      ></ItemPanel>

      <ItemPanel
        isComplete={!isOrderPending}
        title={t("PathologyOrderDetails.bloodTest.title")}
        stepNumber={3}
        pendingStatus={PathologyOrderStatus.TEST_TO_BE_COMPLETED}
        completedStatus={PathologyOrderStatus.TEST_COMPLETED}
      >
        <Stack spacing={1} pt={1}>
          <ButtonWithAnalytics
            page="PathologyOrderSummary"
            text={t("PathologyOrderSummary.howToPrepare.button.title")}
            intent="navigational"
            variant="outlined"
            size="small"
            fullWidth
          >
            {t("PathologyOrderSummary.howToPrepare.button.title")}
          </ButtonWithAnalytics>
          <ButtonWithAnalytics
            page="PathologyOrderSummary"
            text={t("PathologyOrderSummary.findAFacility.button.title")}
            intent="navigational"
            variant="outlined"
            size="small"
            fullWidth
          >
            {t("PathologyOrderSummary.findAFacility.button.title")}
          </ButtonWithAnalytics>
        </Stack>
      </ItemPanel>

      <ItemPanel
        isComplete={!isOrderPending}
        title={
          isOrderPending
            ? t("PathologyOrderDetails.results.pending.title")
            : t("PathologyOrderDetails.results.complete.title")
        }
        stepNumber={4}
        pendingStatus={null as any}
        completedStatus={PathologyOrderStatus.RESULTS_RECEIVED}
      >
        <Stack pt={1}>
          <ButtonWithAnalytics
            page="PathologyDetailsSummary"
            text={t("PathologyOrderDetails.results.button")}
            intent="navigational"
            size="small"
            fullWidth
            disabled={isOrderPending ? true : false}
          >
            {t("PathologyOrderDetails.results.button")}
          </ButtonWithAnalytics>
        </Stack>
      </ItemPanel>

      {!isOrderPending && (
        <Stack
          spacing={2}
          sx={{
            backgroundColor: theme.palette.primary[100],
            borderRadius: 2,
            padding: 2,
          }}
        >
          <Stack flexDirection="row" gap={1}>
            <IconLoader icon="PadlockIcon" />
            <Stack>
              <Typography variant="h4">
                {t("PathologyOrderDetails.needHelp.title")}
              </Typography>
              <Typography variant="h4" fontWeight="400">
                {t("PathologyOrderDetails.needHelp.body")}
              </Typography>
            </Stack>
          </Stack>
          <ButtonWithAnalytics
            page="PathologyOrderDetails"
            text={t("PathologyOrderDetails.needHelp.button")}
            intent="navigational"
            variant="outlined"
            color="neutral"
            onClick={() => console.log("onClick")}
          >
            {t("PathologyOrderDetails.needHelp.button")}
          </ButtonWithAnalytics>
        </Stack>
      )}
    </Stack>
  );
}
