import { ActiveConsultationOutstandingPayment } from ".";
import { MemberProductPaymentRatesChargeCode } from "../../../../services/core-api-adapter";

export default function ActiveConsultationOutstandingPaymentExamples() {
  function onContinue() {
    console.log("onContinue");
  }

  function onBack() {
    console.log("onBack");
  }

  return (
    <>
      <ActiveConsultationOutstandingPayment
        priceFormatted={"R175"}
        redirectUrl={"https://www.google.com/"}
        chargeCode={"A123" as MemberProductPaymentRatesChargeCode}
        onContinue={onContinue}
        onBack={onBack}
      />
      <ActiveConsultationOutstandingPayment
        priceFormatted={"R175"}
        redirectUrl={"https://www.google.com/"}
        chargeCode={MemberProductPaymentRatesChargeCode.DIGITAL_DOCTOR_CONSULT}
        onContinue={onContinue}
        onBack={onBack}
      />
      <ActiveConsultationOutstandingPayment
        priceFormatted={"R175"}
        redirectUrl={"https://www.google.com/"}
        chargeCode={
          MemberProductPaymentRatesChargeCode.ONLINE_DOCTOR_CONSULTATION
        }
        onContinue={onContinue}
        onBack={onBack}
      />
      <ActiveConsultationOutstandingPayment
        priceFormatted={"R175"}
        redirectUrl={"https://www.google.com/"}
        chargeCode={MemberProductPaymentRatesChargeCode.CHAT_TO_A_NURSE}
        onContinue={onContinue}
        onBack={onBack}
      />
    </>
  );
}
