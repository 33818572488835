import { useState } from "react";
import { Button, Typography } from "@mui/material";
import WhyAreWeAskingYouThisModal from "../../../Common/components/WhyAreWeAskingYouThisModal";
import NoOTPReceivedModal from "../../../Common/components/NoOTPReceivedModal";
import GetMedicalAssistanceModal from "../../../ChatForMedicalAssistance/components/GetMedicalAssistanceModal";
import SuccessConfirmationModal from "../../../Common/components/SuccessConfirmationModal";
import HealthProfileLegendModal from "../../../MyHealth/components/HealthProfileLegendModal";
import RejectTermsOrConsentDialog from "../../../MemberRequiredActions/components/RejectTermsOrConsentDialog";
import HaveANurseCallMeSuccessModal from "../../../ChatForMedicalAssistance/components/HaveANurseCallMeSuccessModal";
import UpdateDetailsModal from "../../../Common/components/UpdateDetailsModal";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import GetHelpModal from "../../../Common/components/GetHelpModal";
import OutsideConsultationOperatingHoursModal from "../../../MedicalConsultations/components/OutsideConsultationOperatingHoursModal";
import DirectToDoctorInPersonHelpModal from "../../../MedicalConsultations/components/DirectToDoctorInPersonHelpModal";
import {
  ChatBotOption,
  IdentityDocumentType,
  MemberServiceOfferingUsage,
} from "../../../../services/core-api-adapter";
import { ConfirmDependentRemoval } from "@/features/Membership/components/ConfirmDependentRemoval";
import ChatBotOptionSelectionModal from "@/features/ChatBot/components/ChatBotOptionSelectionModal";
import SwitchAuthIdentificationTypeModal, {
  ModalKeys,
} from "@/features/Auth/components/SwitchAuthIdentificationTypeModal";
import AvailablePromoCodesModal from "@/features/Common/components/AvailablePromoCodesModal";

export default function DefaultDialogExamples() {
  const { t } = useTranslation();

  const [
    isWhyAreWeAskingYouThisModalOpen,
    setIsWhyAreWeAskingYouThisModalOpen,
  ] = useState(false);
  const [isNoOTPReceivedModalOpen, setIsNoOTPReceivedModalOpen] =
    useState(false);
  const [isGetMedicalAssistanceModalOpen, setIsGetMedicalAssistanceModalOpen] =
    useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isHealthProfileLegendModalOpen, setIsHealthProfileLegendModalOpen] =
    useState(false);
  const [
    isRejectTermsOrConsentDialogOpen,
    setIsRejectTermsOrConsentDialogOpen,
  ] = useState(false);
  const [
    isUpdateContactDetailsPhoneNumberModalOpen,
    setIsUpdateContactDetailsPhoneNumberModalOpen,
  ] = useState(false);
  const [
    isCollectAuthIdentificationTypeModalOpen,
    setIsCollectAuthIdentificationTypeModalOpen,
  ] = useState(false);

  const [isHaveANurseCallMeModalOpen, setIsHaveANurseCallMeModalOpen] =
    useState(false);

  const [isGetHelpModalOpen, setIsGetHelpModalOpen] = useState(false);

  const [isAddedToWaitingListModalOpen, setIsAddedToWaitingListModalOpen] =
    useState(false);
  const [
    isOutsideOperatingHoursModalOpen,
    setIsOutsideOperatingHoursModalOpen,
  ] = useState(false);

  const [
    isChatBotOptionSelectionModalOpen,
    setIsChatBotOptionSelectionModalOpen,
  ] = useState(false);

  const [isPersonHelpModalOpen, setIsPersonHelpModalOpen] = useState(false);

  const [isAvailablePromoCodesModalOpen, setIsAvailablePromoCodesModalOpen] =
    useState(false);

  const [
    isConfirmDependentRemovalModalOpen,
    setIsConfirmDependentRemovalModalOpen,
  ] = useState(false);

  const onModalClose = (setterFunction: (value: boolean) => void) => {
    console.log("close modal");
    setterFunction(false);
  };

  const onModalOpen = (setterFunction: (value: boolean) => void) => {
    console.log("open modal");
    setterFunction(true);
  };

  const navigate = useNavigate();
  const onSwitchAuthTypeHandler = () => {
    navigate("/login");
  };

  return (
    <>
      <Typography variant="h2">Modal Examples</Typography>

      <Button
        variant="text"
        onClick={() => onModalOpen(setIsWhyAreWeAskingYouThisModalOpen)}
        size="small"
      >
        Why Are We Asking You This?
      </Button>
      <WhyAreWeAskingYouThisModal
        isOpen={isWhyAreWeAskingYouThisModalOpen}
        onClose={() => onModalClose(setIsWhyAreWeAskingYouThisModalOpen)}
        extras={"Some text goes here"}
      />

      <Button
        variant="text"
        onClick={() => onModalOpen(setIsNoOTPReceivedModalOpen)}
      >
        No OTP Received
      </Button>
      <NoOTPReceivedModal
        isOpen={isNoOTPReceivedModalOpen}
        onClose={() => onModalClose(setIsNoOTPReceivedModalOpen)}
        extras={{ phoneNumber: "0123456879" }}
      />

      <Button
        variant="text"
        onClick={() => {
          onModalOpen(setIsGetMedicalAssistanceModalOpen);
        }}
      >
        Get medical assistance
      </Button>
      {isGetMedicalAssistanceModalOpen && <GetMedicalAssistanceModal />}

      <Button
        variant="text"
        onClick={() => {
          onModalOpen(setIsSuccessModalOpen);
        }}
      >
        Success modal
      </Button>
      <SuccessConfirmationModal
        isOpen={isSuccessModalOpen}
        onClose={() => onModalClose(setIsSuccessModalOpen)}
        body={["Great! Your contact details have been updated"]}
      />

      <Button
        variant="text"
        onClick={() => {
          onModalOpen(setIsHealthProfileLegendModalOpen);
        }}
      >
        Health profile legend modal
      </Button>
      <HealthProfileLegendModal
        isOpen={isHealthProfileLegendModalOpen}
        onClose={() => onModalClose(setIsHealthProfileLegendModalOpen)}
      />

      <Button
        variant="text"
        onClick={() => {
          onModalOpen(setIsRejectTermsOrConsentDialogOpen);
        }}
      >
        Reject Terms Or Consent Dialog
      </Button>
      <RejectTermsOrConsentDialog
        isOpen={isRejectTermsOrConsentDialogOpen}
        onClose={() => onModalClose(setIsRejectTermsOrConsentDialogOpen)}
      />

      <Button
        variant="text"
        onClick={() => {
          onModalOpen(setIsCollectAuthIdentificationTypeModalOpen);
        }}
      >
        What is an UNU ID modal
      </Button>
      <SwitchAuthIdentificationTypeModal
        isOpen={isCollectAuthIdentificationTypeModalOpen}
        onClose={() =>
          onModalClose(setIsCollectAuthIdentificationTypeModalOpen)
        }
        extras={{
          modalLabelKey: ModalKeys.WHAT_IS_AN_UNUID,
          authTypeLabel: ModalKeys.RSA_ID_OR_PASSPORT,
          onRatherSignInWithDifferentAuthType: onSwitchAuthTypeHandler,
          onBackButtonClick: () =>
            onModalClose(setIsCollectAuthIdentificationTypeModalOpen),
        }}
      />

      <Button
        variant="text"
        onClick={() => {
          onModalOpen(setIsUpdateContactDetailsPhoneNumberModalOpen);
        }}
      >
        Update phone number modal
      </Button>
      <UpdateDetailsModal
        title={t("UpdateContactDetailsPhoneNumberModal.title")}
        content={t("UpdateContactDetailsPhoneNumberModal.content")}
        buttonText={t("common.letUsKnow")}
        isOpen={isUpdateContactDetailsPhoneNumberModalOpen}
        onClose={() =>
          onModalClose(setIsUpdateContactDetailsPhoneNumberModalOpen)
        }
        onSubmit={() => {}}
      />

      <Button
        variant="text"
        onClick={() => {
          onModalOpen(setIsHaveANurseCallMeModalOpen);
        }}
      >
        Have a Nurse call me
      </Button>
      <HaveANurseCallMeSuccessModal
        isOpen={isHaveANurseCallMeModalOpen}
        onClose={() => onModalClose(setIsHaveANurseCallMeModalOpen)}
        onSubmit={() => {}}
      />

      <Button
        variant="text"
        onClick={() => {
          onModalOpen(setIsGetHelpModalOpen);
        }}
      >
        Get Help Modal
      </Button>
      <GetHelpModal
        isOpen={isGetHelpModalOpen}
        onClose={() => setIsGetHelpModalOpen(false)}
      />

      <Button
        variant="text"
        onClick={() => {
          onModalOpen(setIsAddedToWaitingListModalOpen);
        }}
      >
        Added to waiting list modal
      </Button>
      <SuccessConfirmationModal
        isOpen={isAddedToWaitingListModalOpen}
        onClose={() => setIsAddedToWaitingListModalOpen(false)}
        label={t("AddedToWaitingListModal.label")}
        title={t("AddedToWaitingListModal.title")}
        body={
          t<any, any, string[]>("AddedToWaitingListModal.body", {
            returnObjects: true,
          }) as []
        }
      />

      <Button
        variant="text"
        onClick={() => {
          onModalOpen(setIsOutsideOperatingHoursModalOpen);
        }}
      >
        Outside Consultation Operating Hours modal
      </Button>
      <OutsideConsultationOperatingHoursModal
        isOpen={isOutsideOperatingHoursModalOpen}
        onClose={() => setIsOutsideOperatingHoursModalOpen(false)}
        onSubmit={() => setIsOutsideOperatingHoursModalOpen(false)}
      />

      <Button
        variant="text"
        onClick={() => {
          onModalOpen(setIsChatBotOptionSelectionModalOpen);
        }}
      >
        Chat bot option selection modal
      </Button>
      <ChatBotOptionSelectionModal
        selectedOption={ChatBotOption.DIABETES}
        isOpen={isChatBotOptionSelectionModalOpen}
        onClose={() => setIsChatBotOptionSelectionModalOpen(false)}
      />

      <Button
        variant="text"
        onClick={() => {
          onModalOpen(setIsPersonHelpModalOpen);
        }}
      >
        In person help modal
      </Button>
      <DirectToDoctorInPersonHelpModal
        isOpen={isPersonHelpModalOpen}
        onFindADoctorButtonClick={() => console.log("onFindADoctorButtonClick")}
        onClose={() => setIsPersonHelpModalOpen(false)}
      />

      <Button
        variant="text"
        onClick={() => {
          onModalOpen(setIsConfirmDependentRemovalModalOpen);
        }}
      >
        Remove dependent modal
      </Button>
      <ConfirmDependentRemoval
        dependent={{
          firstName: "Lesedi",
          lastName: "Persona",
          identityDocumentType: IdentityDocumentType.IdNumber,
          identityDocumentValue: "9603124800089",
        }}
        onDependentRemoval={() => {}}
        confirmingRemoval={true}
        attemptingRemoval={false}
        removalError={false}
        removalSuccess={false}
        shouldModalOpen={isConfirmDependentRemovalModalOpen}
        onModalClose={() => setIsConfirmDependentRemovalModalOpen(false)}
      />

      <Button
        variant="text"
        onClick={() => {
          onModalOpen(setIsAvailablePromoCodesModalOpen);
        }}
      >
        Available promo codes modal
      </Button>
      <AvailablePromoCodesModal
        isModalOpen={isAvailablePromoCodesModalOpen}
        onSkipButtonClick={() => console.log("onSkipButtonClick")}
        onModalClose={() => setIsAvailablePromoCodesModalOpen(false)}
        onContinueButtonClick={() => setIsAvailablePromoCodesModalOpen(false)}
        serviceOfferingUsageItems={
          [
            {
              id: "dead3a9b-5c66-47a9-acbe-0e0003d828ea",
              serviceOfferingType: "ONLINE_NURSE",
              usageStatus: "ACTIVE",
              availableFrom: "2024-10-07T10:07:14.611243+00:00",
              availableTo: "2024-11-06T10:07:14.611243+00:00",
            },
          ] as MemberServiceOfferingUsage[]
        }
      />
    </>
  );
}
