import { Stack, Typography } from "@mui/material";
import PathologyOrderSummary from ".";
import MaxWidthContainer from "../../../Common/components/MaxWidthContainer";
import { PathologyOrderStatus } from "@/services/core-api-adapter";

export default function PathologyOrderSummaryExamples() {
  const pendingData = {
    id: "1",
    panelName: "STD Screening (excl HIV)",
    orderNumber: "12345",
    orderPrice: 100,
    orderPriceFormatted: "R100",
    status: PathologyOrderStatus.PENDING,
    orderPathologyTests: [
      {
        testName: "STD PCR",
        testAmount: 0,
        testCode: "1234",
      },
      {
        testName: "Hep B surface antigen",
        testAmount: 0,
        testCode: "1234",
      },
      {
        testName: "Syphilis RPR + T Pallidum ELISA",
        testAmount: 0,
        testCode: "1234",
      },
    ],
  };

  const completedData = {
    id: "1",
    panelName: "STD Screening (excl HIV)",
    orderNumber: "12345",
    orderPrice: 100,
    orderPriceFormatted: "R100",
    status: PathologyOrderStatus.COMPLETED,
    orderPathologyTests: [
      {
        testName: "STD PCR",
        testAmount: 0,
        testCode: "1234",
      },
      {
        testName: "Hep B surface antigen",
        testAmount: 0,
        testCode: "1234",
      },
      {
        testName: "Syphilis RPR + T Pallidum ELISA",
        testAmount: 0,
        testCode: "1234",
      },
    ],
  };

  return (
    <MaxWidthContainer>
      <Typography variant="h3" color="primary" textAlign="center" mb={2}>
        Pathology Order Summary (Not pending)
      </Typography>
      <Stack height="100vh">
        <PathologyOrderSummary
          orderItem={completedData.panelName}
          orderDate="2027-04-19T13:42:36.521883+00:00"
          amountFormatted={completedData.orderPriceFormatted}
          isOrderPending={completedData.status === PathologyOrderStatus.PENDING}
        />
      </Stack>
      <Typography variant="h3" color="primary" textAlign="center" mb={2}>
        Pathology Order Summary (pending)
      </Typography>
      <Stack height="100vh">
        <PathologyOrderSummary
          orderItem={pendingData.panelName}
          orderDate="2027-04-19T13:42:36.521883+00:00"
          amountFormatted={pendingData.orderPriceFormatted}
          isOrderPending={pendingData.status === PathologyOrderStatus.PENDING}
        />
      </Stack>
    </MaxWidthContainer>
  );
}
