import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import DefaultDialog from "../DefaultDialog";
import WatermarkIcon from "../WatermarkIcon";

interface SuccessConfirmationModalProps {
  body: string[];
  isOpen: boolean;
  onClose: () => void;
  label?: string;
  title?: string;
}

export default function SuccessConfirmationModal({
  body,
  label,
  title,
  isOpen,
  onClose,
}: SuccessConfirmationModalProps) {
  const { t } = useTranslation();

  if (!label) {
    label = body[0];
  }

  return (
    <DefaultDialog
      label={label}
      isOpen={isOpen}
      onClose={onClose}
      fullWidth={true}
    >
      <Box alignSelf="center">
        <WatermarkIcon
          height={55}
          width={55}
          iconSize="medium"
          color="accent1.main"
          foreground="neutral.50"
          Icon={"CheckmarkIcon"}
        />
      </Box>

      {title && (
        <Typography variant="h3" fontWeight={600} align="center">
          {title}
        </Typography>
      )}

      {body.map((text: string, index: number) => (
        <Typography key={String(text + index)} px={2} align="center">
          {text}
        </Typography>
      ))}

      <Button size="small" onClick={onClose}>
        {t("common.close")}
      </Button>
    </DefaultDialog>
  );
}
