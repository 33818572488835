import { Typography } from "@mui/material";
import QuestionTypeSexAtBirth from ".";

export default function QuestionTypeSexAtBirthExamples() {
  const onChange = (answer: any) => {
    console.log("Sex At Birth:", answer);
  };

  return (
    <>
      <Typography variant="h2">QuestionTypeSexAtBirth input example</Typography>
      <QuestionTypeSexAtBirth onChange={onChange} />
    </>
  );
}
