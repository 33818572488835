import { Stack } from "@mui/system";
import AuthIdentificationTypeSelection from ".";

export default function AuthIdentificationTypeSelectionExamples() {
  return (
    <Stack p={2}>
      <AuthIdentificationTypeSelection
        onContinue={(selectedAuthIdentificationType) =>
          console.log(selectedAuthIdentificationType)
        }
      />
    </Stack>
  );
}
