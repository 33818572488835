import QuestionTypeMedicalConditionList from ".";
import { QuestionStatusType } from "../MedicalConditionList";

export default function QuestionTypeMedicalConditionListExamples() {
  const onChange = (value: any) => {
    console.log("onChange()", JSON.stringify(value, null, 2));
  };

  return (
    <>
      <QuestionTypeMedicalConditionList
        type={QuestionStatusType.ALLERGIES}
        onChange={onChange}
      />
    </>
  );
}
