import { Stack } from "@mui/material";
import DoctorBookingConfirmation from ".";

export default function DoctorBookingConfirmationExamples() {
  return (
    <Stack sx={{ height: "100vh" }}>
      <DoctorBookingConfirmation
        onGoBackHome={() => console.log("onGoBackHome")}
        onContinue={() => console.log("onConfirm")}
      />
    </Stack>
  );
}
