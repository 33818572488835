import { Typography, Stack } from "@mui/material";
import SteppedProgressIndicator from ".";

export default function SteppedProgressIndicatorExamples() {
  return (
    <>
      <Typography variant="h2">Stepped Progress Indicator</Typography>
      <Stack spacing={2}>
        <SteppedProgressIndicator />
        <SteppedProgressIndicator value={1} />
        <SteppedProgressIndicator isComplete={true} />

        <SteppedProgressIndicator max={2} />
        <SteppedProgressIndicator max={2} isComplete={true} />

        <SteppedProgressIndicator value={1} max={2} />
        <SteppedProgressIndicator value={2} max={2} />
        <SteppedProgressIndicator value={2} max={2} isComplete={true} />

        <SteppedProgressIndicator value={0} max={5} />
        <SteppedProgressIndicator value={1} max={5} />
        <SteppedProgressIndicator value={2} max={5} />
        <SteppedProgressIndicator value={3} max={5} />
        <SteppedProgressIndicator value={4} max={5} />
        <SteppedProgressIndicator value={5} max={5} />
        <SteppedProgressIndicator value={6} max={5} />
        <SteppedProgressIndicator value={5} max={5} isComplete={true} />
      </Stack>
      <Typography variant="h2">Animated Progress Indicator</Typography>
      <Stack spacing={2}>
        <SteppedProgressIndicator animate={true} />
        <SteppedProgressIndicator
          value={1}
          max={2}
          animate={true}
          animationDuration={5}
        />
        <SteppedProgressIndicator
          value={2}
          max={3}
          animate={true}
          animationDuration={4}
        />
        <SteppedProgressIndicator
          value={3}
          max={3}
          animate={true}
          animationDuration={3}
        />
        <SteppedProgressIndicator
          value={3}
          max={5}
          animate={true}
          animationDuration={2}
        />
        <SteppedProgressIndicator
          value={3}
          max={7}
          animate={true}
          animationDuration={1}
          isComplete={true}
        />
      </Stack>
    </>
  );
}
