import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useGlobalStore } from "../../../../store";
import { logout } from "../../../../services/core-api-adapter";
import { defaultFullscreenPageStyling } from "../../../../theme";
import ProcessIndicatorIcon from "../../../Common/components/ProcessIndicatorIcon";
import CircledBackButton from "../../../Common/components/CircledBackButton";
import { getConfigurationVariable } from "../../../../services/configuration-adapter";
import { trackEvent } from "../../../../services/analytics-adapter";

export default function NoProductsLinkedToMemberError() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatch } = useGlobalStore();

  function logoutAndClearUser() {
    return logout().then(() => {
      dispatch({
        type: "CLEAR_CURRENT_USER",
      });
    });
  }

  function onGoBackButtonClick() {
    logoutAndClearUser().then(() => {
      navigate("/");
    });
  }

  function onGetSupportLinkClick() {
    trackEvent({
      event: "action.supportRequested",
      source: "no active products",
    });
  }

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        ...defaultFullscreenPageStyling,
      }}
    >
      <Box>
        <CircledBackButton
          showLabel={true}
          onClick={() => onGoBackButtonClick()}
        />
      </Box>

      <Stack spacing={2} justifyContent="flex-end">
        <Stack sx={{ opacity: 0.5, textAlign: "center", mb: 6 }}>
          <ProcessIndicatorIcon type="error" height={90} width={90} />
        </Stack>
        <Typography variant="h2">
          {t("NoProductsLinkedToMemberError.title")}
        </Typography>
        <Stack sx={{ pb: 3 }} spacing={3}>
          {(
            t<any, any, string[]>("NoProductsLinkedToMemberError.body", {
              returnObjects: true,
            }) as []
          ).map((text: string, index: number) => (
            <Typography
              variant="body1"
              color="neutral"
              key={String(text + index)}
            >
              {text}
            </Typography>
          ))}
        </Stack>
        <Button
          component="a"
          href={getConfigurationVariable("VITE_APP_UNU_HEALTH_SUPPORT_URI")}
          onClick={onGetSupportLinkClick}
          target="_blank"
          variant="contained"
          size="small"
        >
          {t("common.supportLink")}
        </Button>
      </Stack>
    </Stack>
  );
}
