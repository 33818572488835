import { Button, Typography } from "@mui/material";
import DefaultDialog from "../DefaultDialog";

interface UpdateDetailsModalProps {
  isOpen: boolean;
  title: string;
  content: string;
  buttonText: string;
  onClose: () => void;
  onSubmit: () => void;
}

export default function UpdateDetailsModal({
  isOpen,
  title,
  content,
  buttonText,
  onClose,
  onSubmit,
}: UpdateDetailsModalProps) {
  return (
    <DefaultDialog
      label={title}
      isOpen={isOpen}
      onClose={onClose}
      fullWidth={true}
    >
      <Typography variant="h3" fontWeight={600} align="center">
        {title}
      </Typography>
      <Typography align="center">{content}</Typography>
      <Button size="small" onClick={onSubmit}>
        {buttonText}
      </Button>
    </DefaultDialog>
  );
}
