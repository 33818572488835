import { Typography } from "@mui/material";
import { ThirdPartyMedicalAssistanceServicePrompt } from ".";
import { MemberProductPaymentRatesChargeCode } from "@/services/core-api-adapter";

export default function ThirdPartyMedicalAssistanceServicePromptExamples() {
  return (
    <>
      <Typography textAlign="center" variant="h3" color="info.main">
        COU_NURSEATION
      </Typography>
      <ThirdPartyMedicalAssistanceServicePrompt
        onBackButtonClick={() => console.log("onBackButtonClick")}
        onContinueButtonClick={() => console.log("onContinueButtonClick")}
        serviceType={{
          chargeCode: MemberProductPaymentRatesChargeCode.NURSE_CHAT,
          amount: "0UGX",
          amountFormatted: "0 UGX",
          ussdConfiguration: undefined,
        }}
      />
      <Typography textAlign="center" variant="h3" color="info.main">
        COU_DOCTOR
      </Typography>
      <ThirdPartyMedicalAssistanceServicePrompt
        onBackButtonClick={() => console.log("onBackButtonClick")}
        onContinueButtonClick={() => console.log("onContinueButtonClick")}
        serviceType={{
          chargeCode:
            MemberProductPaymentRatesChargeCode.REMOTE_DOCTOR_CONSULTATIONS,
          amount: "20,000 UGX",
          amountFormatted: "20,000 UGX",
          ussdConfiguration: {
            ussd: "*280#",
            menuOption: 1,
          },
        }}
      />
      <Typography textAlign="center" variant="h3" color="info.main">
        COU_LABORATORY
      </Typography>
      <ThirdPartyMedicalAssistanceServicePrompt
        onBackButtonClick={() => console.log("onBackButtonClick")}
        onContinueButtonClick={() => console.log("onContinueButtonClick")}
        serviceType={{
          chargeCode: MemberProductPaymentRatesChargeCode.LABORATORY,
          amount: "3,000 UGX",
          amountFormatted: "3,000 UGX",
          ussdConfiguration: {
            ussd: "*280*2#",
            menuOption: 1,
          },
        }}
      />
      <Typography textAlign="center" variant="h3" color="info.main">
        COU_PHARMACY
      </Typography>
      <ThirdPartyMedicalAssistanceServicePrompt
        onBackButtonClick={() => console.log("onBackButtonClick")}
        onContinueButtonClick={() => console.log("onContinueButtonClick")}
        serviceType={{
          chargeCode: MemberProductPaymentRatesChargeCode.PHARMACY,
          amount: "2,000 UGX",
          amountFormatted: "2,000 UGX",
          ussdConfiguration: {
            ussd: "*280*3#",
            menuOption: 1,
          },
        }}
      />
      <Typography textAlign="center" variant="h3" color="info.main">
        COU_CLINIC
      </Typography>
      <ThirdPartyMedicalAssistanceServicePrompt
        onBackButtonClick={() => console.log("onBackButtonClick")}
        onContinueButtonClick={() => console.log("onContinueButtonClick")}
        serviceType={{
          chargeCode: MemberProductPaymentRatesChargeCode.CLINIC,
          amount: "20,000 UGX",
          amountFormatted: "20,000 UGX",
          ussdConfiguration: {
            ussd: "*280*4#",
            menuOption: 1,
          },
        }}
      />
    </>
  );
}
