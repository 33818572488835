import { useEffect } from "react";
import MyHealth from "../../components/MyHealth";
import { trackPageViewV2 } from "@/services/analytics-adapter";

export default function MyHealthPage() {
  useEffect(() => {
    trackPageViewV2({
      pageName: "My health:Health profile",
      pageSubSection1: "My health",
      pageSubSection2: "My health:Health profile",
      pageCategory: "My health",
    });
  }, []);
  return <MyHealth />;
}
