import { useEffect } from "react";
import CommunicationPreferences from "../../components/CommunicationPreferences";
import { trackPageViewV2 } from "../../../../services/analytics-adapter";

export default function CommunicationPreferencesPage() {
  useEffect(() => {
    trackPageViewV2({
      pageName: "More:Communication preferences",
      pageSubSection1: "More",
      pageSubSection2: "More:Communication preferences",
      pageCategory: "More",
    });
  }, []);
  return <CommunicationPreferences />;
}
