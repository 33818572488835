import { useEffect } from "react";
import MessageDetails from "../../components/MessageDetails";
import { trackPageViewV2 } from "../../../../services/analytics-adapter";

export default function MessageDetailsPage() {
  useEffect(() => {
    trackPageViewV2({
      pageName: "Notifications:View notification",
      pageSubSection1: "Notifications",
      pageSubSection2: "Notifications:View notification",
      pageCategory: "Notifications",
    });
  }, []);
  return <MessageDetails />;
}
