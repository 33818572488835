import { Typography } from "@mui/material";
import * as AllIcons from "./icons";

export default function IconsExamples() {
  const iconColourNames = [
    "",
    "primary",
    "secondary",
    "inherit",
    "disabled",
    "action",
    "error",
    "info",
    "success",
    "warning",
  ];
  return (
    <>
      <Typography variant="h2">Icons</Typography>
      <table>
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #ccc",
                padding: "8px",
                textAlign: "right",
              }}
            >
              Icon Name
            </th>
            {iconColourNames.map(function (colourName: any) {
              return (
                <th
                  key={colourName}
                  style={{ border: "1px solid #ccc", padding: "8px" }}
                >
                  Color: {colourName ? `"${colourName}"` : "None"}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {Object.keys(AllIcons).map(function (iconName: string) {
            //@ts-expect-error:next-line
            const IconComponent = AllIcons[iconName];
            return (
              <tr key={iconName}>
                <td
                  style={{
                    border: "1px solid #ccc",
                    padding: "8px",
                    textAlign: "right",
                  }}
                >
                  {iconName}
                </td>
                {iconColourNames.map(function (colourName: any) {
                  return (
                    <td
                      key={iconName + "--" + colourName}
                      style={{ border: "1px solid #ccc", textAlign: "center" }}
                    >
                      <IconComponent color={colourName} />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
