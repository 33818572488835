import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import HealthRecordStateWrapper from "../HealthRecordStateWrapper";
import useGetMemberships from "../../../Common/hooks/useGetMemberships";
import { MedicalServiceProviderType } from "../../../../services/core-api-adapter";

export default function HealthRecordEmptyState() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { medicalAssistanceProvider } = useGetMemberships();

  const isHaveANurseCallMe =
    medicalAssistanceProvider === MedicalServiceProviderType.CALL_ME_BACK;

  function onChatWithANurseButtonClick() {
    navigate("/home/get-medical-assistance");
  }

  return (
    <HealthRecordStateWrapper>
      <Typography variant="h4" pb={2}>
        {t("HealthRecord.emptyState.title")}
      </Typography>
      <Typography textAlign={"center"} variant="body2" pb={2}>
        {t("HealthRecord.emptyState.body")}
      </Typography>
      <Button size="small" onClick={onChatWithANurseButtonClick}>
        {isHaveANurseCallMe
          ? t("common.haveANurseCallMe")
          : t("HealthRecord.emptyState.buttonLabel")}
      </Button>
    </HealthRecordStateWrapper>
  );
}
