import { Typography } from "@mui/material";
import ProcessIndicatorIcon from ".";

export default function ProcessIndicatorIconExamples() {
  return (
    <>
      <Typography variant="h2">Default variant with colours</Typography>
      <ProcessIndicatorIcon />
      <ProcessIndicatorIcon height={64} width={64} />
      <ProcessIndicatorIcon type="info" />
      <ProcessIndicatorIcon type="info" height={64} width={64} />
      <ProcessIndicatorIcon type="success" />
      <ProcessIndicatorIcon type="success" height={64} width={64} />
      <ProcessIndicatorIcon type="error" />
      <ProcessIndicatorIcon type="error" height={64} width={64} />

      <Typography variant="h2">Outline variant with colours</Typography>
      <ProcessIndicatorIcon variant="outline" />
      <ProcessIndicatorIcon variant="outline" height={64} width={64} />
      <ProcessIndicatorIcon variant="outline" type="info" />
      <ProcessIndicatorIcon
        variant="outline"
        type="info"
        height={64}
        width={64}
      />
      <ProcessIndicatorIcon variant="outline" type="success" />
      <ProcessIndicatorIcon
        variant="outline"
        type="success"
        height={64}
        width={64}
      />
      <ProcessIndicatorIcon variant="outline" type="error" />
      <ProcessIndicatorIcon
        variant="outline"
        type="error"
        height={64}
        width={64}
      />
    </>
  );
}
