import theme, { defaultFullscreenPageStyling } from "@/theme";
import { Stack, Typography } from "@mui/material";
import CircledBackButton from "../../../Common/components/CircledBackButton";
import { Trans, useTranslation } from "react-i18next";
import { convertPxToRem } from "@/utils";
import ItemPill from "../../../Common/components/ItemPill";
import IconLoader from "../../../Common/components/IconLoader";
import ButtonWithAnalytics from "../../../Common/components/ButtonWithAnalytics";
import { PathologyPanel } from "@/services/core-api-adapter";
import { PATHOLOGY_PANEL_PILL_MAP } from "@/features/Common/constants";

interface PathologyPanelsProps {
  panelList: PathologyPanel[];
  onPanelClick: (panel: PathologyPanel) => void;
}

export default function PathologyPanels({
  panelList,
  onPanelClick,
}: PathologyPanelsProps) {
  const { t } = useTranslation();

  return (
    <Stack spacing={3} sx={{ ...defaultFullscreenPageStyling }}>
      <CircledBackButton showLabel={true} />
      <h1>{t("PathologyPanel.page.title")}</h1>
      <Stack spacing={2}>
        {panelList &&
          panelList.map((panel, index) => (
            <ItemPill
              key={panel.panelName + index}
              onClick={() => {
                onPanelClick(panel);
              }}
              selectedItem={panel}
              itemMap={PATHOLOGY_PANEL_PILL_MAP}
              titleStyles={{ fontSize: convertPxToRem(16), fontWeight: 600 }}
              shouldShowPrice
            />
          ))}
        <Stack
          spacing={2}
          sx={{
            backgroundColor: theme.palette.primary[100],
            borderRadius: 2,
            padding: 2,
          }}
        >
          <Stack flexDirection="row" gap={1}>
            <IconLoader icon="ChatBubblePlusIcon" />
            <Stack>
              <Typography variant="h4">
                {t("PathologyPanel.page.doctorConsultCTA.title")}
              </Typography>
              <Typography variant="h4" fontWeight="400">
                <Trans>
                  {t("PathologyPanel.page.doctorConsultCTA.subtitle")}
                </Trans>
              </Typography>
            </Stack>
          </Stack>
          <ButtonWithAnalytics
            page="PathologyPanel"
            text={t("PathologyPanel.page.doctorConsultCTA.button")}
            intent="navigational"
            variant="outlined"
            color="neutral"
            onClick={() => console.log("onClick")}
          >
            {t("PathologyPanel.page.doctorConsultCTA.button")}
          </ButtonWithAnalytics>
        </Stack>
      </Stack>
    </Stack>
  );
}
