import { useTranslation } from "react-i18next";
import { Button, Stack, Typography } from "@mui/material";
import { defaultFullscreenPageStyling } from "../../../../theme";
import { useNavigate } from "react-router";
import FaceWithIcon from "../../components/FaceWithIcon";
import CircledBackButton from "../../components/CircledBackButton";

export default function MedicalAssistanceUnsupportedPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function goHomeButtonClick() {
    return navigate("/");
  }

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        ...defaultFullscreenPageStyling,
      }}
    >
      <CircledBackButton showLabel={true} />

      <Stack alignItems="center" spacing={2}>
        <FaceWithIcon
          color={"error.main"}
          height={60}
          width={60}
          Icon={"CrossIcon"}
        />
        <Typography textAlign="center" variant="h1">
          {t("MedicalAssistanceUnsupported.title")}
        </Typography>
        <Typography textAlign="center" variant="h2">
          {t("MedicalAssistanceUnsupported.subtitle")}
        </Typography>
        <Typography textAlign="center">
          {t("MedicalAssistanceUnsupported.content")}
        </Typography>
      </Stack>

      <Button fullWidth onClick={goHomeButtonClick}>
        {t("common.goHomeButton")}
      </Button>
    </Stack>
  );
}
