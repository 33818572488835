import { Stack } from "@mui/material";
import PayGMedicalAssistanceCTA from ".";

export default function PayGMedicalAssistanceCTAExamples() {
  return (
    <Stack spacing={2}>
      <PayGMedicalAssistanceCTA memberProfile={null} isLoading={false} />
    </Stack>
  );
}
