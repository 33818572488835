import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { trackEvent } from "../../../../services/analytics-adapter";
import HealthRecordCard from "../HealthRecordCard";

interface HealthRecordListProps {
  data: {
    events: any[];
  };
}

export default function HealthRecordList({ data }: HealthRecordListProps) {
  const { t } = useTranslation();

  function onAccordionOpen(healthRecordType: string) {
    trackEvent({
      event: "action.healthRecordItemExpanded",
      "healthRecord.type": healthRecordType,
    });
  }

  return (
    <>
      <Stack
        component="ul"
        spacing={2}
        aria-label={t("HealthRecordList.list.title")}
      >
        {data.events.map((healthProfileEvent: any, index) => {
          return (
            <Box
              component="li"
              key={healthProfileEvent.type + String(index)}
              sx={{ listStyleType: "none" }}
            >
              <HealthRecordCard
                data={healthProfileEvent}
                onAccordionOpen={onAccordionOpen}
              />
            </Box>
          );
        })}
      </Stack>
    </>
  );
}
