import DefaultAccordion from "@/features/Common/components/DefaultAccordion";
import IconLoader from "@/features/Common/components/IconLoader";
import {
  PathologyPanel,
  PathologyPanelChargeCode,
} from "@/services/core-api-adapter";
import { convertPxToRem } from "@/utils";
import {
  Stack,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

interface PanelTestInfoAccordionProps {
  panelData: PathologyPanel;
}

function PanelTestInfoFromAPI({ panelData }: { panelData: PathologyPanel }) {
  const { t } = useTranslation();
  return (
    <Stack component="ul" spacing={1}>
      {panelData?.panelPathologyTests.map((item: any, index: number) => (
        <>
          <ListItem
            key={index}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 1,
            }}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <IconLoader
                icon="CheckmarkIcon"
                color="primary"
                fontSize="small"
                sx={{ marginTop: convertPxToRem(4) }}
              ></IconLoader>
            </ListItemIcon>
            <Stack flexDirection="column" alignItems="flex-start" spacing={0.5}>
              <ListItemText
                color="neutral.main"
                primary={
                  <Typography
                    variant="body2"
                    sx={{ fontSize: convertPxToRem(14) }}
                  >
                    {t(
                      `PathologyPanelDetails.panelTests.${item?.testCode}.title` as any
                    )}
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: convertPxToRem(12),
                      color: "neutral.main",
                    }}
                  >
                    {t(
                      `PathologyPanelDetails.panelTests.${item?.testCode}.subtitle` as any
                    )}
                  </Typography>
                }
              />
            </Stack>
          </ListItem>
          {index < panelData.panelPathologyTests.length - 1 && <Divider />}
        </>
      ))}
    </Stack>
  );
}

export default function PanelTestInfoAccordion({
  panelData,
}: PanelTestInfoAccordionProps) {
  const { t, i18n } = useTranslation();

  const [shouldUseListFromAPI, setShouldUseListFromAPI] = useState<
    boolean | null
  >(false);

  function buildAccordionContent(panelChargeCode: string) {
    let useListFromAPI = null;

    switch (panelChargeCode) {
      case PathologyPanelChargeCode.MALE_OVER_45:
      case PathologyPanelChargeCode.MALE_UNDER_45:
      case PathologyPanelChargeCode.FEMALE:
      case PathologyPanelChargeCode.HIV_MONITORING:
      case PathologyPanelChargeCode.CDR4_VIRAL_LOAD:
        useListFromAPI = true;
        break;

      default:
        useListFromAPI = false;
        break;
    }
    return useListFromAPI;
  }

  useEffect(() => {
    if (panelData?.panelChargeCode) {
      setShouldUseListFromAPI(
        buildAccordionContent(panelData?.panelChargeCode)
      );
    }
  }, [panelData]);

  const testInfoItems = t<any, any, string[]>(
    `PathologyPanelDetails.${panelData?.panelChargeCode}.testInfo.body.items`,
    {
      returnObjects: true,
    }
  ) as [];

  return (
    <>
      <DefaultAccordion
        title={
          `PathologyPanelDetails.${panelData?.panelChargeCode}.testInfo.title` as any
        }
        icon={
          <IconLoader
            icon="HeartInCircleIcon"
            sx={{ color: "accent2.dark" }}
            fontSize="large"
          />
        }
      >
        <Stack spacing={2}>
          <Trans>
            <Typography variant="body1" sx={{ color: "neutral.main" }}>
              {t(
                `PathologyPanelDetails.${panelData?.panelChargeCode}.testInfo.body` as any
              )}
            </Typography>
          </Trans>

          {shouldUseListFromAPI === true && (
            <PanelTestInfoFromAPI panelData={panelData} />
          )}

          {i18n.exists(
            `PathologyPanelDetails.${panelData?.panelChargeCode}.testInfo.body.items`
          ) && (
            <Stack component="ul" spacing={1}>
              {testInfoItems.map((text: string, index: number) => (
                <>
                  <ListItem
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: 1,
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 0 }}>
                      <IconLoader
                        icon="CheckmarkIcon"
                        color="primary"
                        fontSize="small"
                        sx={{ marginTop: convertPxToRem(4) }}
                      ></IconLoader>
                    </ListItemIcon>
                    <Stack
                      flexDirection="column"
                      alignItems="flex-start"
                      spacing={0.5}
                    >
                      <ListItemText
                        color="neutral.main"
                        primary={
                          <Trans>
                            <Typography
                              variant="body1"
                              sx={{
                                color: "neutral.main",
                              }}
                            >
                              {text}
                            </Typography>
                          </Trans>
                        }
                      />
                    </Stack>
                  </ListItem>
                  {index < testInfoItems.length - 1 && <Divider />}
                </>
              ))}
            </Stack>
          )}

          {i18n.exists(
            `PathologyPanelDetails.${panelData?.panelChargeCode}.testInfo.body.footer`
          ) && (
            <Stack>
              <Typography variant="body1" sx={{ color: "neutral.main" }}>
                {t(
                  `PathologyPanelDetails.${panelData?.panelChargeCode}.testInfo.body.footer` as any
                )}
              </Typography>
            </Stack>
          )}
        </Stack>
      </DefaultAccordion>
    </>
  );
}
