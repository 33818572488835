import { Stack, Typography } from "@mui/material";
import CollectIdentificationNumber from ".";

export default function CollectIdentificationNumberExamples() {
  return (
    <Stack p={2} spacing={2}>
      <Typography variant="h1">Identification type</Typography>
      <Stack py={6} spacing={6}>
        <Typography variant="h2">Default is ZA ID number</Typography>
        <CollectIdentificationNumber
          value={{
            identificationType: "IdNumber",
            identificationValue: "",
          }}
          onSubmit={(
            identificationType: any,
            identificationTypeValue: string
          ) => {
            console.log(
              `identificationType: ${identificationType}, identificationTypeValue: ${identificationTypeValue}`
            );
          }}
        />
      </Stack>

      <Stack py={6} spacing={6}>
        <Typography variant="h2">
          Default is International Passport number
        </Typography>
        <CollectIdentificationNumber
          value={{
            identificationType: "PassportNumber",
            identificationValue: "",
          }}
          onSubmit={(
            identificationType: any,
            identificationTypeValue: string
          ) => {
            console.log(
              `identificationType: ${identificationType}, identificationTypeValue: ${identificationTypeValue}`
            );
          }}
        />
      </Stack>
    </Stack>
  );
}
