import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import theme, { shadows } from "../../../../theme";
import { clampNumber } from "../../../../utils";
import HealthScoreIndicator from "../HealthScoreIndicator";
import IconLoader from "../../../Common/components/IconLoader";
import { useEffect, useState } from "react";

type BaseColourOptions =
  | "primary"
  | "accent1"
  | "accent2"
  | "accent3"
  | "accent4"
  | "accent5";

export interface HealthNudgeProps {
  completionPercentage: number;
  healthScore?: number;
  callToActionButtonColour?: BaseColourOptions;
}

interface ConfigurationOption {
  range: number[];
  name: "incomplete" | "partiallyComplete" | "complete";
  baseColour: BaseColourOptions;
  callToActionButtonLabel: string;
  callToActionButtonURL: string;
}

export default function HealthNudge(props: HealthNudgeProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [healthScoreValue, setHealthScoreValue] = useState(0);

  useEffect(() => {
    if (props.completionPercentage) {
      setCompletionPercentage(clampNumber(props.completionPercentage, 0, 100));
    }
    if (props.healthScore) {
      setHealthScoreValue(Number(props.healthScore));
    }
  }, [props?.healthScore, props?.completionPercentage]);

  const completionPercentageForCircularProgressDisplay = clampNumber(
    completionPercentage,
    2,
    100
  );

  const configurationOptions: ConfigurationOption[] = [
    {
      range: [0, 1],
      name: "incomplete",
      baseColour: "accent3",
      callToActionButtonLabel: t("common.completeProfileButton"),
      callToActionButtonURL: "/binah-scan?skipScanPrompt=true",
    },
    {
      range: [1, 100],
      name: "partiallyComplete",
      baseColour: "accent1",
      callToActionButtonLabel: t("common.viewHealthProfileButton"),
      callToActionButtonURL: "/home/my-health/health-profile",
    },
    {
      range: [100, 999],
      name: "complete",
      baseColour: "accent1",
      callToActionButtonLabel: t("common.viewHealthProfileButton"),
      callToActionButtonURL: "/home/my-health/health-profile",
    },
  ];

  function getConfigurationBasedOnCurrentCompletionPercentage() {
    const internalConfiguration = configurationOptions.find(
      (configurationOption) => {
        return (
          completionPercentage >= configurationOption.range[0] &&
          completionPercentage < configurationOption.range[1]
        );
      }
    );
    return internalConfiguration
      ? internalConfiguration
      : configurationOptions[configurationOptions.length - 1];
  }

  const configuration = getConfigurationBasedOnCurrentCompletionPercentage();

  function onCallToActionClick() {
    navigate(configuration.callToActionButtonURL);
  }

  function callToActionStyling() {
    return props.callToActionButtonColour === "primary"
      ? {
          p: 2,
          pt: 0,
        }
      : {
          background: theme.palette.background.paper,
          borderTop: `1px solid ${theme.palette.neutral["100"]}`,
        };
  }

  return (
    <Stack
      borderRadius={2.5}
      overflow="hidden"
      spacing={2}
      sx={{
        background: theme.palette.background.paper,
        boxShadow: shadows.light,
      }}
    >
      <Box
        sx={{ height: theme.spacing(2), position: "relative", width: "100%" }}
      >
        <IconLoader
          icon="PatternSemiCircles"
          preserveAspectRatio="none"
          color={configuration.baseColour}
          sx={{
            height: "100%",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%",
          }}
        />
      </Box>

      <Stack spacing={2} pl={2} pr={2}>
        <Stack direction="row" justifyContent={"space-between"}>
          <Stack position="relative">
            <CircularProgress
              value={completionPercentageForCircularProgressDisplay}
              color={configuration.baseColour}
              variant="determinate"
              thickness={2}
              size={58}
              sx={{
                strokeLinecap: "round",
              }}
              aria-label="Health profile progress bar"
            />
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                borderRadius: "50%",
                position: "absolute",
                background: theme.palette[configuration.baseColour].light,
                width: 50,
                height: 50,
                top: (theme) => theme.spacing(0.5),
                left: (theme) => theme.spacing(0.5),
              }}
            >
              <IconLoader
                icon="PillBottleIcon"
                color={configuration.baseColour}
              />
            </Stack>
          </Stack>

          <Typography variant="body2" color={theme.palette.neutral[500]}>
            {t("HealthNudge.healthProfileText")}
          </Typography>
        </Stack>

        {configuration.name === "complete" ? (
          <HealthScoreIndicator healthScore={healthScoreValue} />
        ) : (
          <>
            <Typography variant="h4">
              {t(`HealthNudge.${configuration.name}.title`, {
                percentage: `${completionPercentage}`,
              })}
            </Typography>

            <Typography variant="body2" color="neutral">
              {t(`HealthNudge.${configuration.name}.body`)}
            </Typography>
          </>
        )}
      </Stack>
      <Box sx={{ ...callToActionStyling() }}>
        <Button
          color={
            props.callToActionButtonColour === "primary"
              ? "primary"
              : props.callToActionButtonColour
          }
          variant={
            props.callToActionButtonColour === "primary" ? undefined : "text"
          }
          endIcon={<IconLoader icon="ChevronNextIcon" sx={{ scale: "2" }} />}
          onClick={onCallToActionClick}
          fullWidth
          style={{ justifyContent: "space-between" }}
        >
          {configuration.callToActionButtonLabel}
        </Button>
      </Box>
    </Stack>
  );
}
