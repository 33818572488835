import React, { useEffect, useState } from "react";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MedicalConditionInput from "../MedicalConditionInput";
import IconLoader from "../IconLoader";

const MAX_ALLOWED_MEDICINE_LIST = 5;

interface Props {
  onChange?: Function;
  value?: Medicine[];
}

export interface Medicine {
  name: string;
  image?: string;
}

function MedicineList({ onChange, value }: Props) {
  const { t } = useTranslation();
  const [medicineList, setMedicineList] = useState<Medicine[]>(value || []);
  const [addMedicineIsVisible, setAddMedicineIsVisible] = useState(false);
  const listLabel = t("MedicineList.listLabel");

  function onAdd(value: string) {
    setMedicineList((list) => {
      return [...list, { name: value, imageUri: "" }];
    });
  }

  function onRemove(index: number) {
    setMedicineList(medicineList.filter((_, i) => i !== index));
  }

  useEffect(() => {
    setMedicineList(value || []);
  }, [value]);

  useEffect(() => {
    onChange && onChange(medicineList);
  }, [medicineList]);

  return (
    <Stack spacing={1}>
      <Stack role="list" flexGrow={2} spacing={1} aria-label={listLabel}>
        {medicineList.map((medicine, index) => (
          <Stack
            key={String(medicine.name + index)}
            role="listitem"
            aria-label={medicine.name}
            position="relative"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
            sx={{
              backgroundColor: (theme) => theme.palette.neutral[100],
              borderRadius: 4,
              minHeight: 90,
              pl: 2,
            }}
          >
            <Stack direction="row" alignItems="flex-start" py={2}>
              <Stack
                bgcolor="accent1.main"
                color="accent1.contrastText"
                sx={{
                  borderRadius: 2,
                  p: 2,
                  height: "100%",
                }}
              >
                <IconLoader
                  icon="PillBottleIcon"
                  sx={{
                    color: "inherit",
                    margin: "auto",
                  }}
                />
              </Stack>
              <Stack justifyContent="space-around" sx={{ pl: 2 }}>
                <Typography variant="subtitle1">{listLabel}</Typography>
                <Typography variant="h4" sx={{ wordBreak: "break-word" }}>
                  {medicine.name}
                </Typography>
              </Stack>
            </Stack>

            <IconButton
              aria-label={t("MedicineList.deleteButton")}
              onClick={() => onRemove(index)}
              sx={{ p: 2 }}
            >
              <IconLoader icon="BinIcon" />
            </IconButton>
          </Stack>
        ))}
      </Stack>

      {medicineList.length < MAX_ALLOWED_MEDICINE_LIST && (
        <>
          {addMedicineIsVisible ? (
            <MedicalConditionInput
              type={`MedicineList`}
              onChange={onAdd}
              hideInput={setAddMedicineIsVisible}
            />
          ) : (
            <Button
              variant="text"
              fullWidth
              size="small"
              onClick={() => setAddMedicineIsVisible(true)}
            >
              + {t("MedicineList.addButton")}
            </Button>
          )}
        </>
      )}
    </Stack>
  );
}

const MemoMedicineList = React.memo(
  MedicineList,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.value) === JSON.stringify(nextProps.value)
);

export default MemoMedicineList;
