import { useEffect, useState } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import FullscreenLoadingIndicator from ".";

export default function FullscreenLoadingIndicatorExamples() {
  const [durationToShow, setDurationToShow] = useState(1000);
  const [isLoading, setIsLoading] = useState(false);
  const [mainIconType, setMainIconType] = useState<any>("");

  const onUpdateSettings = () => {
    setIsLoading(true);
  };

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, durationToShow);
      return () => clearTimeout(timer);
    }
  }, [isLoading, durationToShow]);

  return (
    <>
      <Typography variant="h2">Fullscreen Loading Indicator</Typography>
      <form onSubmit={onUpdateSettings}>
        <Stack>
          <TextField
            value={durationToShow}
            onChange={(e) => setDurationToShow(Number(e.target.value))}
          />
          <TextField
            value={mainIconType}
            onChange={(e) => setMainIconType(e.target.value)}
          />
          <Button type="submit">Update Settings</Button>
        </Stack>
      </form>
      <FullscreenLoadingIndicator
        isOpen={isLoading}
        mainIconType={mainIconType}
      />
    </>
  );
}
