import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import HealthRecordStateWrapper from "../HealthRecordStateWrapper";

export default function HealthRecordPlaceholder() {
  const { t } = useTranslation();

  return (
    <HealthRecordStateWrapper>
      <Typography variant="h4" pb={2}>
        {t("HealthRecord.placeholder.title")}
      </Typography>
      {(
        t<any, any, string[]>("HealthRecord.placeholder.body", {
          returnObjects: true,
        }) as []
      ).map((text: string, index: number) => (
        <Typography variant="body2" pb={2} key={String(text + index)}>
          {text}
        </Typography>
      ))}
    </HealthRecordStateWrapper>
  );
}
