import { Divider, Stack, Typography } from "@mui/material";
import AuthGetMemberError from ".";

export default function AuthGetMemberErrorExamples() {
  return (
    <>
      <Stack>
        <Typography>Phone number only</Typography>
        <AuthGetMemberError
          phoneNumber={{
            countryCode: "ZA",
            globalSubscriberNumber: "0823110000",
          }}
          tryAgain={() => console.log("Try again")}
        />
      </Stack>
      <Divider />
      <Stack>
        <Typography>ID values</Typography>
        <AuthGetMemberError
          UNUID="UNU-1234"
          identificationNumber={{
            identificationType: "IdNumber",
            identificationValue: "1234567890123",
          }}
          authenticationType="IdNumber"
          phoneNumber={{
            countryCode: "ZA",
            globalSubscriberNumber: "0823110000",
          }}
          tryAgain={() => console.log("Try again")}
        />
      </Stack>
    </>
  );
}
