import CollectMarketingCommunicationPreferences from ".";

export default function CollectMarketingCommunicationPreferencesExamples() {
  function onSubmit() {
    console.log("onSubmit");
  }

  return (
    <>
      <CollectMarketingCommunicationPreferences
        phoneNumber="0123456789"
        onSubmit={onSubmit}
      />
    </>
  );
}
