import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import MembershipTermsPanel from "../../components/MembershipTermsPanel";
import MembershipPanelWrapper from "../../components/MembershipPanelWrapper";
import { useEffect } from "react";
import { trackPageViewV2 } from "../../../../services/analytics-adapter";

export default function MembershipTermsPage() {
  const { t } = useTranslation();
  const { membershipIdentifier } = useParams();

  useEffect(() => {
    trackPageViewV2({
      pageName: "Membership:Manage membership",
      pageSubSection1: "Membership",
      pageSubSection2: "Membership:Manage membership",
      pageCategory: "Membership",
    });
  }, [membershipIdentifier]);

  return (
    <MembershipPanelWrapper
      goBackButtonText={t("MembershipTermsPage.goBackButton")}
      goBackButtonVisibleText={t(
        "MembershipTermsPage.goBackButtonVisibleLabel"
      )}
    >
      <MembershipTermsPanel membershipIdentifier={membershipIdentifier} />
    </MembershipPanelWrapper>
  );
}
