import { Stack } from "@mui/material";
import MemberConsultationCard from ".";
import {
  MemberProductPaymentRatesChargeCode,
  MemberConsultationOutcome,
  MemberConsultationStatus,
} from "../../../../services/core-api-adapter";
import React from "react";

export default function MemberConsultationCardExamples() {
  const consultations = [
    {
      memberId: "ABC124",
      consultationId: "AC12",
      mainMemberId: "AC12",
      isDependant: false,
      chargeAmountFormatted: null,
      chargeCode: MemberProductPaymentRatesChargeCode.DIGITAL_DOCTOR_CONSULT,
      redirectUrl: "",
      outcome: MemberConsultationOutcome.TRIAGE_STARTED,
      consultationTypeName: "Intercare",
      serviceProviderName: "Intercare",
      startedOn: "",
      status: MemberConsultationStatus.ARCHIVED,
    },
    {
      memberId: "ABC124",
      consultationId: "AC12",
      mainMemberId: "AC12",
      isDependant: false,
      chargeAmountFormatted: null,
      chargeCode: MemberProductPaymentRatesChargeCode.CHAT_TO_A_NURSE,
      redirectUrl: "",
      outcome: MemberConsultationOutcome.SESSION_STARTED,
      consultationTypeName: "Intercare",
      serviceProviderName: "Intercare",
      startedOn: "",
      status: MemberConsultationStatus.ARCHIVED,
    },
    {
      memberId: "ABC124",
      consultationId: "AC12",
      mainMemberId: "AC12",
      isDependant: false,
      chargeAmountFormatted: null,
      chargeCode: MemberProductPaymentRatesChargeCode.IN_PERSON_DOCTOR_CONSULT,
      redirectUrl: "",
      outcome: MemberConsultationOutcome.PAYMENT_REQUESTED,
      consultationTypeName: "Intercare",
      serviceProviderName: "Intercare",
      startedOn: "",
      status: MemberConsultationStatus.ARCHIVED,
    },
    {
      memberId: "ABC124",
      consultationId: "AC12",
      mainMemberId: "AC12",
      isDependant: false,
      chargeAmountFormatted: null,
      chargeCode:
        MemberProductPaymentRatesChargeCode.ONLINE_DOCTOR_CONSULTATION,
      redirectUrl: "",
      outcome: MemberConsultationOutcome.PAYMENT_SUCCEEDED,
      consultationTypeName: "Intercare",
      serviceProviderName: "Intercare",
      startedOn: "",
      status: MemberConsultationStatus.ARCHIVED,
    },
    {
      memberId: "ABC124",
      consultationId: "AC12",
      mainMemberId: "AC12",
      isDependant: false,
      chargeAmountFormatted: null,
      chargeCode: null,
      redirectUrl: "",
      outcome: MemberConsultationOutcome.DIGITAL_CONSULT_STARTED,
      consultationTypeName: "Intercare",
      serviceProviderName: "Intercare",
      startedOn: "",
      status: MemberConsultationStatus.ARCHIVED,
    },
    {
      memberId: "ABC124",
      consultationId: "AC12",
      mainMemberId: "AC12",
      isDependant: false,
      chargeAmountFormatted: null,
      chargeCode: null,
      redirectUrl: "",
      outcome: MemberConsultationOutcome.NO_REFUND,
      consultationTypeName: "Intercare",
      serviceProviderName: "Intercare",
      startedOn: "",
      status: MemberConsultationStatus.ARCHIVED,
    },
    {
      memberId: "ABC124",
      consultationId: "AC12",
      mainMemberId: "AC12",
      isDependant: false,
      chargeAmountFormatted: null,
      chargeCode: null,
      redirectUrl: "",
      outcome: MemberConsultationOutcome.TRIAGE_ENDED,
      consultationTypeName: "Intercare",
      serviceProviderName: "Intercare",
      startedOn: "",
      status: MemberConsultationStatus.ARCHIVED,
    },
    {
      memberId: "ABC124",
      consultationId: "AC12",
      mainMemberId: "AC12",
      isDependant: false,
      chargeAmountFormatted: null,
      chargeCode: null,
      redirectUrl: "",
      outcome: MemberConsultationOutcome.PAYMENT_FAILED,
      consultationTypeName: "Intercare",
      serviceProviderName: "Intercare",
      startedOn: "",
      status: MemberConsultationStatus.ARCHIVED,
    },
    {
      memberId: "ABC124",
      consultationId: "AC12",
      mainMemberId: "AC12",
      isDependant: false,
      chargeAmountFormatted: null,
      chargeCode: null,
      redirectUrl: "",
      outcome: MemberConsultationOutcome.DIGITAL_CONSULT_ENDED,
      consultationTypeName: "Intercare",
      serviceProviderName: "Intercare",
      startedOn: "",
      status: MemberConsultationStatus.ARCHIVED,
    },
    {
      memberId: "ABC124",
      consultationId: "AC12",
      mainMemberId: "AC12",
      isDependant: false,
      chargeAmountFormatted: null,
      chargeCode: null,
      redirectUrl: "",
      outcome: MemberConsultationOutcome.INPERSON_CONSULT,
      consultationTypeName: "Intercare",
      serviceProviderName: "Intercare",
      startedOn: "",
      status: MemberConsultationStatus.ARCHIVED,
    },
    {
      memberId: "ABC124",
      consultationId: "AC12",
      mainMemberId: "AC12",
      isDependant: false,
      chargeAmountFormatted: null,
      chargeCode: null,
      redirectUrl: "",
      outcome: MemberConsultationOutcome.EMERGENCY_CONSULT,
      consultationTypeName: "Intercare",
      serviceProviderName: "Intercare",
      startedOn: "",
      status: MemberConsultationStatus.ARCHIVED,
    },
    {
      memberId: "ABC124",
      consultationId: "AC12",
      mainMemberId: "AC12",
      isDependant: false,
      chargeAmountFormatted: null,
      chargeCode: null,
      redirectUrl: "",
      outcome: MemberConsultationOutcome.REFUND_REQUESTED,
      consultationTypeName: "Intercare",
      serviceProviderName: "Intercare",
      startedOn: "",
      status: MemberConsultationStatus.ARCHIVED,
    },
  ];

  return (
    <Stack spacing={2}>
      {consultations.map((consultation) => (
        <React.Fragment key={consultation.consultationId}>
          <Stack>
            {consultation.outcome}
            <MemberConsultationCard
              memberName="Lesedi"
              consultation={consultation}
              shouldDisplayStatus={true}
              onConsultationClick={() => console.log("Consultation selected")}
            />
          </Stack>
        </React.Fragment>
      ))}
    </Stack>
  );
}
