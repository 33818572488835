import { useEffect } from "react";
import { trackPageViewV2 } from "../../../../services/analytics-adapter";
import AboutApp from "../../components/AboutApp";

export default function AboutAppPage() {
  useEffect(() => {
    trackPageViewV2({
      pageName: "More:About app",
      pageSubSection1: "More",
      pageSubSection2: "More:About app",
      pageCategory: "More",
    });
  }, []);
  return <AboutApp />;
}
