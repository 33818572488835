import { Stack } from "@mui/material";
import AddDependentsThankYou from ".";

export default function AddDependentsThankYouExamples() {
  return (
    <Stack sx={{ height: "100vh" }}>
      <AddDependentsThankYou onDone={() => console.log("onDone")} />
    </Stack>
  );
}
