import CollectThirdPartyUserDetails from ".";

export default function CollectThirdPartyUserDetailsExamples() {
  return (
    <CollectThirdPartyUserDetails
      onSubmit={(data) => console.log("On submit", data)}
      onLoginClick={() => console.log("onLoginClick")}
    />
  );
}
