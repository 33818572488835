import { Stack } from "@mui/material";
import DependentsOverview from ".";
import { IdentityDocumentType } from "../../../../services/core-api-adapter";

export default function DependentsOverviewExamples() {
  return (
    <Stack sx={{ height: "100vh" }}>
      <DependentsOverview
        onDone={() => {}}
        onRemoveDependent={() => {}}
        onEditDependent={() => {}}
        onAddMoreDependentButtonClick={() => {}}
        dependentsList={[
          {
            firstName: "Nuki",
            lastName: "Persona",
            identityDocumentType: IdentityDocumentType.IdNumber,
            identityDocumentValue: "9603124800089",
            sexAtBirth: "female",
            dateOfBirth: "1996-03-12",
            identityDocumentCountryOfIssue: "ZAF",
          },
          {
            firstName: "Nuki",
            lastName: "Test",
            identityDocumentType: null,
            identityDocumentValue: null,
            sexAtBirth: "female",
            dateOfBirth: "1996-03-12",
            identityDocumentCountryOfIssue: "ZAF",
          },
        ]}
        remainingDependentsCount={2}
        maxDependentAge={18}
        mainMember={
          {
            isPrimaryCard: true,
            membershipDetails: {
              id: "5a9e9e18-9142-44a9-8d85-0dc21759c5f0",
              type: "MAIN_MEMBER",
              number: "UNU-725",
              status: "ACTIVE",
              beneficiaryCode: "00",
            },
            memberDetails: {
              id: "7f37be7c-31dc-448b-a6b6-a9b64f1b01f2",
              memberFullName: "Lesedi Persona",
              identityDocumentType: "ID_NUMBER",
              identityDocumentValue: "9603124800089",
              dateOfBirth: "1996-03-12",
            },
          } as any
        }
      />
    </Stack>
  );
}
