import { useParams } from "react-router";
import EditHealthMeasurement from "../../../components/EditHealthMeasurement";
import { useEffect } from "react";
import { trackPageViewV2 } from "../../../../../services/analytics-adapter";

export default function HealthMeasurementPage() {
  const { healthMeasurementIdentifier } = useParams();

  const returnPath = "/home/my-health/health-profile";

  useEffect(() => {
    trackPageViewV2({
      pageName: `My health:Edit ${healthMeasurementIdentifier} health measurement`,
      pageSubSection1: "My health",
      pageSubSection2:
        "My health:Edit ${healthMeasurementIdentifier} health measurement",
      pageCategory: "My health",
    });
  }, [healthMeasurementIdentifier]);

  return (
    <EditHealthMeasurement
      healthMeasurementIdentifier={healthMeasurementIdentifier}
      returnPath={returnPath}
    />
  );
}
