import IdentificationNumberToggle from ".";

export default function IdentificationNumberToggleExamples() {
  return (
    <IdentificationNumberToggle
      existingValue={{
        identificationType: "",
        identificationValue: "",
      }}
      showInputLabels={true}
      onIdentificationToggleChange={(type, value) => {
        console.log("identificationType", type);
        console.log("identificationValue", value);
      }}
    />
  );
}
