import { Box, Stack } from "@mui/material";
import WatermarkIcon from "../WatermarkIcon";
import IconLoader from "../IconLoader";

const FACE_WITH_ICON_DEFAULT_SIZE = 24;

interface FaceWithIconProps {
  color: string | undefined;
  Icon: string;
  height?: number;
  width?: number;
  extra?: Object;
}

const backgroundIconStyling = {
  zIndex: 2,
  position: "absolute",
  left: "-20%",
  top: "-20%",
  width: "130%",
  height: "130%",
};

export default function FaceWithIcon({
  color,
  height = FACE_WITH_ICON_DEFAULT_SIZE,
  width = FACE_WITH_ICON_DEFAULT_SIZE,
  Icon,
  extra,
}: FaceWithIconProps) {
  const styling = { ...extra };

  return (
    <Stack direction={"row"}>
      <Box
        sx={{
          position: "relative",
          height: { height },
          ...styling,
        }}
      >
        <IconLoader
          icon="HandDrawnFaceIcon"
          sx={{
            zIndex: -1,
            height: { height },
            width: { width },
            color: { color },
          }}
        />

        <Box
          sx={{
            left: "80%",
            position: "absolute",
            bottom: "0",
          }}
        >
          <IconLoader
            icon="HandDrawnCircleIcon"
            color="info"
            sx={{
              ...backgroundIconStyling,
            }}
          />
          <WatermarkIcon
            color={color}
            width={(50 / 100) * width}
            height={(50 / 100) * height}
            foreground="info"
            Icon={Icon}
            iconSize="medium"
            extra={{ zIndex: 3 }}
          />
        </Box>
      </Box>
    </Stack>
  );
}
