import { Grid2, Typography } from "@mui/material";
import StepsCounter from ".";

export default function StepsCounterExamples() {
  return (
    <Grid2>
      <Typography variant="h2">StepsCounter</Typography>
      <StepsCounter stepCounter={1} />
      <StepsCounter stepCounter={2} />
      <StepsCounter stepCounter={3} />
      <StepsCounter stepCounter={4} />
      <Typography variant="h2">StepsCounter disabled</Typography>
      <StepsCounter disabled stepCounter={1} />
      <StepsCounter disabled stepCounter={2} />
      <StepsCounter disabled stepCounter={3} />
      <StepsCounter disabled stepCounter={4} />
    </Grid2>
  );
}
