import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import OptionToggle from "../OptionToggle";
import { trackUserInteraction } from "../../../../services/analytics-adapter";

interface Props {
  onChange: (data: any) => void;
  data?: string | string[];
}

export default function QuestionTypeBloodType(props: Props) {
  const { t } = useTranslation();

  const options = [
    {
      name: "AB+",
      value: "AB+",
    },
    {
      name: "AB-",
      value: "AB-",
    },
    {
      name: "B+",
      value: "B+",
    },
    {
      name: "B-",
      value: "B-",
    },
    {
      name: "A+",
      value: "A+",
    },
    {
      name: "A-",
      value: "A-",
    },
    {
      name: "O+",
      value: "O+",
    },
    {
      name: "O-",
      value: "O-",
    },
    {
      name: t("common.iDontKnow"),
      value: "UNKNOWN",
    },
  ];

  function onChange(value: string[]) {
    props.onChange({
      data: {
        value: value[0] ? value[0] : null,
      },
      isValid: !!value[0],
    });
    if (value[0]) {
      trackUserInteraction({
        linkText: `Edit Blood type | ${value[0]}`,
        linkIntent: "confirmational",
        linkScope: "button",
      });
    }
  }

  return (
    <Stack spacing={2} justifyContent="space-between">
      <Typography variant="h2">{t("QuestionTypeBloodType.title")}</Typography>
      <OptionToggle onChange={onChange} options={options} value={props?.data} />
    </Stack>
  );
}
