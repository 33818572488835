import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { pageWithBottomNavigationStyling, shadows } from ".";
import BottomNavigation from "../features/Common/components/BottomNavigation";
import DefaultAccordion from "../features/Common/components/DefaultAccordion";
import MembershipCardExamples from "../features/Common/components/MembershipCard/index.examples";

const shadowCount = 25;

function ExampleComponentWithShadow(
  shadowName: string,
  shadowValue: number | string
) {
  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      p={2}
      borderRadius={2}
      bgcolor="background.paper"
      sx={{
        boxShadow: shadowValue,
      }}
    >
      {shadowName}
    </Box>
  );
}

export default function ShadowsExamples() {
  return (
    <Stack p={2} spacing={2} sx={{ ...pageWithBottomNavigationStyling }}>
      <BottomNavigation />

      <Stack spacing={2}>
        <Typography variant="h2" textAlign="center">
          Named shadow levels
        </Typography>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              {ExampleComponentWithShadow("none", shadows.none)}
            </Grid>
            <Grid item xs={4}>
              {ExampleComponentWithShadow("light", shadows.light)}
            </Grid>
            <Grid item xs={4}>
              {ExampleComponentWithShadow("medium", shadows.medium)}
            </Grid>
            <Grid item xs={4}>
              {ExampleComponentWithShadow("dark", shadows.dark)}
            </Grid>
            <Grid item xs={4}>
              {ExampleComponentWithShadow("heavy", shadows.heavy)}
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Divider sx={{ py: 2 }} />

      <Stack spacing={2}>
        <Typography variant="h2" textAlign="center">
          Default shadow levels
        </Typography>
        <Box>
          <Grid container spacing={4}>
            {Array(shadowCount)
              .fill(true)
              .map((shadow: boolean, index: number) => {
                const key = "shadow_" + String(index);
                return (
                  <Grid item key={key} xs={4}>
                    {ExampleComponentWithShadow(String(index), index)}
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Stack>

      <Divider sx={{ py: 2 }} />

      <Stack spacing={2}>
        <Typography variant="h2" textAlign="center">
          Accordion
        </Typography>
        <DefaultAccordion title="See what info we would share">
          <Stack>
            <Typography variant="h3">Sample info</Typography>
            <Typography variant="h4">Info we collect</Typography>
            <Typography variant="body1">
              The info you share with us is captured on your phone every week.
            </Typography>
          </Stack>
        </DefaultAccordion>
      </Stack>

      <Divider sx={{ py: 2 }} />

      <Stack spacing={2}>
        <Typography variant="h2" textAlign="center">
          Membership Card
        </Typography>
        <MembershipCardExamples />
      </Stack>

      <Divider sx={{ py: 2 }} />

      <Stack spacing={2}>
        <Typography variant="h2" textAlign="center">
          Bottom Navigation
        </Typography>
      </Stack>
    </Stack>
  );
}
