import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProductAdministratorSupportedServiceChannelAvailability } from "../../../../services/core-api-adapter";
import ServiceAvailabilityList from ".";

export default function ServiceAvailabilityTableExamples() {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Typography
          sx={{ color: (theme) => theme.palette.grey[500] }}
          textAlign="center"
          variant="body2"
        >
          {t("ServiceAvailabilityList.title")}
        </Typography>
        <ServiceAvailabilityList
          data={
            [
              {
                day: "MONDAY",
                operatingHours: "08:00 – 18:00",
              },
              {
                day: "TUESDAY",
                operatingHours: "08:00 – 18:00",
              },
              {
                day: "WEDNESDAY",
                operatingHours: "08:00 – 18:00",
              },
              {
                day: "THURSDAY",
                operatingHours: "08:00 – 18:00",
              },
              {
                day: "FRIDAY",
                operatingHours: "08:00 – 18:00",
              },
              {
                day: "SATURDAY",
                operatingHours: "08:00 – 14:00",
              },
              {
                day: "SUNDAY",
                operatingHours: null,
              },
              {
                day: "HOLIDAY",
                operatingHours: null,
              },
            ] as ProductAdministratorSupportedServiceChannelAvailability[]
          }
          delimiter="to"
        />
      </Stack>
      <Stack spacing={1}>
        <Typography
          sx={{ color: (theme) => theme.palette.grey[500] }}
          textAlign="center"
          variant="body2"
        >
          {t("ServiceAvailabilityList.title")}
        </Typography>
        <ServiceAvailabilityList
          data={
            [
              {
                day: "MONDAY",
                operatingHours: "08:00 - 17:00",
              },
              {
                day: "THURSDAY",
                operatingHours: "08:00 - 17:00",
              },
              {
                day: "FRIDAY",
                operatingHours: "08:00 - 17:00",
              },
              {
                day: "SATURDAY",
                operatingHours: "",
              },
              {
                day: "SUNDAY",
                operatingHours: null,
              },
              {
                day: "HOLIDAY",
                operatingHours: "08:00 - 17:00",
              },
            ] as ProductAdministratorSupportedServiceChannelAvailability[]
          }
        />
      </Stack>
      <Stack spacing={1}>
        <Typography
          sx={{ color: (theme) => theme.palette.grey[500] }}
          textAlign="center"
          variant="body2"
        >
          {t("ServiceAvailabilityList.title")}
        </Typography>
        <ServiceAvailabilityList
          data={
            [
              {
                day: "MONDAY",
                operatingHours: "08:00 - 17:00",
              },
              {
                day: "THURSDAY",
                operatingHours: "08:00 - 17:00",
              },
              {
                day: "FRIDAY",
                operatingHours: "08:00 - 17:00",
              },
              {
                day: "SATURDAY",
                operatingHours: "",
              },
              {
                day: "SUNDAY",
                operatingHours: null,
              },
              {
                day: "HOLIDAY",
                operatingHours: "08:00 - 17:00",
              },
            ] as ProductAdministratorSupportedServiceChannelAvailability[]
          }
          delimiter="until"
        />
      </Stack>
      <Stack spacing={1}>
        <Typography
          sx={{ color: (theme) => theme.palette.grey[500] }}
          textAlign="center"
          variant="body2"
        >
          {t("ServiceAvailabilityList.title")}
        </Typography>
        <ServiceAvailabilityList
          data={
            [
              {
                day: "MONDAY",
                operatingHours: "08:00 - 17:00",
              },
              {
                day: "SUNDAY",
                operatingHours: null,
              },
              {
                day: "HOLIDAY",
                operatingHours: null,
              },
            ] as ProductAdministratorSupportedServiceChannelAvailability[]
          }
        />
      </Stack>

      <Stack spacing={1}>
        <Typography
          sx={{ color: (theme) => theme.palette.grey[500] }}
          textAlign="left"
          variant="body2"
        >
          {t("ServiceAvailabilityList.title")}
        </Typography>
        <ServiceAvailabilityList
          data={
            [
              {
                day: "MONDAY",
                operatingHours: "08:00 - 17:00",
              },
              {
                day: "FRIDAY",
                operatingHours: "08:00 - 17:00",
              },
              {
                day: "THURSDAY",
                operatingHours: "10:30 to 17:20",
              },
              {
                day: "SATURDAY",
                operatingHours: "08:00 to 14:00, 15:30 - 18:30",
              },
              {
                day: "SUNDAY",
                operatingHours: null,
              },
              {
                day: "HOLIDAY",
                operatingHours: undefined,
              },
            ] as ProductAdministratorSupportedServiceChannelAvailability[]
          }
        />
      </Stack>

      <Stack spacing={1}>
        <Typography
          sx={{ color: (theme) => theme.palette.grey[500] }}
          textAlign="left"
          variant="body2"
        >
          {t("ServiceAvailabilityList.title")}
        </Typography>
        <ServiceAvailabilityList
          data={
            [
              {
                day: "MONDAY",
                operatingHours: "8-11am, 13:00 - 17:00",
              },
              {
                day: "TUESDAY",
                operatingHours: "8-9am",
              },
              {
                day: "FRIDAY",
                operatingHours: "10:30 to 17:20",
              },
              {
                day: "SATURDAY",
                operatingHours: undefined,
              },
              {
                day: "SUNDAY",
                operatingHours: null,
              },
              {
                day: "HOLIDAY",
                operatingHours: undefined,
              },
            ] as ProductAdministratorSupportedServiceChannelAvailability[]
          }
        />
      </Stack>
    </Stack>
  );
}
