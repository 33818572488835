import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Stack, Typography } from "@mui/material";
import useGetMemberships from "../../../Common/hooks/useGetMemberships";
import useGetProductType from "../../../Common/hooks/useGetProductType";
import {
  findMembershipByMemberIdentifier,
  findMembersOtherThanTheCurrentlyAuthenticatedMemberInMembership,
  getMembershipCardConfigFromMembership,
  getMembershipConfig,
  Member,
  Membership,
  MembershipChangeRequest,
  MembershipChangeRequestFormType,
} from "../../../../services/core-api-adapter";
import LoadingSpinner from "../../../Common/components/LoadingSpinner";
import MembershipCard from "../../../Common/components/MembershipCard";
import Panel from "../../../Common/components/Panel";
import SlideUpDialog from "../../../Common/components/SlideUpDialog";
import MembershipManagementDependentsList from "../MembershipManagementDependentsList";
import MembershipManagementChangeRequestForm, {
  MembershipChangeRequestActionType,
} from "../MembershipManagementChangeRequestForm";
import { useNavigate } from "react-router";
import IconLoader from "../../../Common/components/IconLoader";
import MembershipPanelWrapper from "../MembershipPanelWrapper";
import MembershipManagementChangeRequestSuccess from "../MembershipManagementChangeRequestSuccess";

interface MembershipManagementProps {
  membershipIdentifier?: string;
}

export default function MembershipManagement({
  membershipIdentifier = "",
}: MembershipManagementProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    firstMembership,
    memberships,
    membershipsFetchError,
    isMembershipsLoading,
  } = useGetMemberships();
  const [membership, setMembership] = useState<Membership | null>(null);
  const [membershipChangeRequest, setMembershipChangeRequest] =
    useState<MembershipChangeRequest | null>(null);
  const [
    isMembershipChangeOrCancelRequestSuccess,
    setIsMembershipChangeOrCancelRequestSuccess,
  ] = useState<boolean>(false);
  const { isPAYGProduct } = useGetProductType(firstMembership);

  useEffect(() => {
    if (
      getMembershipConfig(membership?.productDetails.code)
        ?.areAllMembershipManagementSupportedActionsEnabled === false
    ) {
      navigate("/home");
    }
  }, [membership, navigate]);

  useEffect(() => {
    setMembership(
      findMembershipByMemberIdentifier(membershipIdentifier || "", memberships)
    );
  }, [memberships, membershipIdentifier]);

  function onMembershipChangeOrCancelRequestSuccess() {
    setMembershipChangeRequest(null);
    setIsMembershipChangeOrCancelRequestSuccess(true);
  }

  function skipChangeRequestAndRouteToAddPeopleIfPAYG() {
    if (isPAYGProduct) {
      return navigate(
        `/home/memberships/${membershipIdentifier}/add-people?showSummaryScreen`
      );
    }
  }

  function onChangePlanButtonClick() {
    setMembershipChangeRequest({
      type: MembershipChangeRequestFormType.CHANGE_PLAN_FORM,
      formData: {
        membershipIdentifier: membershipIdentifier,
      },
    });
  }

  function onCancelPlanButtonClick() {
    setMembershipChangeRequest({
      type: MembershipChangeRequestFormType.CANCEL_PLAN_FORM,
      formData: {
        membershipIdentifier: membershipIdentifier,
      },
    });
  }

  function onEditDependentButtonClick(dependent: Member) {
    skipChangeRequestAndRouteToAddPeopleIfPAYG();
    setMembershipChangeRequest({
      type: MembershipChangeRequestFormType.EDIT_DEPENDENT_FORM,
      formData: {
        membershipIdentifier: membershipIdentifier,
        dependent: dependent,
      },
    });
  }

  function onRemoveDependentButtonClick(dependent: Member) {
    skipChangeRequestAndRouteToAddPeopleIfPAYG();
    setMembershipChangeRequest({
      type: MembershipChangeRequestFormType.REMOVE_DEPENDENT_FORM,
      formData: {
        membershipIdentifier: membershipIdentifier,
        dependent: dependent,
      },
    });
  }

  function onAddDependentButtonClick() {
    skipChangeRequestAndRouteToAddPeopleIfPAYG();
    setMembershipChangeRequest({
      type: MembershipChangeRequestFormType.ADD_DEPENDENT_FORM,
      formData: {
        membershipIdentifier: membershipIdentifier,
      },
    });
  }

  const membersOtherThanCurrentlyAuthenticatedMember =
    findMembersOtherThanTheCurrentlyAuthenticatedMemberInMembership(membership);

  const isDependentsListShown =
    membersOtherThanCurrentlyAuthenticatedMember &&
    (membership?.productDetails?.allowDependents ||
      membersOtherThanCurrentlyAuthenticatedMember.length > 0);

  return (
    <>
      {isMembershipChangeOrCancelRequestSuccess && (
        <MembershipManagementChangeRequestSuccess />
      )}
      {!isMembershipChangeOrCancelRequestSuccess && (
        <MembershipPanelWrapper
          goBackButtonText={t("MembershipManagementPage.goBackButton")}
          goBackButtonVisibleText={t(
            "MembershipManagementPage.goBackButtonVisibleLabel"
          )}
          title={t("MembershipManagementPage.title")}
        >
          <Panel>
            {isMembershipsLoading ? (
              <Stack alignItems="center">
                <LoadingSpinner />
              </Stack>
            ) : (
              <Stack spacing={2}>
                {!membership || membershipsFetchError ? (
                  <Typography variant="h4" align="center">
                    {t("common.somethingWentWrong")}
                  </Typography>
                ) : (
                  <Stack spacing={4}>
                    <Stack spacing={2}>
                      <MembershipCard
                        config={getMembershipCardConfigFromMembership(
                          membership
                        )}
                      />

                      <Stack direction="row" spacing={2}>
                        <Button
                          size="small"
                          fullWidth
                          onClick={onChangePlanButtonClick}
                        >
                          {t("MembershipDetailPanel.changePlanButton")}
                        </Button>

                        <Button
                          variant="outlined"
                          size="small"
                          fullWidth
                          onClick={onCancelPlanButtonClick}
                        >
                          {t("MembershipDetailPanel.cancelPlanButton")}
                        </Button>
                      </Stack>
                    </Stack>

                    {isDependentsListShown && (
                      <MembershipManagementDependentsList
                        dependents={
                          membersOtherThanCurrentlyAuthenticatedMember
                        }
                        onEdit={onEditDependentButtonClick}
                        onRemove={onRemoveDependentButtonClick}
                      />
                    )}

                    {membership?.productDetails?.allowDependents && (
                      <>
                        {isDependentsListShown && <Divider sx={{ mx: 2 }} />}
                        <Button
                          variant="outlined"
                          size="small"
                          fullWidth
                          onClick={onAddDependentButtonClick}
                        >
                          {t("MembershipManagementPanel.addDependentsButton")}
                        </Button>
                      </>
                    )}
                  </Stack>
                )}
              </Stack>
            )}

            <SlideUpDialog
              isOpen={
                membershipChangeRequest?.type ===
                MembershipChangeRequestFormType.CHANGE_PLAN_FORM
              }
              onClose={() => setMembershipChangeRequest(null)}
              label={t("MembershipManagementChangePlanForm.title")}
            >
              <MembershipManagementChangeRequestForm
                membershipIdentifier={membershipIdentifier}
                onCancel={() => setMembershipChangeRequest(null)}
                onClose={() => setMembershipChangeRequest(null)}
                onSuccess={onMembershipChangeOrCancelRequestSuccess}
                membershipActionType={
                  MembershipChangeRequestActionType.CHANGE_PLAN
                }
                Icon={
                  <IconLoader
                    icon="FaceWithRefreshIcon"
                    sx={{
                      color: "accent1.main",
                      fontSize: (theme) => theme.spacing(10),
                    }}
                  />
                }
              />
            </SlideUpDialog>

            <SlideUpDialog
              isOpen={
                membershipChangeRequest?.type ===
                MembershipChangeRequestFormType.CANCEL_PLAN_FORM
              }
              onClose={() => setMembershipChangeRequest(null)}
              label={t("MembershipManagementCancelPlanForm.title")}
            >
              <MembershipManagementChangeRequestForm
                membershipIdentifier={membershipIdentifier}
                membershipActionType={
                  MembershipChangeRequestActionType.CANCEL_PLAN
                }
                onCancel={() => setMembershipChangeRequest(null)}
                onClose={() => setMembershipChangeRequest(null)}
                onSuccess={onMembershipChangeOrCancelRequestSuccess}
                Icon={
                  <IconLoader
                    icon="FaceWithCrossIcon"
                    color="error"
                    sx={{
                      fontSize: (theme) => theme.spacing(10),
                    }}
                  />
                }
              />
            </SlideUpDialog>

            <SlideUpDialog
              isOpen={
                membershipChangeRequest?.type ===
                MembershipChangeRequestFormType.EDIT_DEPENDENT_FORM
              }
              onClose={() => setMembershipChangeRequest(null)}
              label={t("MembershipManagementEditDependentForm.title")}
            >
              <MembershipManagementChangeRequestForm
                membershipIdentifier={membershipIdentifier}
                membershipActionType={
                  MembershipChangeRequestActionType.EDIT_DEPENDENT
                }
                dependent={membershipChangeRequest?.formData.dependent}
                onCancel={() => setMembershipChangeRequest(null)}
                onClose={() => setMembershipChangeRequest(null)}
                Icon={
                  <IconLoader
                    icon="FaceWithRefreshIcon"
                    sx={{
                      color: "accent1.main",
                      fontSize: (theme) => theme.spacing(10),
                    }}
                  />
                }
              />
            </SlideUpDialog>

            <SlideUpDialog
              isOpen={
                membershipChangeRequest?.type ===
                MembershipChangeRequestFormType.REMOVE_DEPENDENT_FORM
              }
              onClose={() => setMembershipChangeRequest(null)}
              label={t("MembershipManagementRemoveDependentForm.title")}
            >
              <MembershipManagementChangeRequestForm
                membershipIdentifier={membershipIdentifier}
                membershipActionType={
                  MembershipChangeRequestActionType.REMOVE_DEPENDENT
                }
                onCancel={() => setMembershipChangeRequest(null)}
                onClose={() => setMembershipChangeRequest(null)}
                Icon={
                  <IconLoader
                    icon="FaceWithCrossIcon"
                    color="error"
                    sx={{
                      fontSize: (theme) => theme.spacing(10),
                    }}
                  />
                }
              />
            </SlideUpDialog>

            <SlideUpDialog
              isOpen={
                membershipChangeRequest?.type ===
                MembershipChangeRequestFormType.ADD_DEPENDENT_FORM
              }
              onClose={() => setMembershipChangeRequest(null)}
              label={t("MembershipManagementAddDependentForm.title")}
            >
              <MembershipManagementChangeRequestForm
                membershipIdentifier={membershipIdentifier}
                onCancel={() => setMembershipChangeRequest(null)}
                onClose={() => setMembershipChangeRequest(null)}
                membershipActionType={
                  MembershipChangeRequestActionType.ADD_DEPENDENT
                }
                Icon={
                  <IconLoader
                    icon="GroupOfFacesIcon"
                    sx={{ fontSize: (theme) => theme.spacing(10) }}
                  />
                }
              />
            </SlideUpDialog>
          </Panel>
        </MembershipPanelWrapper>
      )}
    </>
  );
}
