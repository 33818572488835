import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { convertBooleanLikeStringToBoolean } from "../../../../utils";
import { trackUserInteraction } from "@/services/analytics-adapter";

export enum QuestionStatusType {
  ALLERGIES = "Allergies",
  CONDITIONS = "Conditions",
}

interface Props {
  type: QuestionStatusType;
  data?: string;
  onChange?: (data: any) => void;
}

export default function QuestionTypeStatus(props: Props) {
  const { t } = useTranslation();
  const [internalValue, setInternalValue] = useState<boolean | null>(
    convertBooleanLikeStringToBoolean(props?.data)
  );

  const options = [
    {
      name: t("common.yes"),
      value: true,
    },
    {
      name: t("common.no"),
      value: false,
    },
  ];

  useEffect(() => {
    setInternalValue(convertBooleanLikeStringToBoolean(props?.data));
  }, [props?.data]);

  useEffect(() => {
    const isValid = internalValue !== null;
    if (props.onChange) {
      props.onChange({
        data: {
          value: internalValue === true ? "Yes" : "No",
        },
        isValid,
      });
    }
    if (isValid) {
      trackUserInteraction({
        linkText: `Edit ${props.type} status | ${internalValue === true ? "Yes" : "No"}`,
        linkIntent: "confirmational",
        linkScope: "button",
      });
    }
  }, [internalValue]);

  return (
    <>
      <Stack spacing={2} justifyContent="space-between">
        <Stack spacing={4}>
          <Typography variant="h3">
            {t(`QuestionType${props.type}Status.title`)}
          </Typography>
          <ToggleButtonGroup
            color="neutral"
            exclusive
            value={internalValue}
            onChange={(_event, value) => setInternalValue(value)}
            fullWidth
          >
            {options.map(function (option, index) {
              return (
                <ToggleButton
                  key={String(option.name + index)}
                  value={option.value}
                >
                  {option.name}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Stack>
      </Stack>
    </>
  );
}
