import { Trans, useTranslation } from "react-i18next";
import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  buildWhatsAppUniversalLinkURL,
  convertPxToRem,
  convertSnakeCaseToSentenceCase,
} from "../../../../utils";
import {
  trackEvent,
  trackUserInteraction,
} from "../../../../services/analytics-adapter";
import {
  ChatBotLanguageOption,
  ChatBotOption,
  submitHealthRecordEvent,
  SubmitHealthRecordEventType,
} from "../../../../services/core-api-adapter";
import { getConfigurationVariable } from "../../../../services/configuration-adapter";
import SlideUpDialog from "@/features/Common/components/SlideUpDialog";
import IconLoader from "@/features/Common/components/IconLoader";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "@/services/feature-toggle-adapter";
import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";
interface ChatBotOptionSelectionModalProps {
  isOpen: boolean;
  selectedOption: ChatBotOption;
  onClose: () => void;
}

const CHAT_BOT_WHATSAPP_PHONE_NUMBER = getConfigurationVariable(
  "VITE_APP_UNU_HEALTH_SUPPORT_CHAT_BOT_PHONE_NUMBER_ZA"
);

export default function ChatBotOptionSelectionModal({
  isOpen = true,
  selectedOption,
  onClose = () => {},
}: ChatBotOptionSelectionModalProps) {
  const { t } = useTranslation();

  const isIsiZuluEnabled = isFeatureEnabled(
    FeatureToggleIdentifier.ENABLE_CHAT_BOT_ISIZULU
  );

  const [selectedLanguage, setSelectedLanguage] =
    useState<ChatBotLanguageOption | null>(
      isIsiZuluEnabled ? null : ChatBotLanguageOption.ENGLISH
    );

  function onChatBotLanguageOptionButtonClick(option: ChatBotLanguageOption) {
    setSelectedLanguage(option);
    trackUserInteraction({
      linkText: `Chatbot | ${option} language selected`,
      linkIntent: "navigational",
      linkScope: "button",
    });
  }

  function getPrefilledMessage() {
    if (selectedOption && selectedLanguage) {
      const formattedOption = convertSnakeCaseToSentenceCase(selectedOption);
      return `${formattedOption} ${selectedLanguage}`;
    }
  }

  function onChatOnWhatsappButtonClick() {
    trackEvent({
      event: "action.sweetLifeChatOnWhatsAppButtonClicked",
      source: "Chat bot screen",
      option: selectedOption,
      language: selectedLanguage,
    });

    submitHealthRecordEvent(
      SubmitHealthRecordEventType.SWEETLIFE,
      selectedOption as any
    ).catch(() => {});

    onClose();
  }

  function onDialogClose() {
    setSelectedLanguage(
      isIsiZuluEnabled ? null : ChatBotLanguageOption.ENGLISH
    );
    trackUserInteraction({
      linkText: `Chatbot | Close`,
      linkIntent: "navigational",
      linkScope: "button",
    });
    onClose();
  }
  return (
    <SlideUpDialog
      isOpen={isOpen}
      onClose={onDialogClose}
      label={t("ChatBotPage.modal.language.title")}
    >
      <Stack spacing={4} textAlign={"center"}>
        <Stack spacing={2}>
          <Typography variant="body1" fontWeight={600}>
            {t("ChatBotPage.modal.language.title")}
          </Typography>
          <ToggleButtonGroup
            color="neutral"
            onChange={(_event, option) =>
              onChatBotLanguageOptionButtonClick(option)
            }
            value={selectedLanguage}
            fullWidth
            exclusive
          >
            <ToggleButton value={ChatBotLanguageOption.ENGLISH}>
              {t("ChatBotPage.modal.options.englishButton")}
            </ToggleButton>
            {isIsiZuluEnabled ? (
              <ToggleButton value={ChatBotLanguageOption.ZULU}>
                {t("ChatBotPage.modal.options.zuluButton")}
              </ToggleButton>
            ) : (
              <ToggleButton
                value={ChatBotLanguageOption.ZULU}
                disabled
                sx={{
                  display: "block",
                }}
              >
                <Typography color="neutral.500" lineHeight="1">
                  <Trans i18nKey="ChatBotPage.modal.options.zuluButton.disabled">
                    <Typography
                      component="span"
                      variant="body2"
                      color="inherit"
                      fontSize={convertPxToRem(10)}
                    ></Typography>
                  </Trans>
                </Typography>
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </Stack>

        <ButtonWithAnalytics
          page="Chat Bot"
          text={t(`common.chatOnWhatsApp`)}
          intent="navigational"
          component="a"
          href={buildWhatsAppUniversalLinkURL(
            CHAT_BOT_WHATSAPP_PHONE_NUMBER,
            getPrefilledMessage()
          )}
          sx={{ alignItems: "center" }}
          aria-label={t("common.chatOnWhatsApp")}
          color="whatsApp"
          onClick={onChatOnWhatsappButtonClick}
          disabled={!selectedLanguage}
        >
          <Box display="flex" alignSelf="center" pr={1}>
            <IconLoader
              sx={{ fontSize: convertPxToRem(30) }}
              icon="WhatsAppIcon"
            />
          </Box>
          {t("common.chatOnWhatsApp")}
        </ButtonWithAnalytics>
        <Typography color="neutral.light" variant="body1" fontWeight={600}>
          {t("ChatBotPage.modal.footer")}
        </Typography>
      </Stack>
    </SlideUpDialog>
  );
}
