import { Divider, Stack, Typography } from "@mui/material";

const paragraphContent = () => {
  return (
    <>
      A paragraph (from the <strong>Greek paragraphos</strong>,{" "}
      <em>“to write beside”</em> or{" "}
      <strong>
        <em>“written beside”</em>
      </strong>
      ) is a self-contained unit of a discourse in writing dealing with a
      particular <a href="#">point or idea</a>. A paragraph consists of one or
      more sentences.
    </>
  );
};

const headingContent = (level: number) => {
  return (
    <>
      Heading {level} with some <strong>strong</strong>, <em>emphasized</em>,{" "}
      <strong>
        <em>strong & emphasized</em>
      </strong>{" "}
      <a href="#">link</a> text
    </>
  );
};

const sectionHeading = (sectionTitle: string) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h2" textAlign="center">
        {sectionTitle}
      </Typography>
    </Stack>
  );
};

export default function TypographyExamples() {
  return (
    <Stack divider={<Divider />} spacing={2}>
      <Stack>
        {sectionHeading("Variants")}
        <Typography variant="h1">Typography (variant="h1")</Typography>
        <Typography variant="h2">Typography (variant="h2")</Typography>
        <Typography variant="h3">Typography (variant="h3")</Typography>
        <Typography variant="h4">Typography (variant="h4")</Typography>
        <Typography variant="h5">Typography (variant="h5")</Typography>
        <Typography variant="h6">Typography (variant="h6")</Typography>

        <Typography variant="subtitle1">
          Typography (variant="subtitle1")
        </Typography>

        <Typography variant="subtitle2">
          Typography (variant="subtitle2")
        </Typography>

        <Typography variant="body2">Typography (variant="body2")</Typography>

        <Typography variant="button">Typography (variant="button")</Typography>

        <Typography>Typography (no variant)</Typography>

        <p>{paragraphContent()}</p>
      </Stack>

      <Stack>
        {sectionHeading("Text Flow")}

        <Typography variant="h1">{headingContent(1)}</Typography>
        <p>{paragraphContent()}</p>
        <p>{paragraphContent()}</p>

        <Typography variant="h2">{headingContent(2)}</Typography>
        <p>{paragraphContent()}</p>
        <p>{paragraphContent()}</p>

        <Typography variant="h3">{headingContent(3)}</Typography>
        <p>{paragraphContent()}</p>
        <p>{paragraphContent()}</p>

        <Typography variant="h4">{headingContent(4)}</Typography>
        <p>{paragraphContent()}</p>
        <p>{paragraphContent()}</p>

        <Typography variant="h5">{headingContent(5)}</Typography>
        <p>{paragraphContent()}</p>
        <p>{paragraphContent()}</p>

        <Typography variant="h6">{headingContent(6)}</Typography>
        <p>{paragraphContent()}</p>
        <p>{paragraphContent()}</p>
      </Stack>

      <Stack>
        {sectionHeading("Text Flow in Stack (spacing={1})")}

        <Stack spacing={1}>
          <Typography variant="h1">{headingContent(1)}</Typography>
          <Typography variant="h2">{headingContent(2)}</Typography>
          <Typography variant="h3">{headingContent(3)}</Typography>
          <p>{paragraphContent()}</p>
          <p>{paragraphContent()}</p>
        </Stack>
      </Stack>

      <Stack>
        {sectionHeading("Text Flow in Stack (spacing={2})")}

        <Stack spacing={2}>
          <Typography variant="h1">{headingContent(1)}</Typography>
          <Typography variant="h2">{headingContent(2)}</Typography>
          <Typography variant="h3">{headingContent(3)}</Typography>
          <p>{paragraphContent()}</p>
          <p>{paragraphContent()}</p>
        </Stack>
      </Stack>

      <Stack>
        {sectionHeading("Text Flow in Stack (spacing={3})")}

        <Stack spacing={3}>
          <Typography variant="h1">{headingContent(1)}</Typography>
          <Typography variant="h2">{headingContent(2)}</Typography>
          <Typography variant="h3">{headingContent(3)}</Typography>
          <p>{paragraphContent()}</p>
          <p>{paragraphContent()}</p>
        </Stack>
      </Stack>
    </Stack>
  );
}
