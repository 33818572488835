import { Button, Stack } from "@mui/material";
import { useState } from "react";

const ErrorComponent = () => {
  throw new Error("");
};

export default function CrashAppButtonExamples() {
  const [dynamicComponent, setDynamicComponent] = useState<any>(null);

  function crashApp() {
    ("asd" as any).map((el: any) => el);
  }

  function loadErrorComponent() {
    setDynamicComponent(<ErrorComponent />);
  }

  return (
    <Stack spacing={2}>
      <Button color="error" onClick={crashApp}>
        Crash the App
      </Button>

      <Button color="error" onClick={loadErrorComponent}>
        Load a component with an error
      </Button>

      {dynamicComponent}
    </Stack>
  );
}
