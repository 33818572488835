import { Stack } from "@mui/material";
import NurseConsultMoreInfo from ".";

export default function NurseConsultMoreInfoExamples() {
  function onBackButtonClick() {
    console.log("Back button clicked");
  }

  return (
    <Stack>
      <NurseConsultMoreInfo onBackButtonClick={onBackButtonClick} />
    </Stack>
  );
}
