import { Stack } from "@mui/material";
import OutsideConsultationOperatingHours from ".";
import { ProductAdministratorSupportedServiceChannelAvailability } from "../../../../services/core-api-adapter";

const AVAILABILITY_DATA = [
  {
    day: "MONDAY",
    operatingHours: "07:00 - 18:45",
  },
  {
    day: "TUESDAY",
    operatingHours: "07:00 - 18:45",
  },
  {
    day: "WEDNESDAY",
    operatingHours: "07:00 - 18:45",
  },
  {
    day: "THURSDAY",
    operatingHours: "07:00 - 18:45",
  },
  {
    day: "FRIDAY",
    operatingHours: "07:00 - 18:45",
  },
  {
    day: "SATURDAY",
    operatingHours: "08:00 - 14:45",
  },
  {
    day: "SUNDAY",
    operatingHours: "09:00 - 12:45",
  },
  {
    day: "HOLIDAY",
    operatingHours: "09:00 - 12:45",
  },
] as ProductAdministratorSupportedServiceChannelAvailability[];

export default function OutsideConsultationOperatingHoursExamples() {
  return (
    <Stack sx={{ height: "100vh" }}>
      <OutsideConsultationOperatingHours
        redirectUrl="http://google.com"
        availabilityData={AVAILABILITY_DATA}
      />

      <OutsideConsultationOperatingHours
        redirectUrl="http://google.com"
        availabilityData={AVAILABILITY_DATA}
        shouldDisplayViewConsultationHistoryCTA
      />
    </Stack>
  );
}
