import { Stack, Typography } from "@mui/material";
import PathologyOrderDetails from ".";
import MaxWidthContainer from "../../../Common/components/MaxWidthContainer";

export default function PathologyOrderDetailsExamples() {
  return (
    <MaxWidthContainer>
      <Typography variant="h3" color="primary" textAlign="center" mb={2}>
        Pathology Order Details (Not pending)
      </Typography>
      <Stack height="100vh">
        <PathologyOrderDetails
          orderDate="2027-04-19T13:42:36.521883+00:00"
          isOrderPending={false}
          paymentDate="2027-04-19T13:42:36.521883+00:00"
        />
      </Stack>
      <Typography variant="h3" color="primary" textAlign="center" mb={2}>
        Pathology Order Details (pending)
      </Typography>
      <Stack height="100vh">
        <PathologyOrderDetails
          orderDate="2027-04-19T13:42:36.521883+00:00"
          isOrderPending={true}
          paymentDate="2027-04-19T13:42:36.521883+00:00"
        />
      </Stack>
    </MaxWidthContainer>
  );
}
