import { MemberProductPaymentRatesChargeCode } from "@/services/core-api-adapter";
import { ThirdPartyConsultationTypeSelection } from ".";

export default function ThirdPartyConsultationTypeSelectionExamples() {
  function onNurseConsultClick() {
    console.log("Click nurse consult");
  }

  function onDoctorConsultClick() {
    console.log("Click doctor consult");
  }

  function onLaboratoryServicesClick() {
    console.log("Click laboratory services");
  }

  function onPharmacyServicesClick() {
    console.log("Click pharmacy services");
  }

  function onClinicAppointmentClick() {
    console.log("Click clinic appointment services");
  }

  return (
    <>
      <ThirdPartyConsultationTypeSelection
        productPaymentRates={[
          {
            chargeCode:
              MemberProductPaymentRatesChargeCode.REMOTE_DOCTOR_CONSULTATIONS,
            amount: "20,000 UGX",
            amountFormatted: "20,000 UGX",
            ussdConfiguration: {
              ussd: "*280#",
              menuOption: 1,
            },
          },
          {
            chargeCode: MemberProductPaymentRatesChargeCode.CLINIC,
            amount: "20,000 UGX",
            amountFormatted: "20,000 UGX",
            ussdConfiguration: {
              ussd: "*280*4#",
              menuOption: 1,
            },
          },
          {
            chargeCode: MemberProductPaymentRatesChargeCode.LABORATORY,
            amount: "3,000 UGX",
            amountFormatted: "3,000 UGX",
            ussdConfiguration: {
              ussd: "*280*2#",
              menuOption: 1,
            },
          },
          {
            chargeCode: MemberProductPaymentRatesChargeCode.PHARMACY,
            amount: "2,000 UGX",
            amountFormatted: "2,000 UGX",
            ussdConfiguration: {
              ussd: "*280*3#",
              menuOption: 1,
            },
          },
          {
            chargeCode: MemberProductPaymentRatesChargeCode.NURSE_CHAT,
            amount: "0UGX",
            amountFormatted: "0 UGX",
            ussdConfiguration: undefined,
          },
        ]}
        onNurseConsultClick={onNurseConsultClick}
        onDoctorConsultClick={onDoctorConsultClick}
        onLaboratoryServicesClick={onLaboratoryServicesClick}
        onPharmacyServicesClick={onPharmacyServicesClick}
        onClinicAppointmentClick={onClinicAppointmentClick}
      />
    </>
  );
}
