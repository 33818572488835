import { Stack } from "@mui/material";
import HealthRecordEmptyState from ".";

export default function HealthRecordEmptyStateExamples() {
  return (
    <Stack spacing={2}>
      <HealthRecordEmptyState />
    </Stack>
  );
}
