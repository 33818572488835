import { Stack } from "@mui/material";
import PaymentReceipt from ".";

export default function PaymentReceiptExamples() {
  return (
    <Stack height="100vh">
      <PaymentReceipt
        amountFormatted="R200.00"
        transactionDate="2024-10-15T14:45:00+02:00"
        referenceNumber="1234567891011"
        merchantId="0000000035792468"
        cardNumber="**** **** **** 1234"
      />
    </Stack>
  );
}
