import { ChangeEvent, useEffect, useState } from "react";
import { Stack, TextField, Typography } from "@mui/material";
import HealthNudge, { HealthNudgeProps } from ".";

export default function HealthNudgeExamples() {
  const [completionValue, setCompletionValue] = useState(50);
  const [completionValueText, setCompletionValueText] = useState(
    String(completionValue)
  );

  const healthNudgesItems: HealthNudgeProps[] = [
    { callToActionButtonColour: "primary", completionPercentage: 0 },
    { callToActionButtonColour: "accent1", completionPercentage: 0 },
    { callToActionButtonColour: "accent2", completionPercentage: 0 },
    { callToActionButtonColour: "accent3", completionPercentage: 0 },
    { callToActionButtonColour: "accent4", completionPercentage: 0 },
    { callToActionButtonColour: "accent5", completionPercentage: 0 },
  ];

  useEffect(() => {
    setCompletionValue(Number(completionValueText));
  }, [completionValueText]);

  return (
    <Stack spacing={2} sx={{ pt: 2 }}>
      <TextField
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setCompletionValueText(event.target.value)
        }
        value={String(completionValueText)}
        fullWidth
      />

      {healthNudgesItems.map((healthNudgeItem, index) => (
        <Stack key={index} spacing={1}>
          <Typography>{healthNudgeItem.callToActionButtonColour}</Typography>
          <HealthNudge
            callToActionButtonColour={healthNudgeItem.callToActionButtonColour}
            completionPercentage={completionValue}
          />
        </Stack>
      ))}
    </Stack>
  );
}
