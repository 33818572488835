import { Navigate } from "react-router";
import { useMachine } from "@xstate/react";
import { Fade, Stack } from "@mui/material";
import { welcomeFlowMachine } from "./machine";
import CVPFlow from "@/features/Welcome/components/CVPFlow";
import AuthIdentificationTypeSelection from "@/features/Auth/components/AuthIdentificationTypeSelection";
import { trackEvent, trackPageViewV2 } from "@/services/analytics-adapter";
import { useGlobalStore } from "@/store";
import { getDiffBetweenDatesInHours } from "@/utils";
import { useEffect } from "react";
import WelcomeLandingScreen from "../WelcomeLandingScreen";

export default function WelcomeFlow() {
  const { state, dispatch } = useGlobalStore();
  const [welcomeFlowMachineState, dispatchWelcomeFlowMachineEvent] =
    useMachine(welcomeFlowMachine);

  function onAuthIdentificationTypeSelectionComplete(authType: string) {
    dispatchWelcomeFlowMachineEvent({
      type: "AUTH_IDENTIFICATION_TYPE_COMPLETED",
    });

    trackEvent({
      event: "action.authIdentificationTypeSelected",
      authIdentificationType: authType,
      source: "Welcome flow",
    });
  }

  function onCVPFlowComplete(skipWelcomePage: boolean) {
    dispatchWelcomeFlowMachineEvent({
      type: "CVP_VIEW_COMPLETED",
    });

    if (skipWelcomePage) {
      dispatchWelcomeFlowMachineEvent({
        type: "WELCOME_CONTINUE_BUTTON_CLICKED",
      });
    }
  }

  function onWelcomeLandingScreenContinue() {
    setWelcomeScreenLastViewedAt();

    dispatchWelcomeFlowMachineEvent({
      type: "WELCOME_CONTINUE_BUTTON_CLICKED",
    });
  }

  function onWelcomeLandingScreenSignUp() {
    setWelcomeScreenLastViewedAt();

    dispatchWelcomeFlowMachineEvent({
      type: "WELCOME_SIGN_UP_BUTTON_CLICKED",
    });
  }

  function setWelcomeScreenLastViewedAt() {
    dispatch({
      type: "SET_WELCOME_SCREEN_LAST_VIEWED",
    });
  }

  useEffect(() => {
    const hasWelcomeScreenBeenViewed = state.welcomeScreenLastViewed.value;
    const hasUserSelectedAuthIdentificationType =
      state.userSelectedAuthIdentificationType.value;
    const nowInUTCString = new Date().toUTCString();

    const WELCOME_SCREEN_MAX_VIEWED_HOURS = 4;
    const numberOfHoursSinceLastViewed = Number(
      getDiffBetweenDatesInHours(
        new Date(nowInUTCString),
        new Date(state.welcomeScreenLastViewed.createdAt)
      )
    );

    const hasWelcomeScreenBeenViewedRecently =
      numberOfHoursSinceLastViewed <= WELCOME_SCREEN_MAX_VIEWED_HOURS;

    if (hasWelcomeScreenBeenViewed && hasWelcomeScreenBeenViewedRecently) {
      onWelcomeLandingScreenContinue();
    }

    if (
      welcomeFlowMachineState.matches("WelcomeLandingScreen") &&
      !hasWelcomeScreenBeenViewed
    ) {
      trackPageViewV2({
        pageName: "Home:Welcome",
        pageSubSection1: "Home",
        pageSubSection2: "Home:Welcome",
        pageCategory: "Home",
      });
    }

    if (
      welcomeFlowMachineState.matches("authIdentificationTypeSelection") &&
      !hasUserSelectedAuthIdentificationType
    ) {
      trackPageViewV2({
        pageName: "Home:Auth identification type selection",
        pageSubSection1: "Home",
        pageSubSection2: "Home:Auth identification type selection",
        pageCategory: "Home",
      });
    }
  }, [welcomeFlowMachineState]);

  return (
    <>
      <Fade in={true}>
        <Stack flexGrow={1} minHeight="100%">
          {welcomeFlowMachineState.matches(
            "authIdentificationTypeSelection"
          ) && (
            <AuthIdentificationTypeSelection
              onContinue={onAuthIdentificationTypeSelectionComplete}
            />
          )}

          {welcomeFlowMachineState.matches("customerValueProposition") && (
            <CVPFlow
              onContinue={onCVPFlowComplete}
              isSignUp={welcomeFlowMachineState.context.signUpButtonClicked}
            />
          )}

          {welcomeFlowMachineState.matches("WelcomeLandingScreen") && (
            <WelcomeLandingScreen
              onContinue={onWelcomeLandingScreenContinue}
              onSignUp={onWelcomeLandingScreenSignUp}
            />
          )}
        </Stack>
      </Fade>

      {welcomeFlowMachineState.matches("exit") && (
        <Navigate to="/login"></Navigate>
      )}
    </>
  );
}
