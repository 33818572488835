import { MenuItem, Select, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import IconLoader from "../../../Common/components/IconLoader";

export default function TabsExamples() {
  const [scrollableValue, setScrollableValue] = useState(1);
  const [nonScrollableValue, setNonScrollableValue] = useState(1);
  const [customizatedValue, setCustomizedValue] = useState(1);
  const [textColour, setTextColour] = useState<
    "primary" | "secondary" | "inherit" | undefined
  >("primary");

  function setTabValue(newTab: any, setter: any) {
    setter(newTab);
  }

  function onTextColourChange(el: any) {
    setTextColour(el.target.value);
  }

  return (
    <Stack spacing={4}>
      <Select value={textColour} onChange={onTextColourChange}>
        <MenuItem value={"primary"}>primary</MenuItem>
        <MenuItem value={"secondary"}>secondary</MenuItem>
      </Select>

      <Stack spacing={1}>
        <Typography variant="h3" textAlign="center">
          Scrollable tabs
        </Typography>

        <Tabs
          textColor={textColour}
          value={scrollableValue}
          onChange={(_, newTab) => setTabValue(newTab, setScrollableValue)}
          variant="scrollable"
          scrollButtons={false}
        >
          <Tab value={1} label={"1"} />
          <Tab value={2} label={"2"} />
          <Tab value={3} label={"3"} />
          <Tab value={4} label={"4"} />
          <Tab value={5} label={"5"} />
        </Tabs>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="h3" textAlign="center">
          Non Scrollable tabs
        </Typography>
        <Tabs
          textColor={textColour}
          value={nonScrollableValue}
          onChange={(_, newTab) => setTabValue(newTab, setNonScrollableValue)}
          scrollButtons={false}
        >
          <Tab value={1} label={"1"} />
          <Tab value={2} label={"2"} />
          <Tab value={3} label={"3"} />
          <Tab value={4} label={"4"} />
          <Tab value={5} label={"5"} />
        </Tabs>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="h3" textAlign="center">
          Tabs customization
        </Typography>
        <Tabs
          textColor={textColour}
          value={customizatedValue}
          onChange={(_, newTab) => setTabValue(newTab, setCustomizedValue)}
          variant="scrollable"
          scrollButtons={true}
        >
          <Tab
            value={1}
            label={
              "A lot of text here. A lot of text here. A lot of text here."
            }
          />
          <Tab value={2} disabled label={"Disabled"} />
          <Tab
            icon={<IconLoader icon="HomeIcon" />}
            value={3}
            label={"Tab with icon"}
          />
        </Tabs>
      </Stack>
    </Stack>
  );
}
