import { Stack } from "@mui/material";
import FaceWithIcon from ".";

import theme from "../../../../theme";

export default function FaceWithIconExamples() {
  return (
    <>
      <Stack spacing={2} mt={3}>
        <FaceWithIcon
          color={"error.main"}
          height={60}
          width={60}
          Icon={"CrossIcon"}
        />
        <FaceWithIcon
          color={"accent1.main"}
          height={80}
          width={80}
          Icon={"BookmarkIcon"}
        />
        <FaceWithIcon
          color={theme.palette.primary[700]}
          Icon={"CheckmarkIcon"}
        />
      </Stack>
    </>
  );
}
