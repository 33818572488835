import { Divider, Stack, Typography } from "@mui/material";
import GetMedicalAssistanceModalContent from ".";
import {
  buildProductDetails,
  buildMembership,
} from "../../../Playground/pages/PlaygroundPage";

export default function GetMedicalAssistanceModalContentExamples() {
  return (
    <Stack p={2} spacing={10} divider={<Divider />}>
      <Stack spacing={2}>
        <Typography variant="h2">BEWELL 101</Typography>
        <GetMedicalAssistanceModalContent
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "BEWELL 101",
            }),
          })}
        />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h2">BEWELL-101</Typography>
        <GetMedicalAssistanceModalContent
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "BEWELL-101",
            }),
          })}
        />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h2">BEWELL 101+</Typography>
        <GetMedicalAssistanceModalContent
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "BEWELL 101+",
            }),
          })}
        />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h2">BEWELL 102</Typography>
        <GetMedicalAssistanceModalContent
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "BEWELL 102",
            }),
          })}
        />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h2">BEWELL 103</Typography>
        <GetMedicalAssistanceModalContent
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "BEWELL 103",
            }),
          })}
        />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h2">BW101</Typography>
        <GetMedicalAssistanceModalContent
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "BW101",
            }),
          })}
        />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h2">B101P</Typography>
        <GetMedicalAssistanceModalContent
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "B101P",
            }),
          })}
        />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h2">BW102</Typography>
        <GetMedicalAssistanceModalContent
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "BW102",
            }),
          })}
        />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h2">BW103</Typography>
        <GetMedicalAssistanceModalContent
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "BW103",
            }),
          })}
        />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h2">GLDHR</Typography>
        <GetMedicalAssistanceModalContent
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "GLDHR",
            }),
          })}
        />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h2">Khululekha</Typography>
        <GetMedicalAssistanceModalContent
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "Khululekha",
            }),
          })}
        />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h2">PRIST</Typography>
        <GetMedicalAssistanceModalContent
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "PRIST",
            }),
          })}
        />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h2">UNHANDLED_PRODUCT_CODE</Typography>
        <GetMedicalAssistanceModalContent
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "UNHANDLED_PRODUCT_CODE",
            }),
          })}
        />
      </Stack>
    </Stack>
  );
}
