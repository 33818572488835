import { Stack } from "@mui/material";
import GetHelpCTAButton from ".";
import MaxWidthContainer from "../MaxWidthContainer";

export default function GetHelpCTAButtonExamples() {
  return (
    <MaxWidthContainer>
      <Stack
        direction="column"
        justifyContent="space-between"
        position="absolute"
        left="10px"
      >
        <GetHelpCTAButton />

        <GetHelpCTAButton iconColor="primary" buttonColor="info" />

        <GetHelpCTAButton iconColor="info" buttonColor="success" />
      </Stack>
    </MaxWidthContainer>
  );
}
