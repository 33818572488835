import { Stack } from "@mui/material";
import PaymentFailure from ".";

export default function PaymentFailureExamples() {
  return (
    <Stack sx={{ height: "100vh" }}>
      <PaymentFailure />
    </Stack>
  );
}
