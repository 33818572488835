import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultDialog, {
  DefaultDialogImplementationProps,
} from "../DefaultDialog";
import ButtonWithAnalytics from "../ButtonWithAnalytics";

export default function WhyAreWeAskingYouThisModal({
  isOpen,
  onClose,
  extras,
}: DefaultDialogImplementationProps) {
  const { t } = useTranslation();

  return (
    <DefaultDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("WhyAreWeAskingYouThisModal.title")}
      actionButtons={
        <ButtonWithAnalytics
          page="WhyAreWeAskingYouThisModal"
          text={t("common.close")}
          intent="navigational"
          size="small"
          onClick={() => onClose()}
        >
          {t("common.close")}
        </ButtonWithAnalytics>
      }
    >
      <Typography align="center">{extras}</Typography>
      <Typography align="center">
        {t("WhyAreWeAskingYouThisModal.defaultSignOff")}
      </Typography>
    </DefaultDialog>
  );
}
