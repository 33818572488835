import { MemberProfile } from "@/services/core-api-adapter";
import PayGMemberDashboard from ".";
import { Stack, Typography } from "@mui/material";
import {
  defaultFullscreenPageStyling,
  pageWithBottomNavigationStyling,
} from "@/theme";

export default function PayGMemberDashboardExamples() {
  return (
    <Stack spacing={4}>
      <Typography>Default</Typography>
      <Stack
        sx={{
          ...defaultFullscreenPageStyling,
          ...pageWithBottomNavigationStyling,
          position: "relative",
        }}
      >
        <PayGMemberDashboard
          isMemberProfileLoading={false}
          memberProfileFetchError={false}
          memberProfile={
            {
              firstName: "Susan",
              consultation: {
                consultationResponses: [],
                consultationRates: [
                  {
                    chargeCode: "ONLINE_DOCTOR_CONSULTATION",
                    amount: "R179.00",
                    amountFormatted: "R179",
                  },
                  {
                    chargeCode: "CHAT_TO_A_NURSE",
                    amount: "R95.00",
                    amountFormatted: "R95",
                  },
                ],
              },
              memberServiceOfferingUsageCount: "1",
              healthProfile: {
                lastUpdatedAt: "2022-07-08T10:26:04Z",
                healthScoreValue: 0,
              },
              benefitCount: 11,
              dependentCount: 1,
              membership: {
                id: "5a9e9e18-9142-44a9-8d85-0dc21759c5f0",
                fullName: "Susan",
                beneficiaryCode: "00",
                productCode: "UNU01",
                productFriendlyName: "Unu forFree",
                membershipNumber: "UNU-725",
                dependentCount: 1,
                status: "ACTIVE",
              },
              product: {
                pharmacySwitchCode: null,
                provider: null,
                administrator: {
                  id: "38d556f7-5302-4ea7-a1a4-660371b7d953",
                  code: "NHG",
                  name: "National Health Group",
                  FileUri: "FileUri",
                  timeZone: "UTC+02:00",
                  supportedServices: [
                    {
                      type: "CALL_CENTRE",
                      channels: [
                        {
                          type: "PHONE",
                          value: "+27860222286",
                          availability: [],
                        },
                        {
                          type: "EMAIL",
                          value: "test@test.com",
                          availability: [],
                        },
                        {
                          type: "WHATSAPP",
                          value: null,
                          availability: [],
                        },
                      ],
                    },
                  ],
                },
              },
            } as unknown as MemberProfile
          }
        />
      </Stack>
      <Typography>Loading</Typography>
      <Stack
        sx={{
          ...defaultFullscreenPageStyling,
          ...pageWithBottomNavigationStyling,
          position: "relative",
        }}
      >
        <PayGMemberDashboard
          isMemberProfileLoading={true}
          memberProfileFetchError={false}
          memberProfile={
            {
              firstName: "Susan",
              consultation: {
                consultationResponses: [],
                consultationRates: [
                  {
                    chargeCode: "ONLINE_DOCTOR_CONSULTATION",
                    amount: "R179.00",
                    amountFormatted: "R179",
                  },
                  {
                    chargeCode: "CHAT_TO_A_NURSE",
                    amount: "R95.00",
                    amountFormatted: "R95",
                  },
                ],
              },
              memberServiceOfferingUsageCount: "1",
              healthProfile: {
                lastUpdatedAt: "2022-07-08T10:26:04Z",
                healthScoreValue: 0,
              },
              benefitCount: 11,
              dependentCount: 1,
              membership: {
                id: "5a9e9e18-9142-44a9-8d85-0dc21759c5f0",
                fullName: "Susan",
                beneficiaryCode: "00",
                productCode: "UNU01",
                productFriendlyName: "Unu forFree",
                membershipNumber: "UNU-725",
                dependentCount: 1,
                status: "ACTIVE",
              },
              product: {
                pharmacySwitchCode: null,
                provider: null,
                administrator: {
                  id: "38d556f7-5302-4ea7-a1a4-660371b7d953",
                  code: "NHG",
                  name: "National Health Group",
                  FileUri: "FileUri",
                  timeZone: "UTC+02:00",
                  supportedServices: [
                    {
                      type: "CALL_CENTRE",
                      channels: [
                        {
                          type: "PHONE",
                          value: "+27860222286",
                          availability: [],
                        },
                        {
                          type: "EMAIL",
                          value: "test@test.com",
                          availability: [],
                        },
                        {
                          type: "WHATSAPP",
                          value: null,
                          availability: [],
                        },
                      ],
                    },
                  ],
                },
              },
            } as unknown as MemberProfile
          }
        />
      </Stack>
      <Typography>Member profile error</Typography>
      <Stack
        sx={{
          ...defaultFullscreenPageStyling,
          ...pageWithBottomNavigationStyling,
          position: "relative",
        }}
      >
        <PayGMemberDashboard
          isMemberProfileLoading={false}
          memberProfileFetchError={true}
          memberProfile={
            {
              firstName: "Susan",
              consultation: {
                consultationResponses: [],
                consultationRates: [
                  {
                    chargeCode: "ONLINE_DOCTOR_CONSULTATION",
                    amount: "R179.00",
                    amountFormatted: "R179",
                  },
                  {
                    chargeCode: "CHAT_TO_A_NURSE",
                    amount: "R95.00",
                    amountFormatted: "R95",
                  },
                ],
              },
              memberServiceOfferingUsageCount: "1",
              healthProfile: {
                lastUpdatedAt: "2022-07-08T10:26:04Z",
                healthScoreValue: 0,
              },
              benefitCount: 11,
              dependentCount: 1,
              membership: {
                id: "5a9e9e18-9142-44a9-8d85-0dc21759c5f0",
                fullName: "Susan",
                beneficiaryCode: "00",
                productCode: "UNU01",
                productFriendlyName: "Unu forFree",
                membershipNumber: "UNU-725",
                dependentCount: 1,
                status: "ACTIVE",
              },
              product: {
                pharmacySwitchCode: null,
                provider: null,
                administrator: {
                  id: "38d556f7-5302-4ea7-a1a4-660371b7d953",
                  code: "NHG",
                  name: "National Health Group",
                  FileUri: "FileUri",
                  timeZone: "UTC+02:00",
                  supportedServices: [
                    {
                      type: "CALL_CENTRE",
                      channels: [
                        {
                          type: "PHONE",
                          value: "+27860222286",
                          availability: [],
                        },
                        {
                          type: "EMAIL",
                          value: "test@test.com",
                          availability: [],
                        },
                        {
                          type: "WHATSAPP",
                          value: null,
                          availability: [],
                        },
                      ],
                    },
                  ],
                },
              },
            } as unknown as MemberProfile
          }
        />
      </Stack>
    </Stack>
  );
}
