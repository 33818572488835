import { PathologyFacility } from "@/services/core-api-adapter";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import theme, {
  overrideDefaultLinkStyling,
  shadows,
} from "../../../../../../theme";
import {
  buildMapLinkURL,
  convertPxToRem,
  isMobileDevice,
} from "../../../../../../utils";
import DefaultAccordion from "../../../../../Common/components/DefaultAccordion";
import IconLoader from "../../../../../Common/components/IconLoader";
import WatermarkIcon from "../../../../../Common/components/WatermarkIcon";

interface FindAFacilityResultsProps {
  facilitySearchResults: any[];
}

interface ResultsIconCounterProps {
  resultsLength: number;
}

function ResultsIconCounter({ resultsLength }: ResultsIconCounterProps) {
  return (
    <Box
      sx={{
        position: "relative",
        height: 35,
        width: 35,
        alignSelf: "center",
      }}
    >
      <IconLoader
        icon="HandDrawnCircleIcon"
        sx={{
          color: "accent2.light",
          fontSize: convertPxToRem(35),
          alignSelf: "center",
          position: "absolute",
          left: "0",
        }}
      />
      <Typography
        sx={{
          fontWeight: 700,
          position: "relative",
          zIndex: 1,
          top: "50%",
          left: "50%",
          textAlign: "center",
          transform: "translate(-50%, -50%)",
        }}
      >
        {resultsLength}
      </Typography>
    </Box>
  );
}

export default function FindAFacilityResults({
  facilitySearchResults,
}: FindAFacilityResultsProps) {
  const { t } = useTranslation();
  const [filteredTowns, setFilteredTowns] = useState<string[]>([]);

  function filterSearchResultsByTown(
    facilitySearchResults: any[],
    town: string
  ): any[] {
    return facilitySearchResults.filter(
      (result) => result.town?.toLowerCase() === town?.toLowerCase()
    );
  }

  function extractTownsFromSearchResults(facilitySearchResults: any[]) {
    const towns: string[] = [];

    facilitySearchResults.forEach((result) => {
      const town = result.town?.toLowerCase();
      if (town && !towns.includes(town)) {
        towns.push(town);
      }
    });

    return towns;
  }

  useMemo(() => {
    if (facilitySearchResults) {
      const filteredTownsFromSearchResults = extractTownsFromSearchResults(
        facilitySearchResults
      );
      setFilteredTowns(filteredTownsFromSearchResults);
    }
  }, [facilitySearchResults]);

  async function onShareFacilityInformationClick(
    facilityData: PathologyFacility
  ) {
    const shareData = {
      text: `${facilityData.facilityName}\n${facilityData.facilityContactNumber}\n${buildMapLinkURL(`${facilityData.facilityLatitude},${facilityData.facilityLongitude}`)}`,
    };

    if (navigator.canShare(shareData)) {
      try {
        await navigator.share(shareData);
      } catch (error: any) {
        console.log(`Error: ${error.message}`);
      }
    }
  }

  return (
    <Stack spacing={1}>
      <Typography variant="body1">
        {t("FindADoctor.suburbCityLabel")}
      </Typography>
      <Stack spacing={2}>
        {filteredTowns &&
          filteredTowns.map((town, index) => (
            <Stack key={String(town) + String(index)}>
              <DefaultAccordion
                title={town}
                expandedIconSize="large"
                expandedIconColour={theme.palette.neutral.dark}
                accordionStyles={{
                  textTransform: "capitalize",
                }}
                icon={
                  <ResultsIconCounter
                    resultsLength={
                      filterSearchResultsByTown(facilitySearchResults, town)
                        .length
                    }
                  />
                }
              >
                <Stack spacing={3}>
                  {filterSearchResultsByTown(facilitySearchResults, town).map(
                    (facilityDetails: PathologyFacility, index: number) => (
                      <Stack
                        boxShadow={shadows.light}
                        p={2}
                        spacing={2}
                        borderRadius={theme.shape.borderRadius}
                        key={
                          String(facilityDetails.facilityContactNumber) +
                          String(index)
                        }
                      >
                        <Stack
                          justifyContent="space-between"
                          direction={{ xs: "column", sm: "row" }}
                          alignItems={{ xs: "left", sm: "start" }}
                        >
                          <Stack spacing={2}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              sx={{
                                textTransform: "capitalize",
                              }}
                            >
                              {facilityDetails.facilityName}
                            </Typography>
                          </Stack>
                          <Stack justifyContent={"row"}>
                            {isMobileDevice() === true && (
                              <Button
                                variant="outlined"
                                size="small"
                                startIcon={
                                  <IconLoader
                                    icon="ShareIcon"
                                    sx={{ color: theme.palette.neutral.dark }}
                                  />
                                }
                                sx={{
                                  color: theme.palette.neutral.dark,
                                  borderColor: theme.palette.neutral.dark,
                                  fontSize: convertPxToRem(12),
                                }}
                                onClick={() =>
                                  onShareFacilityInformationClick(
                                    facilityDetails
                                  )
                                }
                              >
                                {t("common.shareButtonText")}
                              </Button>
                            )}
                          </Stack>
                        </Stack>
                        <Divider />
                        <Stack direction="row" gap={1} alignItems="flex-start">
                          <WatermarkIcon
                            height={35}
                            width={35}
                            iconSize="medium"
                            color={theme.palette.primary[100]}
                            foreground={theme.palette.primary[700]}
                            Icon={"MapMarkerIcon"}
                          />
                          <Typography
                            component="a"
                            href={buildMapLinkURL(
                              `${facilityDetails.facilityLatitude},${facilityDetails.facilityLongitude}`
                            )}
                            target="_blank"
                            sx={{
                              ...overrideDefaultLinkStyling,
                              color: theme.palette.primary.main,
                              fontWeight: 600,
                            }}
                          >
                            {facilityDetails.facilityAddress}
                          </Typography>
                        </Stack>

                        <Stack direction="row" gap={1} alignItems="center">
                          <WatermarkIcon
                            height={35}
                            width={35}
                            iconSize="medium"
                            color={theme.palette.primary[100]}
                            foreground={theme.palette.primary[700]}
                            Icon={"PhoneIcon"}
                          />
                          <Typography
                            component="a"
                            href={`tel:${facilityDetails.facilityContactNumber}`}
                            sx={{
                              ...overrideDefaultLinkStyling,
                              color: theme.palette.primary.main,
                              fontWeight: 900,
                            }}
                          >
                            {facilityDetails.facilityContactNumber}
                          </Typography>
                        </Stack>
                        {facilityDetails.openingTimes && (
                          <Stack spacing={1}>
                            <Divider />
                            <Stack spacing={1} alignItems="center">
                              <Typography
                                fontWeight="bold"
                                color="neutral.500"
                                sx={{
                                  textTransform: "initial",
                                }}
                                variant="body2"
                              >
                                {t("FindAFacility.openingTimes.title")}
                              </Typography>

                              <Stack spacing={1} width="100%">
                                {facilityDetails.openingTimes.map(
                                  (time, index) => (
                                    <Stack
                                      key={index}
                                      flexDirection="row"
                                      justifyContent="space-between"
                                    >
                                      <Stack>
                                        <Typography
                                          variant="body2"
                                          color="neutral.500"
                                        >
                                          {time.day}
                                        </Typography>
                                      </Stack>
                                      <Stack>
                                        <Typography
                                          variant="body2"
                                          align="right"
                                          color="neutral.500"
                                        >
                                          {time.hours}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  )
                                )}
                              </Stack>
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                    )
                  )}
                </Stack>
              </DefaultAccordion>
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
}
