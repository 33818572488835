import { Typography, Stack } from "@mui/material";
import DefaultAccordion from ".";
import IconLoader from "../IconLoader";

export default function DefaultAccordionExamples() {
  return (
    <>
      <Stack spacing={2}>
        <DefaultAccordion
          title="See what info we would share"
          icon={<IconLoader icon="CheckInShieldIcon" color="primary" />}
        >
          <Stack>
            <Typography variant="h3">Sample info</Typography>
            <Typography variant="h4">Info we collect</Typography>
            <Typography variant="body1">
              The info you share with us is captured on your phone every week.
            </Typography>
          </Stack>
        </DefaultAccordion>

        <DefaultAccordion
          title="See how we protect your info"
          icon={<IconLoader icon="CheckInShieldIcon" color="primary" />}
        >
          <Stack>
            <Typography variant="h3">Info protection</Typography>
          </Stack>
        </DefaultAccordion>

        <DefaultAccordion
          title="See what info we would share"
          icon={<IconLoader icon="CheckInShieldIcon" color="primary" />}
          disabled={true}
        >
          <Stack>
            <Typography variant="h3">Sample info</Typography>
          </Stack>
        </DefaultAccordion>

        <DefaultAccordion title="No icon supplied">
          <Stack>
            <Typography variant="h3">Sample info</Typography>
          </Stack>
        </DefaultAccordion>
      </Stack>
    </>
  );
}
