import { TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import MembershipDependentsSummary from ".";
import { normaliseStringToNumber } from "../../../../utils";

export default function MembershipDependentsSummaryExamples() {
  const [count, setCount] = useState(1);

  function onCountInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    const sanitisedValue = normaliseStringToNumber(value);
    setCount(Number(sanitisedValue));
  }

  return (
    <>
      <Stack spacing={2}>
        <TextField
          sx={{ mt: 1 }}
          onInput={onCountInput}
          inputProps={{
            autoComplete: "off",
            inputMode: "numeric",
          }}
          value={count}
          fullWidth
        />
        <MembershipDependentsSummary dependentsCount={count} />
      </Stack>
    </>
  );
}
