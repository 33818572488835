import {
  AnalyticsEvent,
  trackEvent,
  trackFormEvent,
  trackPageViewV2,
} from "@/services/analytics-adapter";
import { submitContractAgreement } from "@/services/core-api-adapter";
import { Stack, Typography, Box, Divider } from "@mui/material";
import { useState, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CollectConsentCommonContent from "@/features/MemberRequiredActions/components/CollectConsentCommonContent";
import DefaultAccordion from "@/features/Common/components/DefaultAccordion";
import FullscreenLoadingIndicator from "@/features/Common/components/FullscreenLoadingIndicator";
import IconLoader from "@/features/Common/components/IconLoader";
import InformationBox from "@/features/Common/components/InformationBox";
import TermsResponseForm from "@/features/MemberRequiredActions/components/TermsResponseForm";

interface CollectConsentAcceptanceProps {
  onSubmit: () => void;
  data: {
    ContractType: string;
    Version: number;
  };
}

export default function CollectConsentAcceptance(
  props: CollectConsentAcceptanceProps
) {
  const { t } = useTranslation();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  function onFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsSubmittingForm(true);
    submitContractAgreement({
      contractType: props.data?.ContractType,
      version: String(props.data?.Version) || "",
    })
      .then(() => {
        setIsSubmittingForm(false);
        onDone();
      })
      .catch(() => {
        setIsSubmittingForm(false);
        onError();
      });
  }

  function onDone() {
    trackEvent({
      event: "action.termsAndConditionsAccepted",
      source: "consentTermsAndConditions",
    });
    trackFormEvent(AnalyticsEvent.FORM_COMPLETE, {
      formName: "Marketing consent",
    });
    props.onSubmit();
  }

  function onError() {
    console.log("Error collecting consent acceptance.");
  }

  function onTermsReject() {
    trackEvent({
      event: "action.termsAndConditionsRejected",
      source: "consentTermsAndConditions",
    });
  }

  useEffect(() => {
    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: "Marketing consent",
    });

    trackPageViewV2({
      pageName: "Sign up:Marketing consent",
      pageSubSection1: "Sign up",
      pageSubSection2: "Sign up:Marketing consent",
      pageCategory: "Sign up",
    });
  }, []);

  return (
    <>
      {isSubmittingForm ? (
        <FullscreenLoadingIndicator />
      ) : (
        <Stack spacing={4} justifyContent="flex-end" sx={{ pb: 2 }}>
          <CollectConsentCommonContent
            titleTextKey={t("CollectConsentAcceptance.title")}
            bodyTextKey={"CollectConsentAcceptance.body"}
          />
          <Stack spacing={2}>
            <DefaultAccordion
              title={t("CollectConsentAcceptance.dataSharingPolicy.title")}
              icon={<IconLoader icon="CheckInShieldIcon" color="primary" />}
            >
              <Stack spacing={1}>
                <Typography variant="h4">
                  {t("CollectConsentAcceptance.dataSharingPolicy.subtitle")}
                </Typography>
                <Typography>
                  {t("CollectConsentAcceptance.dataSharingPolicy.intro")}
                </Typography>
                <Stack>
                  {(
                    t<any, any, string[]>(
                      "CollectConsentAcceptance.dataSharingPolicy.informationlist",
                      {
                        returnObjects: true,
                      }
                    ) as []
                  ).map((text: string, index: number) => (
                    <Box key={String(text + index)}>
                      <Typography variant="body2" sx={{ py: 2 }}>
                        {text}
                      </Typography>
                      <Divider />
                    </Box>
                  ))}
                </Stack>
                <Stack spacing={2}>
                  <Typography variant="body2">
                    {t(
                      "CollectConsentAcceptance.dataSharingPolicy.acknowledgement"
                    )}
                  </Typography>
                  <InformationBox Icon={"PadlockIcon"}>
                    <Typography variant="body2">
                      {t("CollectConsentAcceptance.dataSharingPolicy.info")}
                    </Typography>
                  </InformationBox>
                </Stack>
              </Stack>
            </DefaultAccordion>

            <DefaultAccordion
              title={t("CollectConsentAcceptance.dataProtectionPolicy.title")}
              icon={<IconLoader icon="CheckInShieldIcon" color="primary" />}
            >
              <Stack spacing={1}>
                <Typography variant="h4">
                  {t("CollectConsentAcceptance.dataProtectionPolicy.subtitle")}
                </Typography>
                <Typography>
                  {t("CollectConsentAcceptance.dataProtectionPolicy.intro")}
                </Typography>
                <Typography variant="body2">
                  {t(
                    "CollectConsentAcceptance.dataProtectionPolicy.acknowledgement"
                  )}
                </Typography>
              </Stack>
            </DefaultAccordion>
          </Stack>
          <TermsResponseForm
            onTermsReject={onTermsReject}
            onFormSubmit={onFormSubmit}
          />
        </Stack>
      )}
    </>
  );
}
