import { useEffect } from "react";
import ChatBot from "../../components/ChatBot";
import { trackPageViewV2 } from "@/services/analytics-adapter";

export default function ChatBotPage() {
  useEffect(() => {
    trackPageViewV2({
      pageName: "Chat bot:Chat bot",
      pageSubSection1: "Chat bot",
      pageSubSection2: "Chat bot:Chat bot",
      pageCategory: "Chat bot",
    });
  }, []);
  return <ChatBot />;
}
