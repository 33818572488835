import { Typography } from "@mui/material";
import FlowHeader from ".";

export default function FlowHeaderExamples() {
  return (
    <>
      <Typography variant="h2">FlowHeaderExamples</Typography>

      <FlowHeader title="Test 1" />
      <FlowHeader title="Test Test Test Test Test Test Test Test 2" />
      <FlowHeader title="Test 3" value={1} />
      <FlowHeader title="Test 4" isComplete={true} />

      <FlowHeader title="Test 5" max={2} />
      <FlowHeader title="Test 6" max={2} isComplete={true} />

      <FlowHeader title="Test 7" value={1} max={2} />
      <FlowHeader title="Test 8" value={2} max={2} />
      <FlowHeader title="Test 9" value={2} max={2} isComplete={true} />

      <FlowHeader title="Test 10" value={0} max={5} />
      <FlowHeader title="Test 11" value={1} max={5} />
      <FlowHeader title="Test 12" value={2} max={5} />
      <FlowHeader title="Test 13" value={3} max={5} />
      <FlowHeader title="Test 14" value={4} max={5} />
      <FlowHeader title="Test 15" value={5} max={5} />
      <FlowHeader title="Test 16" value={6} max={5} />
      <FlowHeader title="Test 17" value={5} max={5} isComplete={true} />
      <FlowHeader title="Test 17" value={5} max={5} isComplete={true} />
      <FlowHeader title="5-min Health Profile" value={5} max={5} />
    </>
  );
}
