import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultDialog from "../../../Common/components/DefaultDialog";
import { convertPxToRem } from "../../../../utils";
import IconLoader from "../../../Common/components/IconLoader";

interface HaveANurseCallMeSuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export default function HaveANurseCallMeSuccessModal({
  isOpen,
  onClose,
  onSubmit,
}: HaveANurseCallMeSuccessModalProps) {
  const { t } = useTranslation();
  return (
    <DefaultDialog
      label={t("HaveANurseCallMeSuccessModal.title")}
      isOpen={isOpen}
      onClose={onClose}
      fullWidth={true}
    >
      <IconLoader
        icon="ChatHeartIcon"
        color="error"
        sx={{
          fontSize: convertPxToRem(56),
          alignSelf: "center",
        }}
      />

      <Typography variant="h3" fontWeight={600} align="center">
        {t("HaveANurseCallMeSuccessModal.title")}
      </Typography>

      {(
        t<any, any, string[]>(`HaveANurseCallMeSuccessModal.content`, {
          returnObjects: true,
        }) as []
      ).map((text: string) => (
        <Typography key={text} textAlign="center">
          {text}
        </Typography>
      ))}

      <Button size="small" onClick={onSubmit}>
        {t("common.okay")}
      </Button>
    </DefaultDialog>
  );
}
