import { useTranslation } from "react-i18next";
import { Box, Button, Stack, Typography } from "@mui/material";
import IconLoader from "../../../Common/components/IconLoader";

export enum MembershipManagementChangeRequestResponseTypes {
  SUCCESS = "Success",
  FAILURE = "Failure",
}

interface MembershipManagementChangeRequestFormResponseProps {
  responseType: MembershipManagementChangeRequestResponseTypes;
  onClose?: () => void;
}

export default function MembershipManagementChangeRequestFormResponse({
  onClose,
  responseType,
}: MembershipManagementChangeRequestFormResponseProps) {
  const { t } = useTranslation();

  return (
    <>
      <Stack component="article" spacing={2}>
        <Box textAlign="center">
          <IconLoader
            icon="SpeechBubbleWithRingingPhoneIcon"
            color="primary"
            sx={{ fontSize: (theme) => theme.spacing(7) }}
          />
        </Box>

        <Typography textAlign="center" variant="h3" fontWeight="600">
          {t(
            `MembershipManagementChangeRequestFormResponse${responseType}.title`
          )}
        </Typography>

        {(
          t<any, any, string[]>(
            `MembershipManagementChangeRequestFormResponse${responseType}.body`,
            {
              returnObjects: true,
            }
          ) as []
        ).map((text: string) => (
          <Typography key={text} textAlign="center">
            {text}
          </Typography>
        ))}
      </Stack>

      {onClose && (
        <Stack component="footer" spacing={1}>
          <Button size="small" fullWidth onClick={onClose}>
            {t("common.close")}
          </Button>
        </Stack>
      )}
    </>
  );
}
