import { Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import HealthMeasurementGauge from ".";
import {
  getHealthMeasurementValueRange,
  HealthMeasurementDataValueRange,
  HealthMeasurementQuestionType,
} from "../../../../services/core-api-adapter";
import { HealthMeasurementIdentifier } from "../../../../store";
import { HealthMeasurementValueRangeColors } from "../../../../theme";

const BMIData = {
  value: 25,
  unit: "",
};

const MetabolicAgeData = {
  value: 41,
  unit: "",
};

const SystolicBloodPressureData = {
  value: 127,
  unit: "",
};

const DiastolicBloodPressureData = {
  value: 76,
  unit: "",
};

const WeightData = {
  value: 62,
  unit: "",
};

const BodyFat = {
  value: 15.5,
  unit: "",
};

const BodyWaterPercent = {
  value: 62,
  unit: "",
};

const MusclePercent = {
  value: 62,
  unit: "",
};

function replaceValuesWithColours(
  valueRange: HealthMeasurementDataValueRange[],
  key: HealthMeasurementQuestionType
) {
  return valueRange.map((item) => {
    return {
      ...item,
      color: HealthMeasurementValueRangeColors[key][item.healthRiskIndicator],
    };
  });
}

export default function HealthMeasurementGaugeExamples() {
  const [customBMIdata, setCustomBMIdata] = useState(BMIData);
  const [customMetabolicAgeData, setCustomMetabolicAgeData] =
    useState(MetabolicAgeData);
  const [customSystolicBloodPressureData, setCustomSystolicBloodPressureData] =
    useState(SystolicBloodPressureData);
  const [
    customDiastolicBloodPressureData,
    setCustomDiastolicBloodPressureData,
  ] = useState(DiastolicBloodPressureData);
  const [customWeightData, setCustomWeightData] = useState(WeightData);
  const [customBodyFat, setCustomBodyFat] = useState(BodyFat);
  const [customBodyWaterPercent, setCustomBodyWaterPercent] =
    useState(BodyWaterPercent);
  const [customMusclePercent, setCustomMusclePercent] = useState(MusclePercent);

  const valueRangeBodyMassIndex = replaceValuesWithColours(
    getHealthMeasurementValueRange("BodyMassIndex"),
    "BodyMassIndex"
  );
  const valueRangeMetabolicAge = replaceValuesWithColours(
    getHealthMeasurementValueRange("MetabolicAge"),
    "MetabolicAge"
  );
  const valueRangeSystolicBloodPressure = replaceValuesWithColours(
    getHealthMeasurementValueRange("SystolicBloodPressure"),
    "SystolicBloodPressure"
  );
  const valueRangeDiastolicBloodPressure = replaceValuesWithColours(
    getHealthMeasurementValueRange("DiastolicBloodPressure"),
    "MetabolicAge"
  );
  const valueRangeWeight = replaceValuesWithColours(
    getHealthMeasurementValueRange("Weight"),
    "Weight"
  );
  const valueRangeBodyFat = replaceValuesWithColours(
    getHealthMeasurementValueRange("BodyFat"),
    "BodyFat"
  );
  const valueRangeBodyWaterPercent = replaceValuesWithColours(
    getHealthMeasurementValueRange("BodyWaterPercent"),
    "BodyWaterPercent"
  );
  const valueRangeMusclePercent = replaceValuesWithColours(
    getHealthMeasurementValueRange("MusclePercent"),
    "MusclePercent"
  );

  function setValue(setter: any, value: number) {
    setter((prevState: any) => ({
      ...prevState,
      value,
    }));
  }

  return (
    <Stack>
      <Stack spacing={4}>
        <Stack alignItems="center">
          <Typography>BMI</Typography>
          <HealthMeasurementGauge
            healthMeasurementIdentifier={
              HealthMeasurementIdentifier.BodyMassIndex
            }
            value={customBMIdata.value}
            unit={customBMIdata.unit}
            valueRange={valueRangeBodyMassIndex}
            numberOfTicks={41}
          />
          <TextField
            sx={{ mt: 1 }}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setValue(setCustomBMIdata, +event.target.value)
            }
            value={customBMIdata.value}
            fullWidth
          />
        </Stack>

        <Stack alignItems="center">
          <Typography>MetabolicAge</Typography>
          <HealthMeasurementGauge
            healthMeasurementIdentifier={
              HealthMeasurementIdentifier.MetabolicAge
            }
            value={customMetabolicAgeData.value}
            unit={customMetabolicAgeData.unit}
            valueRange={valueRangeMetabolicAge}
            numberOfTicks={17}
          />
          <TextField
            sx={{ mt: 1 }}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setValue(setCustomMetabolicAgeData, +event.target.value)
            }
            value={customMetabolicAgeData.value}
            fullWidth
          />
        </Stack>

        <Stack alignItems="center">
          <Typography>SystolicBloodPressureData</Typography>
          <HealthMeasurementGauge
            healthMeasurementIdentifier={
              HealthMeasurementIdentifier.BloodPressure
            }
            value={customSystolicBloodPressureData.value}
            unit={customSystolicBloodPressureData.unit}
            valueRange={valueRangeSystolicBloodPressure}
            numberOfTicks={71}
          />
          <TextField
            sx={{ mt: 1 }}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setValue(setCustomSystolicBloodPressureData, +event.target.value)
            }
            value={customSystolicBloodPressureData.value}
            fullWidth
          />
        </Stack>

        <Stack alignItems="center">
          <Typography>DiastolicBloodPressureData</Typography>
          <HealthMeasurementGauge
            healthMeasurementIdentifier={
              HealthMeasurementIdentifier.BloodPressure
            }
            value={customDiastolicBloodPressureData.value}
            unit={customDiastolicBloodPressureData.unit}
            valueRange={valueRangeDiastolicBloodPressure}
            numberOfTicks={76}
          />
          <TextField
            sx={{ mt: 1 }}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setValue(setCustomDiastolicBloodPressureData, +event.target.value)
            }
            value={customDiastolicBloodPressureData.value}
            fullWidth
          />
        </Stack>

        <Stack alignItems="center">
          <Typography>WeightData</Typography>
          <HealthMeasurementGauge
            healthMeasurementIdentifier={HealthMeasurementIdentifier.Weight}
            value={customWeightData.value}
            unit={customWeightData.unit}
            valueRange={valueRangeWeight}
            numberOfTicks={21}
          />
          <TextField
            sx={{ mt: 1 }}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setValue(setCustomWeightData, +event.target.value)
            }
            value={customWeightData.value}
            fullWidth
          />
        </Stack>

        <Stack alignItems="center">
          <Typography>BodyFat</Typography>
          <HealthMeasurementGauge
            healthMeasurementIdentifier={HealthMeasurementIdentifier.BodyFat}
            value={customBodyFat.value}
            unit={customBodyFat.unit}
            valueRange={valueRangeBodyFat}
            numberOfTicks={25}
          />
          <TextField
            sx={{ mt: 1 }}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setValue(setCustomBodyFat, +event.target.value)
            }
            value={customBodyFat.value}
            fullWidth
          />
        </Stack>

        <Stack alignItems="center">
          <Typography>BodyWaterPercent</Typography>
          <HealthMeasurementGauge
            healthMeasurementIdentifier={
              HealthMeasurementIdentifier.BodyWaterPercent
            }
            value={customBodyWaterPercent.value}
            unit={customBodyWaterPercent.unit}
            valueRange={valueRangeBodyWaterPercent}
            numberOfTicks={51}
          />
          <TextField
            sx={{ mt: 1 }}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setValue(setCustomBodyWaterPercent, +event.target.value)
            }
            value={customBodyWaterPercent.value}
            fullWidth
          />
        </Stack>

        <Stack alignItems="center">
          <Typography>MusclePercent</Typography>
          <HealthMeasurementGauge
            healthMeasurementIdentifier={"MusclePercent"}
            value={customMusclePercent.value}
            unit={customMusclePercent.unit}
            valueRange={valueRangeMusclePercent}
            numberOfTicks={31}
          />
          <TextField
            sx={{ mt: 1 }}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setValue(setCustomMusclePercent, +event.target.value)
            }
            value={customMusclePercent.value}
            fullWidth
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
