import { Box, Button, Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import FullscreenBackground from "@/features/Common/components/FullscreenBackground";
import { trackEvent } from "@/services/analytics-adapter";
import theme from "@/theme";
import StepsCounter from "../../../Common/components/StepsCounter";

interface PathologyPromptProps {
  onBackButtonClick: () => void;
  onContinueButtonClick: () => void;
}

export function PathologyPrompt({
  onContinueButtonClick,
  onBackButtonClick,
}: PathologyPromptProps) {
  const { t } = useTranslation();

  function onContinue() {
    trackEvent({
      event: "action.nextStepsContinue",
      source: "Church Of Uganda Medical Assistance",
    });
    onContinueButtonClick();
  }

  function onBack() {
    onBackButtonClick();
  }

  return (
    <>
      <Stack p={2} justifyContent="space-between" flexGrow={1} minHeight="100%">
        <Stack
          color="neutral.50"
          spacing={4}
          sx={{
            height: "100%",
          }}
        >
          <Typography variant="h1" color="info.main" textAlign="center">
            {t("PathologyPrompt.title")}
          </Typography>
          <Stack spacing={4}>
            <Stack
              bgcolor="neutral.50"
              borderRadius={theme.shape.borderRadius}
              p={4}
              spacing={2}
            >
              <Typography variant="h2">
                {t("PathologyPrompt.steps.title")}
              </Typography>

              <Stack direction="row">
                <StepsCounter stepCounter={1} />
                <Typography ml={2} variant="body1">
                  <Trans i18nKey={"PathologyPrompt.steps.1.title"} />
                </Typography>
              </Stack>
              <Stack direction="row">
                <StepsCounter stepCounter={2} />
                <Typography ml={2} variant="body1">
                  <Trans i18nKey={"PathologyPrompt.steps.2.title"} />
                </Typography>
              </Stack>
              <Stack direction="row">
                <StepsCounter stepCounter={3} />
                <Box>
                  <Typography ml={2} variant="body1">
                    <Trans i18nKey={"PathologyPrompt.steps.3.title"} />
                  </Typography>
                  <Typography ml={2} variant="body2">
                    <Trans i18nKey={"PathologyPrompt.steps.3.subtitle"} />
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row">
                <StepsCounter stepCounter={4} />
                <Typography ml={2} variant="body1">
                  <Trans i18nKey={"PathologyPrompt.steps.4.title"} />
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={2}>
              <Button
                onClick={onBack}
                color="info"
                fullWidth
                variant="outlined"
              >
                {t("common.backButton")}
              </Button>
              <Button
                size="small"
                fullWidth
                component="a"
                target="_blank"
                color="info"
                onClick={onContinue}
              >
                {t("common.continueButton")}
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <FullscreenBackground color="primary.main" />
      </Stack>
    </>
  );
}
