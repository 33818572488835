import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RejectTermsOrConsentDialog from "../RejectTermsOrConsentDialog";
import GetHelpCTAButton from "../../../Common/components/GetHelpCTAButton";
import { GetHelpFABConfig } from "../../../../theme";
import { useGlobalStore } from "../../../../store";
import ButtonWithAnalytics from "../../../Common/components/ButtonWithAnalytics";
import { trackUserInteraction } from "@/services/analytics-adapter";

interface TermsResponseFormProps {
  onFormSubmit: FormEventHandler<HTMLFormElement>;
  onTermsReject: () => void;
}

export default function TermsResponseForm({
  onFormSubmit,
  onTermsReject,
}: TermsResponseFormProps) {
  const { t } = useTranslation();
  const { state } = useGlobalStore();

  const [isFormSubmitEnabled, setFormSubmitEnabled] = useState(false);
  const [
    isRejectTermsOrConsentDialogOpen,
    setIsRejectTermsOrConsentDialogOpen,
  ] = useState(false);
  const [acceptanceValue, setAcceptanceValue] = useState<null | boolean>(null);

  useEffect(() => {
    setFormSubmitEnabled(acceptanceValue === true);
  }, [acceptanceValue]);

  function onRejectTermsOrConsentDialogClose() {
    setIsRejectTermsOrConsentDialogOpen(false);
  }

  function onRejectTermsOrConsentButtonClick() {
    setIsRejectTermsOrConsentDialogOpen(true);
    onTermsReject();
  }

  function onToggleChange(
    _: React.MouseEvent<HTMLElement>,
    newValue: null | boolean
  ) {
    if (newValue === false) {
      onRejectTermsOrConsentButtonClick();
    }
    setAcceptanceValue(newValue);

    trackUserInteraction({
      linkText:
        "Sign up:Marketing consent | " +
        (newValue === true ? "I Agree" : "I Don't Agree"),
      linkIntent: "confirmational",
      linkScope: "toggle",
    });
  }

  useEffect(() => {
    if (isRejectTermsOrConsentDialogOpen === false) {
      setAcceptanceValue(null);
    }
  }, [isRejectTermsOrConsentDialogOpen]);

  return (
    <>
      <form onSubmit={onFormSubmit}>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <ToggleButtonGroup
              color="neutral"
              size="large"
              value={acceptanceValue}
              exclusive
              onChange={onToggleChange}
              fullWidth
            >
              <ToggleButton value={true}>{t("common.iAgree")}</ToggleButton>
              <ToggleButton value={false}>
                {t("common.iDontAgree")}
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>

          {state.currentUser.isRetailMember === false && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignSelf="flex-end"
              position="relative"
              width={GetHelpFABConfig.floatingActionButtonWidth}
            >
              <GetHelpCTAButton pageName="CollectConsentAcceptance" />
            </Stack>
          )}
          <ButtonWithAnalytics
            page="CollectConsentAcceptance"
            text={t("common.continueButton")}
            intent="navigational"
            type="submit"
            color="primary"
            disabled={!isFormSubmitEnabled}
            fullWidth
          >
            {t("common.continueButton")}
          </ButtonWithAnalytics>
        </Stack>
      </form>
      <RejectTermsOrConsentDialog
        isOpen={isRejectTermsOrConsentDialogOpen}
        onClose={onRejectTermsOrConsentDialogClose}
      />
    </>
  );
}
