import { useGlobalStore } from "@/store";
import AuthFlow from "../../components/AuthFlow";
import ThirdPartyAuthFlow from "../../components/ThirdPartyAuthFlow";

export default function AuthPage() {
  const { state } = useGlobalStore();

  return (
    <>
      {state.isThirdPartyMember === true && <ThirdPartyAuthFlow />}

      {state.isThirdPartyMember === false && <AuthFlow />}
    </>
  );
}
