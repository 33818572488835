import { useNavigate } from "react-router";
import GetMedicalAssistanceModalContent from "../GetMedicalAssistanceModalContent";
import { convertPxToRem } from "../../../../utils";
import SlideUpDialog from "../../../Common/components/SlideUpDialog";
import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import IconLoader from "../../../Common/components/IconLoader";
import useGetMemberships from "../../../Common/hooks/useGetMemberships";
import { useEffect, useState } from "react";
import useSeekMedicalAssistance from "../../hooks/useSeekMedicalAssistance";
import { MedicalServiceProviderType } from "../../../../services/core-api-adapter";
import LoadingSpinner from "../../../Common/components/LoadingSpinner";

interface GetMedicalAssistanceModalProps {
  onClose?: () => void;
  isOpen?: boolean;
}
export default function GetMedicalAssistanceModal({
  onClose,
  isOpen = true,
}: GetMedicalAssistanceModalProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showTooManyRequestError, setShowTooManyRequestError] =
    useState<boolean>(false);
  const [showGenericError, setShowGenericError] = useState<boolean>(false);
  const [
    userCanMakeMedicalAssistanceRequest,
    setUserCanMakeMedicalAssistanceRequest,
  ] = useState<boolean | null>(null);

  const {
    isMembershipsLoading,
    firstMembership,
    memberships,
    medicalAssistanceProvider,
  } = useGetMemberships();

  const {
    checkIfPleaseCallMeServiceIsAvailable,
    isTooManyRequestsError,
    isGenericError,
    isAssistanceRequestInProgress,
  } = useSeekMedicalAssistance();

  const onModalClose = () => {
    if (onClose) {
      return onClose();
    }
    navigate(-1);
  };

  useEffect(() => {
    setUserCanMakeMedicalAssistanceRequest(false);

    if (medicalAssistanceProvider === MedicalServiceProviderType.CALL_ME_BACK) {
      checkIfPleaseCallMeServiceIsAvailable().then(() => {
        setUserCanMakeMedicalAssistanceRequest(!showTooManyRequestError);
      });
    } else {
      setUserCanMakeMedicalAssistanceRequest(true);
    }

    setShowGenericError(!firstMembership?.productDetails?.administrator);
  }, [firstMembership, memberships]);

  useEffect(() => {
    setShowTooManyRequestError(!!isTooManyRequestsError);
    setShowGenericError(isGenericError);
  }, [isAssistanceRequestInProgress, isTooManyRequestsError, isGenericError]);

  return (
    <SlideUpDialog
      isOpen={isOpen}
      onClose={onModalClose}
      label={t("GetMedicalAssistanceModalContent.title")}
    >
      <Stack spacing={1} textAlign={"center"}>
        <Stack spacing={2}>
          <IconLoader
            icon="ChatHeartIcon"
            color="error"
            sx={{
              fontSize: convertPxToRem(56),
              alignSelf: "center",
            }}
          />
          <Typography variant="h2" sx={{ mx: 4 }}>
            {t("GetMedicalAssistanceModalContent.title")}
          </Typography>
        </Stack>

        {isMembershipsLoading || isAssistanceRequestInProgress ? (
          <LoadingSpinner />
        ) : (
          <>
            {showGenericError && (
              <>
                <Typography pb={2}>{t("GenericError.title")}</Typography>
                <Stack direction="row" spacing={2}>
                  <Button fullWidth onClick={onModalClose} variant="contained">
                    {t("common.okay")}
                  </Button>
                </Stack>
              </>
            )}

            {showTooManyRequestError && (
              <Stack spacing={4}>
                <Stack spacing={2}>
                  {(
                    t<any, any, string[]>(
                      `HaveANurseCallMeRequestLimit.content`,
                      {
                        returnObjects: true,
                      }
                    ) as []
                  ).map((text: string) => (
                    <Typography key={text} textAlign="center">
                      {text}
                    </Typography>
                  ))}
                </Stack>
                <Button onClick={onModalClose}>{t("common.okay")}</Button>
              </Stack>
            )}

            {!showGenericError &&
              !showTooManyRequestError &&
              userCanMakeMedicalAssistanceRequest && (
                <GetMedicalAssistanceModalContent
                  membership={firstMembership}
                />
              )}
          </>
        )}
      </Stack>
    </SlideUpDialog>
  );
}
