import { Box, Stack, Typography } from "@mui/material";
import FrequencyInput from ".";

export default function FrequencyInputExamples() {
  return (
    <Box>
      <Stack>
        <Typography variant="h2" sx={{ mt: 3 }}>
          Example 1
        </Typography>
        <FrequencyInput
          label="Generic Frequency Input Label"
          onChange={(data: Object) => {
            console.log(data);
          }}
          options={[
            {
              name: "In a day",
              value: "day",
            },
            {
              name: "In a week",
              value: "week",
            },
            {
              name: "In a month",
              value: "month",
            },
          ]}
          initialValue={10}
        ></FrequencyInput>

        <Typography variant="h2" sx={{ mt: 3 }}>
          Example 2 (minimum value: -5, maximum value: 5)
        </Typography>
        <FrequencyInput
          label="Generic Frequency Input Label"
          onChange={(data: Object) => {
            console.log(data);
          }}
          options={[
            {
              name: "Last Year",
              value: "LAST_YEAR",
            },
            {
              name: "This Year",
              value: "THIS_YEAR",
            },
          ]}
          initialValue={0}
          min={-5}
          max={5}
        ></FrequencyInput>

        <Typography variant="h2" sx={{ mt: 3 }}>
          Example 3 (Empty options list)
        </Typography>
        <FrequencyInput
          label="Generic Frequency Input Label"
          onChange={(data: Object) => {
            console.log(data);
          }}
          options={[]}
          initialValue={0}
        ></FrequencyInput>
      </Stack>
    </Box>
  );
}
