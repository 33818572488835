import { Typography } from "@mui/material";
import OTPInput from ".";

export default function OTPExamples() {
  return (
    <>
      <Typography variant="h2">OTP Examples</Typography>
      <Typography variant="subtitle2">OTP Input Field</Typography>
      <br />
      <p>Input pattern of "***-***"</p>
      <OTPInput inputPattern="***-***" />
      <br />
      <p>Input pattern of "****"</p>
      <OTPInput inputPattern="****" />
      <br />
      <p>Input pattern of "*-*-*-*"</p>
      <OTPInput inputPattern="*-*-*-*" />
      <br />
      <p>Input pattern of "******"</p>
      <OTPInput inputPattern="******" />
      <br />
    </>
  );
}
