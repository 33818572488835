import { Stack } from "@mui/material";
import UserRatingFeedback from ".";

export default function UserRatingFeedbackExamples() {
  return (
    <Stack sx={{ height: "100vh" }}>
      <UserRatingFeedback
        serviceType="CONSULTATION"
        ratedServiceId="asdf-asf-asdf-asdf"
        onSendFeedback={() => console.log("onSendFeedback")}
        onSkipFeedback={() => console.log("onSkipFeedback")}
      />
    </Stack>
  );
}
