import DefaultDialog from "@/features/Common/components/DefaultDialog";
import FullscreenBackground from "@/features/Common/components/FullscreenBackground";
import FullScreenIframeDialog from "@/features/Common/components/FullScreenIframeDialog";
import { trackEvent, trackUserInteraction } from "@/services/analytics-adapter";
import { getConfigurationVariable } from "@/services/configuration-adapter";
import {
  generatePlatformTsAndCsLinkBasedOnUserSelectedLanguage,
  MemberProductPaymentRatesChargeCode,
  MemberProductPaymentRatesItem,
} from "@/services/core-api-adapter";
import theme from "@/theme";
import { Button, Link, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import StepsCounter from "../../../Common/components/StepsCounter";

const THIRD_PARTY_NURSE_CHAT_LINK = getConfigurationVariable(
  "VITE_APP_UNU_HEALTH_THIRD_PARTY_NURSE_CHAT_LINK"
);

interface ThirdPartyMedicalAssistanceServicePromptProps {
  onBackButtonClick: () => void;
  onContinueButtonClick: () => void;
  serviceType: MemberProductPaymentRatesItem;
}

interface BreakoutDisclaimerModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function BreakoutDisclaimerModal({
  isOpen,
  onClose,
}: BreakoutDisclaimerModalProps) {
  const { t } = useTranslation();

  const [isTermsAndConditionsDialogOpen, setIsTermsAndConditionsDialogOpen] =
    useState(false);

  function onTermsLinkClick(event: any) {
    event.preventDefault();
    setIsTermsAndConditionsDialogOpen(true);
    trackEvent({
      event: "action.termsAndConditionsLinkOpened",
      source: "ChurchOfUgandaBreakoutDisclaimer",
    });
  }

  function onTermsDialogClose() {
    setIsTermsAndConditionsDialogOpen(false);
  }
  return (
    <DefaultDialog
      label={t(
        "ThirdPartyMedicalAssistanceServicePrompt.disclaimer.modal.title"
      )}
      isOpen={isOpen}
      onClose={onClose}
      fullWidth={true}
    >
      <Stack textAlign="center" spacing={2}>
        <Typography variant="h3" fontWeight={600}>
          {t("ThirdPartyMedicalAssistanceServicePrompt.disclaimer.modal.title")}
        </Typography>

        <Typography variant="body2" color="inherit">
          {t("ThirdPartyMedicalAssistanceServicePrompt.disclaimer.modal.body")}
        </Typography>

        <Typography variant="body2" color="inherit">
          <Trans i18nKey="ThirdPartyMedicalAssistanceServicePrompt.disclaimer.modal.footer">
            <Link
              sx={{ color: "primary", fontWeight: 900 }}
              href="/"
              onClick={onTermsLinkClick}
            ></Link>
          </Trans>
        </Typography>
      </Stack>

      <FullScreenIframeDialog
        title={t(
          "CollectTermsAcceptance.termsAndConditions.TERMS_AND_CONDITIONS_PLATFORM.documentTitle"
        )}
        isOpen={isTermsAndConditionsDialogOpen}
        onClose={onTermsDialogClose}
        url={generatePlatformTsAndCsLinkBasedOnUserSelectedLanguage()}
      />
    </DefaultDialog>
  );
}

export function ThirdPartyMedicalAssistanceServicePrompt({
  onContinueButtonClick,
  onBackButtonClick,
  serviceType,
}: ThirdPartyMedicalAssistanceServicePromptProps) {
  const { t } = useTranslation();
  const [isBreakoutDisclaimerModalOpen, setIsBreakoutDisclaimerModalOpen] =
    useState<boolean>(false);

  function onContinue() {
    trackEvent({
      event: "action.nextStepsContinue",
      source: "Church Of Uganda Medical Assistance",
    });
    trackUserInteraction({
      linkText: "Continue | Church Of Uganda Medical assistance next steps",
      linkIntent: "navigational",
      linkScope: "button",
    });
    onContinueButtonClick();
  }

  function onBack() {
    trackUserInteraction({
      linkText: "Back | Church Of Uganda Medical assistance next steps",
      linkIntent: "navigational",
      linkScope: "button",
    });
    onBackButtonClick();
  }

  function onBreakoutDisclaimerModalClose() {
    setIsBreakoutDisclaimerModalOpen(false);
  }

  function onDisclaimerButtonClicked() {
    setIsBreakoutDisclaimerModalOpen(true);
  }

  function getLinkForServiceType() {
    if (
      serviceType.chargeCode === MemberProductPaymentRatesChargeCode.NURSE_CHAT
    ) {
      return THIRD_PARTY_NURSE_CHAT_LINK;
    } else if (serviceType.ussdConfiguration) {
      const encodedUSSD = serviceType.ussdConfiguration.ussd.replace(
        "#",
        "%23"
      );
      return "tel:" + encodedUSSD;
    }
  }

  return (
    <>
      <Stack p={2} justifyContent="space-between" flexGrow={1} minHeight="100%">
        <Stack
          color="neutral.50"
          sx={{
            height: "100%",
          }}
        >
          <Stack spacing={4}>
            <Stack
              bgcolor="neutral.50"
              borderRadius={theme.shape.borderRadius}
              p={4}
              spacing={2}
            >
              <Typography variant="h2">
                {t("ThirdPartyMedicalAssistanceServicePrompt.title")}
              </Typography>

              <>
                {(
                  t<any, any, string[]>(
                    `ThirdPartyMedicalAssistanceServicePrompt.${serviceType.chargeCode}.steps.body`,
                    {
                      returnObjects: true,
                    }
                  ) as []
                ).map((text: string, index: number) => (
                  <Stack key={String(text + index)} direction="row">
                    <Stack>
                      <StepsCounter stepCounter={index + 1} />
                    </Stack>
                    <Stack>
                      <Typography ml={2} variant="body1">
                        <Trans i18nKey={text as any} />
                      </Typography>
                    </Stack>
                  </Stack>
                ))}
              </>
            </Stack>

            <Stack>
              <Button
                onClick={onDisclaimerButtonClicked}
                size="small"
                variant="text"
              >
                <Typography variant="body1" color="info.main">
                  {t(
                    "ThirdPartyMedicalAssistanceServicePrompt.disclaimer.label"
                  )}
                </Typography>
              </Button>
            </Stack>

            <Stack direction="row" spacing={2}>
              <Button
                onClick={onBack}
                color="info"
                fullWidth
                variant="outlined"
              >
                {t("common.backButton")}
              </Button>
              <Button
                size="small"
                fullWidth
                component="a"
                target="_blank"
                color="info"
                href={getLinkForServiceType()}
                onClick={onContinue}
              >
                {t("common.continueButton")}
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <FullscreenBackground color="primary.main" />
        <BreakoutDisclaimerModal
          isOpen={isBreakoutDisclaimerModalOpen}
          onClose={onBreakoutDisclaimerModalClose}
        />
      </Stack>
    </>
  );
}
